import { React } from 'react';
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
import './layout.scss';

const Layout = props => {
	return (
		<div className='layout'>
			<Header />
			<div className='content'>
				<Sidebar />
				{props.children}
			</div>
		</div>
	);
};

export default Layout;
