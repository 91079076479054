import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from 'react';
import TagList from '../tagList/tagList';
import TagGroupList from '../tagGroupList/tagGroupList';
import './tagTabs.scss';
import { useTranslation } from "react-i18next";

const TagTabs = () => {
	const [tab, setTab] = useState('tags');
	const { t } = useTranslation()

	return (
		<div className="tag-tabs-container">
			<Tabs activeKey={tab} onSelect={selectedTab => setTab(selectedTab)}>
				<Tab eventKey='tags' title={t('tags.tags')}>
					<TagList />
				</Tab>
				<Tab eventKey='tag-groups' title={t('tags.tagGroups')}>
					<TagGroupList />
				</Tab>
			</Tabs>
		</div>
	)
}

export default TagTabs;