export const getIdentifier = (firstIndex, secondIndex, thirdIndex) => {
  let identifier = `${firstIndex}`;

  if (secondIndex !== undefined) {
    identifier += `-${secondIndex}`;
  }

  if (thirdIndex !== undefined) {
    identifier += `-${thirdIndex}`;
  }

  return identifier;
};

export const getFile = (option) => {
  const { default_file } = option;

  if (default_file?.mime) {
    // Create dummy file to mark it as a file recieved from strapi
    return new File([{}], "from-strapi");
  }

  return default_file;
};

export const processOptions = (options) =>
  options.map((option) => ({
    ...option,
    default_type: option.default_type?.value,
    default_category: option.default_category?.value,
    products: option.products?.map(product => product.value) || [],
    dropdown_options: option.second_level_options.map(
      (second_level_option) => ({
        ...second_level_option,
        default_type: second_level_option.default_type?.value,
        parent_option: option.id,
        default_category: second_level_option.default_category?.value,
        products: second_level_option.products?.map(product => product.value) || [],
        dropdown_options: second_level_option.third_level_options.map(
          (third_level_option) => ({
            ...third_level_option,
            default_type: third_level_option.default_type?.value,
            parent_option: second_level_option.id,
            default_category: third_level_option.default_category?.value,
          })
        ),
      })
    ),
  }));

const getType = {
  internal_link: "Internal Link",
  external_link: "External Link",
  email: "Send an email",
  file: "File",
  ftp: "Download a Report",
  table: "Table",
};

export const getOptionsInitialValues = (dropdownDetails) =>
  dropdownDetails?.dropdown_options?.map((option) => ({
    ...option,
    default_type: {
      value: option.default_type,
      label: getType[option.default_type],
    },
    default_category: {
      value: option.default_category,
      label: option.default_category,
    },
    products: option.products?.map(product => ({ value: product.id, label: product.name })) || [],
    is_visible: (option.is_visible === null || option.is_visible === undefined) ? true : option.is_visible,
    second_level_options: option.dropdown_options?.map((secondLevelOption) => ({
      ...secondLevelOption,
      default_type: {
        value: secondLevelOption.default_type,
        label: getType[secondLevelOption.default_type],
      },
      default_category: {
        value: secondLevelOption.default_category,
        label: secondLevelOption.default_category,
      },
      is_visible: (secondLevelOption.is_visible === null || secondLevelOption.is_visible === undefined) ? true : secondLevelOption.is_visible,
      products: secondLevelOption.products?.map(product => ({ value: product.id, label: product.name })) || [],
      third_level_options: secondLevelOption.dropdown_options?.map(
        (thirdLevelOption) => ({
          ...thirdLevelOption,
          default_type: {
            value: thirdLevelOption.default_type,
            label: getType[thirdLevelOption.default_type],
          },
          default_category: {
            value: thirdLevelOption.default_category,
            label: thirdLevelOption.default_category,
          },
        })
      ),
    })),
  })) || [];
