// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
}
.tag-group-title {
  font-size: 1.5em;
  font-weight: bold;
}
.tag-group-container {
  flex: 1 1;
  margin-top: 2em;
  margin-inline: 2em;
  padding: 2em;
  border-radius: 1em;
  background-color: #fff;
}
.tag-group-form {
  display: flex;
  flex-direction: column;
}
.tag-group-form-layout {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2em;
}`, "",{"version":3,"sources":["webpack://./src/components/views/tag/tagGroupForm/tagGroupForm.scss","webpack://./src/sass/globals/mixins.scss","webpack://./src/sass/globals/globals.scss"],"names":[],"mappings":"AAGA;ECFC,aAAA;EACA,uBDEc;ECDd,uBDCsB;EACtB,SAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;AAAD;AAEC;EACC,gBAAA;EACA,iBAAA;AAAF;AAGC;EACC,SAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBEjBM;AFgBR;AAIC;EACC,aAAA;EACA,sBAAA;AAFF;AAIE;EC3BD,aAAA;EACA,6BD2BgB;EC1BhB,uBD0B8B;EAC5B,eAAA;EACA,QAAA;AAAH","sourcesContent":["@import '../../../../sass/globals/globals.scss';\n@import '../../../../sass/globals/mixins.scss';\n\n.tag-group {\n\t@include flex(center, flex-start);\n\tflex: 1;\n\theight: 100%;\n\tbox-sizing: border-box;\n\toverflow: scroll;\n\n\t&-title {\n\t\tfont-size: 1.5em;\n\t\tfont-weight: bold;\n\t}\n\n\t&-container {\n\t\tflex: 1;\n\t\tmargin-top: 2em;\n\t\tmargin-inline: 2em;\n\t\tpadding: 2em;\n\t\tborder-radius: 1em;\n\t\tbackground-color: $white;\n\t}\n\n\t&-form {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\n\t\t&-layout {\n\t\t\t@include flex(space-evenly, flex-start);\n\t\t\tflex-wrap: wrap;\n\t\t\tgap: 2em;\n\t\t}\n\t}\n}\n","@mixin flex($justify-content, $align-items) {\n\tdisplay: flex;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n\n@mixin flexColumn($justify-content, $align-items) {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n","$dark-red: #8f003c;\n$sidebar-selected-color: #c4145d;\n$sidebar-logo-color: #a53363;\n$logo-color: #920942;\n$white: #fff;\n$grey: #f3f6f9;\n$grey2: #e2e5e7;\n$dark-grey: #777776;\n$font-color: #fff;\n$shadow-color: #4e7da2;\n$font: 'Rubik';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
