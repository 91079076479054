import "./buttonInHomeForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import FileInput from "../../../fileInput/fileInput";
import * as buttonAction from "../../../../redux/actions/button.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";

const ButtonInHomeForm = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const { memberId, entityId } = useParams();
	const dispatch = useDispatch();
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showExitModal, setShowExitModal] = useState(false);
  const { t } = useTranslation();
  
  const { buttonDetails, button, error } = useSelector((state) => ({
    buttonDetails: state.button.buttonDetails,
    button: state.button.button,
    error: state.button.buttonError,
  }));

  const initialValues = {
    title: entityId ? buttonDetails?.name || "" : "",
    order: entityId ? buttonDetails?.order || "" : "",
    source: entityId ? (["internal_link", "external_link"].includes(buttonDetails?.type) ? "url" : "upload") : "url",
    linkType: entityId ? (buttonDetails?.type === "internal_link" ? "internal_link" : "external_link")  : "internal_link",
    url: entityId ? buttonDetails?.link || "" : "",
    file: entityId ? buttonDetails?.file || "" : "",
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const VALID_FILE_TYPES = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "csv",
  ];

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required field"),
    order: Yup.number(),
    source: Yup.string(),
    linkType: Yup.string(),
    url: Yup.string(),
    file: Yup.mixed()
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid file extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
  });

	useEffect(() => {
		if(entityId) { //If id is passed as a parameter then load initial values
			buttonAction.fetchDetails(dispatch, entityId);
		}
	}, [ dispatch, entityId ]);

	useEffect(() => {
		//Render success alert and redirect
		if (submittedForm && button) {
			setRenderSuccessAlert(true);
      if(!entityId) {
        customResetForm();
      }
		} 
	}, [entityId, button, submittedForm, navigate]);

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    let type = "";

    let { file, linkType, url, source, title, order } = values;

    if (source === "upload") {
      type = "file";
      if (file && file.type === "application/pdf") {
        type = "pdf_file";
      }
      url = "";
    } else {
      type = linkType;
    }

    const formData = new FormData();

    const formBody = {
      type,
      link: url,
      name: title,
      location: memberId,
      order,
    };

    formData.append("buttonInHome", JSON.stringify(formBody));
    formData.append("file", file);

		if(entityId) {
			await buttonAction.update(dispatch, entityId, formData);
		} else {
			await buttonAction.create(dispatch, formData);
		}

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
			enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          resetForm,
        } = formik;

        return (
          <div className="button-in-home">
            <div className="button-in-home-container">
						{error && (
                <Alert variant="danger">
                  {error}
                </Alert>
              )}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Button ${entityId ? "edited" : "created"} successfully.`}
                </Alert>
              )}
              <div className="button-in-home-header">
                <h1 className="button-in-home-title">New Button In Home</h1>
              </div>
              <Form className="button-in-home-form">
                <div className="button-in-home-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Order</Form.Label>
                      <Form.Control
                        name="order"
                        value={values.order}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.order && touched.order}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.order}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="button-in-home-form-radio-group">
                    <Form.Label>Link source</Form.Label>
                    <div className="button-in-home-form-radio">
                      <Form.Check
                        type="radio"
                        id="url"
                        label="URL"
                        name="source"
                        bsPrefix='form-check-input-custom'
                        value="url"
                        checked={values.source !== "upload"}
                        onChange={(e) => {
													// setFieldValue("file", "");
													handleChange(e);
												}}
                        isInvalid={errors.source && touched.source}
                      />
                      <Form.Check
                        type="radio"
                        id="upload"
                        label="Upload File"
                        bsPrefix='form-check-input-custom'
                        name="source"
                        value="upload"
                        checked={values.source === "upload"}
                        onChange={(e) => {
													// setFieldValue("url", "");
													handleChange(e);
												}}
                        isInvalid={errors.source && touched.source}
                      />
                    </div>
                  </div>
                  <div className="button-content-container">
                    {values.source === "upload" ? (
                      <div className="form-input">
                        <FileInput
                          title="Browse a File"
                          allowedTypes={"PDF, DOC, XLS"}
                          value={values.file}
                          setFieldValue={setFieldValue}
                          errorMessage={errors}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="button-in-home-form-radio-group">
                          <Form.Label>Points to</Form.Label>
                          <div className="button-in-home-form-radio">
                            <Form.Check
                              type="radio"
                              id="internal_link"
                              label="Internal Link"
                              name="linkType"
                              bsPrefix='form-check-input-custom'
                              value="internal_link"
                              checked={values.linkType !== "external_link"}
                              onChange={handleChange}
                              isInvalid={errors.linkType && touched.linkType}
                            />
                            <Form.Check
                              type="radio"
                              id="external_link"
                              label="External link"
                              name="linkType"
                              bsPrefix='form-check-input-custom'
                              value="external_link"
                              checked={values.linkType === "external_link"}
                              onChange={handleChange}
                              isInvalid={errors.linkType && touched.linkType}
                            />
                          </div>
                        </div>
                        <div className="form-input">
                          <Form.Label>URL</Form.Label>
                          <Form.Control
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.url && touched.url}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.url}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if(formik.dirty && !renderSuccessAlert){
                        setShowExitModal(true);
                      } else {
                        navigate(`/members/form/${memberId}/buttonsinhome`)
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default ButtonInHomeForm;
