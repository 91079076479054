/* eslint-disable react-hooks/exhaustive-deps */
import "./newsForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../../fileInput/fileInput";
import { Formik } from "formik";
import * as Yup from "yup";
import * as newsAction from "../../../../redux/actions/news.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";

const NewsForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
  const VALID_FILE_TYPES = ["image/png", "image/jpeg"];
  const [showExitModal, setShowExitModal] = useState(false);

  const { newsDetails, error } = useSelector((state) => ({
    newsDetails: state.news.newsDetails,
    error: state.news.newsError,
  }));

  const initialValues = {
    title: entityId ? newsDetails?.title || "" : "",
    category_name: entityId ? newsDetails?.category_name || "" : "",
    category_url: entityId ? newsDetails?.category_url || "" : "",
    mma_url: entityId ? newsDetails?.mma_url || "" : "",
    file: entityId ? newsDetails?.image : "",
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required field"),
    category_name: Yup.string().required("Required field"),
    category_url: Yup.string().url("Invalid URL").required("Required field"),
    mma_url: Yup.string().url("Invalid URL").required("Required field"),
    file: Yup.mixed()
      .required("Required field")
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid image extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
  });

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const { title, category_name, category_url, mma_url, file } = values;

    const formBody = {
      title,
      category_name,
      category_url,
      mma_url,
    };

    formData.append("news", JSON.stringify(formBody));
    formData.append("image", file);

    await newsAction.update(dispatch, entityId, formData);

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    newsAction.fetchDetails(dispatch, entityId);
  }, [dispatch, entityId]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && newsDetails) {
      setRenderSuccessAlert(true);
    }
  }, [newsDetails, submittedForm]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
        } = formik;

        return (
          <div className="news">
            <div className="news-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {t("news.editSuccess")}
                </Alert>
              )}
              <div className="news-header">
                <h1 className="news-title">
                  {entityId ? t("news.edit") : t("news.create")}
                </h1>
              </div>
              <Form className="news-form">
                <div className="news-form-layout">
                  <div className="form-column">
                    <div className="form-input">
                      <Form.Label>{t("news.title")}</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("news.mma_url")}</Form.Label>
                      <Form.Control
                        name="mma_url"
                        value={values.mma_url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.mma_url && touched.mma_url}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mma_url}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("news.category_name")}</Form.Label>
                      <Form.Control
                        name="category_name"
                        value={values.category_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.category_name && touched.category_name
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.category_name}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("news.category_url")}</Form.Label>
                      <Form.Control
                        name="category_url"
                        value={values.category_url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.category_url && touched.category_url}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.category_url}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="form-column">
                    <div className="form-input">
                      <Form.Label>{t("news.image")}</Form.Label>
                      <FileInput
                        title="Browse an Image"
                        allowedTypes={"PNG, JPG"}
                        value={values.file}
                        setFieldValue={setFieldValue}
                        errorMessage={errors}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={() => {
                      newsAction.empty(dispatch, entityId);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.clearnews")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default NewsForm;
