import Modal from 'react-bootstrap/Modal';
import ArticlePreview from '../articlePreview/articlePreview';

const ArticlePreviewModal = ({ show, handleClose, article }) => {
	return (
		<Modal size="lg" show={show} onHide={handleClose}>
			<Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Preview
        </Modal.Title>
      </Modal.Header>
			<Modal.Body style={{backgroundColor: '#f7f8f9'}} >
				<ArticlePreview article={article} />
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	);
}

export default ArticlePreviewModal;