import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";

const Option = ({
  handleChange,
  handleBlur,
  onClose,
  levelValue,
  levelName,
  onOpenLinkModal,
  onOpenVisibilityModal,
  onAddOption,
  secondLevel,
	thirdLevel,
}) => {

  const firstLevel = !secondLevel && !thirdLevel;

  return (
    <div
      className={`dropdown-form-option form-input ${
        secondLevel ? "second-level" : ""
      } ${thirdLevel ? "third-level" : ""}`}
    >
      <div className="dropdown-form-option-value">
        <div className="dropdown-form-option-fields">
          {(!secondLevel || levelValue?.default_type.value === "table") && !thirdLevel && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Add a New Option</Tooltip>}
            >
              <div onClick={onAddOption} className="dropdown-form-option-add">
                <span className="material-symbols-outlined">add</span>
              </div>
            </OverlayTrigger>
          )}
          {( levelValue?.default_type?.value !== "table" &&
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Add a Default Link</Tooltip>}
            >
              <div className="dropdown-form-option-link">
                <span
                  onClick={onOpenLinkModal}
                  className="material-symbols-outlined"
                >
                  add_link
                </span>
              </div>
            </OverlayTrigger>
          )}
          {( (firstLevel || secondLevel) &&
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Visibility Settings</Tooltip>}
            >
              <div className="dropdown-form-option-visibility">
                <span
                  onClick={onOpenVisibilityModal}
                  className="material-symbols-outlined"
                >
                  visibility_lock
                </span>
              </div>
            </OverlayTrigger>
          )}
          <Form.Control
            name={`${levelName}.name`}
            onChange={handleChange}
            value={levelValue?.name}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <span
        onClick={onClose}
        className="option-delete-button material-symbols-outlined"
      >
        close
      </span>
    </div>
  );
};

export default Option;
