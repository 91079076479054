
/* eslint-disable react-hooks/exhaustive-deps */
import "./programForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../../fileInput/fileInput";
import { Formik } from "formik";
import * as Yup from "yup";
import * as programAction from "../../../../redux/actions/program.action";
import * as memberAction from "../../../../redux/actions/member.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ReactMde from "react-mde";
import * as tagAction from "../../../../redux/actions/tag.action";
import * as tagGroupAction from "../../../../redux/actions/tagGroup.action";
import * as Showdown from "showdown";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import "react-mde/lib/styles/css/react-mde-all.css";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";

import { getTheme } from "../../../../helpers/select.theme";
import { customStyles } from '../../../../helpers/select.custom';
import Select from "react-select";
import {
  getMemberOptions,
  getInitialMemberValues,
  processMemberOptions,
  formatGroupLabel,
} from "../../../../helpers/select";

const ProgramForm = ({ type }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { entityId } = useParams();
	const navigate = useNavigate();
	const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
	const [submittedForm, setSubmittedForm] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [selectedTabDescription, setSelectedTabDescription] =
		React.useState("write");
	const [editMode, setEditMode] = useState(null); // draft, publish
	const [editAction, setEditAction] = useState({ change: null });
	const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
	const VALID_FILE_TYPES = ["image/png", "image/jpeg"];
	const [customResetForm, setCustomResetForm] = useState(() => { });
	const [showExitModal, setShowExitModal] = useState(false);

  	const [memberOptions, setMemberOptions] = useState([]);
	console.log('memberOptionsVVVVVVVVVVVVVVVVVVVVVVVVVVVVV', memberOptions)

	const converter = new Showdown.Converter({
		tables: true,
		simplifiedAutoLink: true,
		strikethrough: true,
		tasklists: true,
	});

	const { programDetails, program, error, memberGroupList, memberList } = useSelector(
		(state) => ({
			programDetails: state.program.programDetails,
    		memberList: state.member.memberSelectList,
			program: state.program.program,
			error: state.program.programError,
    		memberGroupList: state.memberGroup.memberGroupSelectList,
		})
	);

	const initialValues = {
    member: entityId
      ? getInitialMemberValues(programDetails, true)
      : [
          {
            value: "all",
            label: "All Members",
            secondaryLabel: "",
            type: "all",
          },
        ],
		title: entityId ? programDetails?.title || "" : "",
		name: entityId ? programDetails?.name || "" : "",
		link: entityId ? programDetails?.link || "" : "",
		description: entityId ? programDetails?.description || "" : "",
		file: entityId ? programDetails?.image?.data?.attributes || "" : "",
		tooltip: entityId ? programDetails?.tooltip || "" : "",
	};

	const formSchema = Yup.object().shape({
		// title and description are only required for health programs
		title: Yup.string(),
		description: Yup.string(),
		name: Yup.string().required("Required field"),
		link: Yup.string().required("Required field"),
		tooltip: Yup.string().required("Required field"),
		file: Yup.mixed()
			.required("Required field")
			.test(
				"is-correct-size",
				"File too large",
				(value, _) => (value?.size || 0) <= MAX_FILE_SIZE
			)
			.test(
				"is-correct-format",
				"Invalid file extension",
				(value, _) =>
					VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
			),
	});

	const onSubmitForm = async (values) => {
		setDisableSubmit(true); //Disable submit button

		const formData = new FormData();

		const {
      		member = [],
			title,
			name,
			link,
			file,
			description,
			tooltip,
		} = values;

    const { locations, location_groups, is_all_locations } =
      processMemberOptions(member);


		const formBody = {
			locations,
			location_groups,
			is_all_locations,
			name,
			description,
			link,
			title,
			tooltip,
			type: type?.toUpperCase(),
		};

		// Only add the image if it was changed
		if (file && file.type) formData.append("files.image", file);
		formData.append("data", JSON.stringify(formBody));
		if (entityId) {
			await programAction.update(dispatch, entityId, formData);
		} else {
			await programAction.create(dispatch, formData);
		}

		setSubmittedForm(true); // Flag to make sure that the form was submitted
		setDisableSubmit(false); // Reenable submit button
	};

	useEffect(() => {
		memberAction.fetchSelectOptions(dispatch, { isDropdown: true });
		tagAction.fetchSelectOptions(dispatch);
		tagGroupAction.fetchSelectOptions(dispatch);
    	memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });

		if (entityId) {
			//If id is passed as a parameter then load initial values
			programAction.fetchDetails(dispatch, entityId);
		}
	}, [dispatch, entityId]);

	useEffect(() => {
		//Render success alert and redirect
		if (submittedForm && program) {
			setRenderSuccessAlert(true);
			if (!entityId) {
				customResetForm();
			}
		}
	}, [entityId, program, submittedForm, navigate]);

	useEffect(() => {
		if (entityId && programDetails) {
			setEditMode(programDetails?.publishedAt ? "publish" : "draft");
		}
	}, [programDetails]);

	useEffect(() => {
		setMemberOptions(getMemberOptions(memberGroupList, memberList));
	}, [JSON.stringify(memberGroupList), JSON.stringify(memberList)]);

	console.log('memberList-3-3-3-3-3-3-', memberList)
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={formSchema}
			onSubmit={onSubmitForm}
		>
			{(formik) => {
				const {
					values,
					handleChange,
					handleSubmit,
					errors,
					touched,
					handleBlur,
					setFieldValue,
					resetForm,
				} = formik;

				return (
					<div className="program">
						<div className="program-container">
							{error && <Alert variant="danger">{error}</Alert>}
							{renderSuccessAlert && (
								<Alert
									variant="success"
									onClose={() => {
										setRenderSuccessAlert(false);
									}}
								>
									{entityId
										? t("programs.editSuccess")
										: t("programs.createSuccess")}
								</Alert>
							)}


							<Form className="program-form">
								<div className="program-form-layout">
									<div className="form-column">
										<div className="form-input">
											<Form.Label>{t("programs.title")}</Form.Label>
											<Form.Control
												name="title"
												value={values.title}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.title && touched.title}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.title}
											</Form.Control.Feedback>
										</div>
										<div className="form-input">
											<Form.Label>{t("programs.name")}</Form.Label>
											<Form.Control
												name="name"
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.name && touched.name}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.name}
											</Form.Control.Feedback>
										</div>
										<div className="form-input">
											<Form.Label>{t("programs.link")}</Form.Label>
											<Form.Control
												name="link"
												value={values.link}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.link && touched.link}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.link}
											</Form.Control.Feedback>
										</div>
										<div className="form-input">
											<Form.Label>{t("programs.tooltip")}</Form.Label>
											<Form.Control
												name="tooltip"
												value={values.tooltip}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.tooltip && touched.tooltip}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.tooltip}
											</Form.Control.Feedback>
										</div>
									
										<div className="form-area description">
											<Form.Label>{t("programs.description")}</Form.Label>
											<ReactMde
												as="textarea"
												name="description"
												value={values.description}
												onChange={(value) =>
													setFieldValue("description", value)
												}
												onBlur={handleBlur}
												isInvalid={errors.description && touched.description}
												selectedTab={selectedTabDescription}
												onTabChange={setSelectedTabDescription}
												generateMarkdownPreview={(markdown) =>
													Promise.resolve(converter.makeHtml(markdown))
												}
												childProps={{
													writeButton: {
														tabIndex: -1,
													},
												}}
											/>
										</div>
										<p
											className="input-info-error"
											style={{ marginLeft: ".5em" }}
										>
											{errors.description}
										</p>
									</div>

									<div className="form-column">
										<div className="form-input">
											<Form.Label>{t("programs.image")}</Form.Label>
											<FileInput
												title="Browse an Image"
												allowedTypes={"PNG, JPG"}
												value={values.file}
												setFieldValue={setFieldValue}
												errorMessage={errors}
											/>
										</div>
										<div className="form-input">
											<Form.Label>{t("carrousel.publishedIn")}</Form.Label>
											<Select
												theme={(theme) => getTheme(theme)}
												className="form-select-custom"
												isMulti
												styles={customStyles}
												value={values.member}
												onChange={(option) => setFieldValue("member", option)}
												closeMenuOnSelect={false}
												onBlur={handleBlur}

												defaultValue={{
													value: "all",
													label: "All Members",
													secondaryLabel: "",
													type: "all",
												}}
												options={memberOptions}
												isInvalid={errors.member && touched.member}
												formatGroupLabel={formatGroupLabel}
												getOptionLabel={(option) => (
													<div className="form-select-custom-label">
														<span className="form-select-custom-label-primary">
															{option.label}
														</span>
														<span className="form-select-custom-label-secondary">
															{option.secondaryLabel}
														</span>
													</div>
												)}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.member}
											</Form.Control.Feedback>
										</div>
									</div>

								</div>

								<div className="form-action-group">
									<Button
										onClick={() => {
											if (formik.dirty && !renderSuccessAlert) {
												setShowExitModal(true);
											} else {
												window.history.back();
											}
										}}
										className="form-action"
										variant="outline-primary"
									>
										{t("buttons.back")}
									</Button>
									<Button
										type="submit"
										className="form-action form-action-primary"
										variant="outline-primary"
										onClick={(e) => {
											if (editMode) {
												setEditAction({ change: false });
											} else {
												setCustomResetForm(() => resetForm);
											}
											handleSubmit(e);
										}}
										disabled={disableSubmit}
									>
										{t("buttons.save")}
									</Button>
								</div>
							</Form>
						</div>
						<ModalConfirmation
							show={showExitModal}
							handleShow={setShowExitModal}
							title={t("modalconfirmation.titleExit")}
							mainAction={() => setShowExitModal(false)}
							secondaryAction={() => window.history.back()}
							question={t("modalconfirmation.exitquestion")}
							mainButton={t("modalconfirmation.continue")}
							secondaryButton={t("modalconfirmation.yesleave")}
						/>
					</div>
				);
			}}
		</Formik>
	);
};

export default ProgramForm;
