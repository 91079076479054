/* eslint-disable react-hooks/exhaustive-deps */
import "./newsletter.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../fileInput/fileInput";
import { Formik } from "formik";
import * as Yup from "yup";
import * as newsletterAction from "../../../redux/actions/newsletter.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ModalConfirmation from "../../modalconfirmation/ModalConfirmation";

const Newsletter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
  const VALID_FILE_TYPES = ["image/png", "image/jpeg"];
  const [showExitModal, setShowExitModal] = useState(false);

  const {
    newsletterDetails,
    newsletter,
    error,
  } = useSelector((state) => ({
    newsletterDetails: state.newsletter.newsletterDetails,
    newsletter: state.newsletter.newsletter,
    error: state.newsletter.newsletterError,
  }));

  const initialValues = {
    link: newsletterDetails?.link || "" ,
    file: newsletterDetails?.image,
    title: newsletterDetails?.title || "",
  };

  const formSchema = Yup.object().shape({
    link: Yup.string().required("Required field"),
    title: Yup.string().required("Required field"),
    file: Yup.mixed()
      .required("Required field")
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid file extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
  });

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      link,
      file,
      title
    } = values;

    const formBody = {
      link,
      title
    };

    formData.append("newsletter", JSON.stringify(formBody));
    formData.append("image", file);

		await newsletterAction.update(dispatch, newsletterDetails?.id, formData);

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    newsletterAction.fetchDetails(dispatch);
  }, [dispatch]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && newsletter) {
      setRenderSuccessAlert(true);
    }
  }, [newsletter, submittedForm, navigate]);


  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
        } = formik;

        return (
          <div className="newsletter">
            <div className="newsletter-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {t("newsletters.editSuccess")}
                </Alert>
              )}
              <div className="newsletter-header">
                <h1 className="newsletter-title">
                  {t("newsletters.editNewsletter")}
                </h1>
              </div>
              <Form className="newsletter-form">
                <div className="newsletter-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("newsletters.link")}</Form.Label>
                      <Form.Control
                        name="link"
                        value={values.link}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.link && touched.link}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.link}
                      </Form.Control.Feedback>
                    </div>
                  <div className="form-input">
                      <Form.Label>{t("newsletters.title")}</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("newsletters.image")}</Form.Label>
                      <FileInput
                        title="Browse an Image"
                        allowedTypes={"PNG, JPG"}
                        value={values.file}
                        setFieldValue={setFieldValue}
                        errorMessage={errors}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default Newsletter;
