import { createSlice } from "@reduxjs/toolkit";

const pageReducer = createSlice({
  name: "page",
  initialState: {
    pageDetails: null,
    page: null,
    pageList: null,
    pageMetadata: null,
    pageError: '',
    listLoading: false,
  },
  reducers: {
    setPageDetail: (state, action) => {
      state.pageDetails = action.payload.data;
      state.pageError = null;
    },
    setPageList: (state, action) => {
      state.pageList = action.payload.data;
      state.pageError = null;
    },
    setMetadata: (state, action) => {
      state.pageMetadata = action.payload.meta;
    },
    setPage: (state, action) => {
      state.page = action.payload.data;
      state.pageError = null;
    },
    setPageError: (state, action) => {
      state.pageError = action.payload.error;
    },
    cleanPageState: (state) => {
      state = pageReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setPageDetail,
  setPageList,
  setPageError,
  setCreatedPage,
  setEditedPage,
  setMetadata,
  setPage,
  cleanPageState,
  setListLoading,
} = pageReducer.actions;

export default pageReducer.reducer;