import { createSlice } from "@reduxjs/toolkit";

const dropdownReducer = createSlice({
  name: "dropdown",
  initialState: {
    dropdownDetails: null,
    dropdown: null,
    orderDropdowns: null,
    dropdownList: null,
    dropdownMetadata: null,
    dropdownError: "",
    dropdownOptionValue: null,
    dropdownOptionDetails: null,
    dropdownOptionValueDetails: null,
    reportCategories: null,
    listLoading: false,
  },
  reducers: {
    setDropdownDetail: (state, action) => {
      state.dropdownDetails = action.payload.data;
      state.dropdownError = null;
    },
    setDropdownList: (state, action) => {
      state.dropdownList = action.payload.data;
      state.dropdownError = null;
    },
    setDropdownOptionDetail: (state, action) => {
      state.dropdownOptionDetails = action.payload.data;
      state.dropdownError = null;
    },
    setDropdownOptionValueDetail: (state, action) => {
      state.dropdownOptionValueDetails = action.payload.data;
      state.dropdownError = null;
    },
    setDropdownOptionValue: (state, action) => {
      state.dropdownOptionValue = action.payload.data;
      state.dropdownError = null;
    },
    setMetadata: (state, action) => {
      state.dropdownMetadata = action.payload.meta;
    },
    setDropdown: (state, action) => {
      state.dropdown = action.payload.data;
      state.dropdownError = null;
    },
    setDropdownError: (state, action) => {
      state.dropdownError = action.payload.error;
    },
    cleanDropdownState: (state) => {
      state = dropdownReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setReportCategories: (state, action) => {
      state.reportCategories = action.payload.data;
    },
    setOrderDropdowns: (state, action) => {
      state.orderDropdowns = action.payload.data;
    },
  },
});

export const {
  setDropdownDetail,
  setDropdownList,
  setDropdownError,
  setDropdown,
  setMetadata,
  cleanDropdownState,
  setDropdownOptionDetail,
  setDropdownOptionValueDetail,
  setDropdownOptionValue,
  setListLoading,
  setReportCategories,
  setOrderDropdowns,
} = dropdownReducer.actions;

export default dropdownReducer.reducer;
