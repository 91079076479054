// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview .action-box {
  color: #8F003C;
  font-size: 17px;
  text-decoration: none;
}
.overview .box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  top: -100px;
}
.overview .box-data {
  background-color: white;
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
}
.overview .box-data-title {
  align-self: center;
  text-align: center;
  font-size: 18px;
  margin-bottom: 0px;
}
.overview .rounded {
  border-radius: 50% !important;
  height: 50px;
  width: 50px;
  background-color: #8F003C;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overview .overview-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/overviewinfo/OvewviewInfo.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,eAAA;EACA,qBAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,kBAAA;EACA,WAAA;AADR;AAII;EACI,uBAAA;EACA,UAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AAFR;AAKI;EACI,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AAHR;AAMI;EACI,6BAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAJR;AAOI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AALR","sourcesContent":[".overview{\n    .action-box {\n        color: #8F003C;\n        font-size: 17px;\n        text-decoration: none;\n    }\n\n    .box-container {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-around;\n        position: relative;\n        top: -100px;\n    }\n\n    .box-data {\n        background-color: white;\n        width: 25%;\n        display: flex;\n        justify-content: flex-start;\n        flex-direction: column;\n        align-content: center;\n        align-items: center;\n        padding: 10px;\n        border-radius: 6px;\n    }\n\n    .box-data-title {\n        align-self: center;\n        text-align: center;\n        font-size: 18px;\n        margin-bottom: 0px;\n    }\n\n    .rounded {\n        border-radius: 50% !important;\n        height: 50px;\n        width: 50px;\n        background-color: #8F003C;\n        margin-bottom: 5px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .overview-icon{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        color: white;\n        cursor: default;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
