/* eslint-disable react-hooks/exhaustive-deps */
import "./articleForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../../fileInput/fileInput";
import { Formik } from "formik";
import * as Yup from "yup";
import * as articleAction from "../../../../redux/actions/article.action";
import * as memberAction from "../../../../redux/actions/member.action";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import * as tagAction from "../../../../redux/actions/tag.action";
import * as tagGroupAction from "../../../../redux/actions/tagGroup.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import {
  getInitialMemberValues,
  getInitialTagValues,
  getMemberOptions,
  getTagOptions,
  processMemberOptions,
  processTagOptions,
  formatGroupLabel,
} from "../../../../helpers/select";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import ArticlePreviewModal from "../articlePreviewModal/articlePreviewModal";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from '../../../../helpers/select.custom';

const ArticleForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState("write");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [createMode, setCreateMode] = useState({ isDraft: null });
  const [editMode, setEditMode] = useState(null); // draft, publish
  const [editAction, setEditAction] = useState({ change: null });
  const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
  const VALID_FILE_TYPES = ["image/png", "image/jpeg"];
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showPreview, setShowPreview] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  const handleClose = () => setShowPreview(false);
  const handleShow = () => setShowPreview(true);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const {
    articleDetails,
    article,
    error,
    memberList,
    memberGroupList,
    tagList,
    tagGroupList,
  } = useSelector((state) => ({
    articleDetails: state.article.articleDetails,
    article: state.article.article,
    error: state.article.articleError,
    memberList: state.member.memberSelectList,
    memberGroupList: state.memberGroup.memberGroupSelectList,
    tagList: state.tag.tagList,
    tagGroupList: state.tagGroup.tagGroupList,
  }));

  const initialValues = {
    member: entityId
      ? getInitialMemberValues(articleDetails, true)
      : [
          {
            value: "all",
            label: "All Members",
            secondaryLabel: "",
            type: "all",
          },
        ],
    title: entityId ? articleDetails?.title || "" : "",
    description: entityId ? articleDetails?.description || "" : "",
    content: entityId ? articleDetails?.body || "" : "",
    author: entityId ? articleDetails?.author || "" : "",
    file: entityId ? articleDetails?.image : "",
    tags: entityId ? getInitialTagValues(articleDetails) : [],
  };

  const formSchema = Yup.object().shape({
    member: Yup.mixed(),
    title: Yup.string().required("Required field"),
    description: Yup.string().required("Required field"),
    content: Yup.string().required("Required field"),
    author: Yup.string().required("Required field"),
    tags: Yup.mixed(),
    file: Yup.mixed()
      .required("Required field")
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid file extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
  });

  const onSubmitForm = async (values, { resetForm }) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      member = [],
      title,
      description,
      content,
      author,
      file,
      tags = [],
    } = values;

    const locationOptions = processMemberOptions(member);
    const tagOptions = processTagOptions(tags);

    const formBody = {
      locations: locationOptions.locations,
      location_groups: locationOptions.location_groups,
      is_all_locations: locationOptions.is_all_locations,
      tags: tagOptions.tags,
      tag_groups: tagOptions.tag_groups,
      title,
      description,
      body: content,
      author,
      draft: createMode.isDraft, // create as draft or published
      change: editAction.change, // switch from draft to publish and vice versa
    };

    formData.append("article", JSON.stringify(formBody));
    formData.append("image", file);

    if (entityId) {
      await articleAction.update(dispatch, entityId, formData);
    } else {
      await articleAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    memberAction.fetchSelectOptions(dispatch, { isDropdown: true });
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
    tagAction.fetchSelectOptions(dispatch);
    tagGroupAction.fetchSelectOptions(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      articleAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setMemberOptions(getMemberOptions(memberGroupList, memberList));
  }, [JSON.stringify(memberGroupList), JSON.stringify(memberList)]);

  useEffect(() => {
    setTagOptions(getTagOptions(tagGroupList, tagList));
  }, [JSON.stringify(tagGroupList), JSON.stringify(tagList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && article) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, article, submittedForm, navigate]);

  useEffect(() => {
    if (entityId && articleDetails) {
      setEditMode(articleDetails?.publishedAt ? "publish" : "draft");
    }
  }, [articleDetails]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
          resetForm,
        } = formik;

        return (
          <div className="article">
            <div className="article-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {entityId
                    ? t("articles.editSuccess")
                    : t("articles.createSuccess")}
                </Alert>
              )}
              <div className="article-header">
                <h1 className="article-title">
                  {entityId
                    ? t("articles.editArticle")
                    : t("articles.newArticle")}
                </h1>
                <Button
                  onClick={handleShow}
                  className="form-action preview-btn"
                >
                  Preview
                </Button>
              </div>
              <Form className="article-form">
                <div className="article-form-layout">
                  <div className="form-column">
                    <div className="form-input">
                      <Form.Label>{t("articles.publishedIn")}</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        isMulti
                        styles={customStyles}
                        value={values.member}
                        onChange={(option) => setFieldValue("member", option)}
                        closeMenuOnSelect={false}
                        onBlur={handleBlur}
                        options={memberOptions}
                        isInvalid={errors.member && touched.member}
                        formatGroupLabel={formatGroupLabel}
                        getOptionLabel={(option) => (
                          <div className="form-select-custom-label">
                            <span className="form-select-custom-label-primary">
                              {option.label}
                            </span>
                            <span className="form-select-custom-label-secondary">
                              {option.secondaryLabel}
                            </span>
                          </div>
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.member}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("articles.title")}</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("articles.description")}</Form.Label>
                      <Form.Control
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-area">
                      <Form.Label>{t("articles.content")}</Form.Label>
                      <ReactMde
                        value={values.content}
                        onChange={(value) => setFieldValue("content", value)}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                          Promise.resolve(converter.makeHtml(markdown))
                        }
                        childProps={{
                          writeButton: {
                            tabIndex: -1,
                          },
                        }}
                      />
                      <p className="input-info-error">{errors.content}</p>
                    </div>
                  </div>

                  <div className="form-column">
                    <div className="form-input">
                      <Form.Label>{t("articles.image")}</Form.Label>
                      <FileInput
                        title="Browse an Image"
                        allowedTypes={"PNG, JPG"}
                        value={values.file}
                        setFieldValue={setFieldValue}
                        errorMessage={errors}
                      />
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("articles.author")}</Form.Label>
                      <Form.Control
                        name="author"
                        value={values.author}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.author && touched.author}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.author}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("articles.tags")}</Form.Label>
                      <Select
                        isMulti
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        styles={customStyles}
                        value={values.tags}
                        onChange={(option) => setFieldValue("tags", option)}
                        closeMenuOnSelect={false}
                        onBlur={handleBlur}
                        options={tagOptions}
                        isInvalid={errors.tags && touched.tags}
                        formatGroupLabel={formatGroupLabel}
                        getOptionLabel={(option) => (
                          <div className="form-select-custom-label">
                            <span className="form-select-custom-label-primary">
                              {option.label}
                            </span>
                            <span className="form-select-custom-label-secondary">
                              {option.secondaryLabel}
                            </span>
                          </div>
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.tags}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      if (editMode) {
                        setEditAction({ change: true });
                      } else {
                        setCreateMode({ isDraft: true });
                        setCustomResetForm(() => resetForm);
                      }
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {editMode === "draft"
                      ? t("buttons.publish")
                      : editMode === "publish"
                      ? t("buttons.draft")
                      : t("buttons.draft")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      if (editMode) {
                        setEditAction({ change: false });
                      } else {
                        setCreateMode({ isDraft: false });
                        setCustomResetForm(() => resetForm);
                      }
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {editMode ? t("buttons.save") : t("buttons.savepublish")}
                  </Button>
                </div>
              </Form>
            </div>
            <ArticlePreviewModal
              show={showPreview}
              handleClose={handleClose}
              article={values}
            />
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default ArticleForm;
