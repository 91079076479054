import Pagination from "react-bootstrap/Pagination";

export const paginate = ({ current, max }) =>  {
	//Algorithm for showing pagination buttons
	if (!current || !max) return null;

	let prev = current === 1 ? null : current - 1,
		next = current === max ? null : current + 1,
		items = [1];

	if (current === 1 && max === 1) return { current, prev, next, items };
	if (current > 4) items.push("E");

	let r = 2,
		r1 = current - r,
		r2 = current + r;

	for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i);

	if (r2 + 1 < max) items.push("E");
	if (r2 < max) items.push(max);

	return { current, prev, next, items };
};

export const populatePagination = (items, pageRef, filterParams, onClickPage) => {
	let paginationList = [];
	
	items?.forEach((page, index) => {
		let itemToPush = null;
		if (page === "E") {
			itemToPush = <Pagination.Ellipsis key={page} />;
		} else {
			itemToPush = (
				<Pagination.Item
					id={`page-${page}`}
					ref={(element) => {
						pageRef.current[index] = element;
					}}
					className={
						filterParams?.page === page ? "pagination-container-selected" : ""
					}
					onClick={() => onClickPage(page)}
					key={page}
					activeLabel={""}
				>
					{page}
				</Pagination.Item>
			);
		}
		paginationList.push(itemToPush);
	});

	return paginationList;
}