import "./dropdownForm.scss";
import { Formik, FieldArray } from "formik";
import { Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import * as productAction from "../../../../redux/actions/product.action";
import * as dropdownAction from "../../../../redux/actions/dropdown.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import { getTheme } from "../../../../helpers/select.theme";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import ModalOptionForm from "../../../modalOptionForm/modalOptionForm";
import ModalAddOption from "../../../modalAddOption/modalAddOption";
import Option from "./option";
import {
  getFile,
  getIdentifier,
  processOptions,
  getOptionsInitialValues,
} from "../../../../helpers/dropdowns";
import FileInput from "../../../fileInput/fileInput";
import { customStyles } from "../../../../helpers/select.custom";
import ModalVisibility from "../../../modalVisibility/modalVisibility";

const DropdownForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [optionsToDelete, setOptionsToDelete] = useState([]);
  const { dropdownDetails, dropdown, categories, productList, error } =
    useSelector((state) => ({
      dropdownDetails: state.dropdown.dropdownDetails,
      productList: state.product.productList,
      dropdown: state.dropdown.dropdown,
      categories: state.dropdown.reportCategories,
      error: state.dropdown.error,
    }));
  const [showExitModal, setShowExitModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAddOptionModal, setShowAddOptionModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [context, setContext] = useState({});
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [optionModalContext, setOptionModalContext] = useState({});
  const [visibilityModalContext, setVisibilityModalContext] = useState({});
  const hasChilds = (dropdown) => {
    return dropdown?.options?.length > 0;
  };

  const addDeletedOption = (id, second_level_options) => {
    if (id) {
      setOptionsToDelete([
        ...optionsToDelete,
        id,
        ...second_level_options?.map((option) => option.id),
      ]);
    }
  };

  const getLabel = {
    internal_link: "Internal Link",
    external_link: "External Link",
    file: "File",
    email: "Send an email",
    ftp: "Download a Report",
  };

  const initialValues = {
    name: entityId ? dropdownDetails?.name || "" : "",
    order: entityId ? dropdownDetails?.order || "" : "",
    product_operator: entityId
      ? dropdownDetails?.product_operator || ""
      : "and",
    options: entityId ? getOptionsInitialValues(dropdownDetails) : [],
    product: entityId
      ? dropdownDetails?.products.map((product) => ({
          value: product.id,
          label: product.name,
        }))
      : [],
    default_type: entityId
      ? {
          value: dropdownDetails?.default_type,
          label: getLabel[dropdownDetails?.default_type],
        } || {
          value: "internal_link",
          label: "Internal link",
        }
      : { value: "internal_link", label: "Internal link" },
    default_link: entityId ? dropdownDetails?.default_link || "" : "",
    default_file: entityId ? dropdownDetails?.default_file || "" : "",
    default_category: entityId ? dropdownDetails?.default_category || "" : "",
    download_previous_report: entityId ? dropdownDetails?.download_previous_report || false : false,
    report_period: dropdownDetails?.report_period || { value: "previous_month", label: "Previous Month" }
  };

  useEffect(() => {
    productAction.fetchSelectOptions(dispatch);
    dropdownAction.fetchReportCategories(dispatch);

    if (entityId) {
      dropdownAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    if (categories) {
      setCategoryOptions(
        categories?.data?.categories?.map((category) => ({
          value: category,
          label: category,
        }))
      );
    }
  }, [categories]);

  const formSchema = Yup.object().shape({
    product: Yup.mixed().required("Required field"),
    name: Yup.string().required("Required field"),
    product_operator: Yup.string(),
    order: Yup.number().typeError("Order must be of type 'Number'"),
    options: Yup.array(),
  });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setDisableSubmit(true); //Disable submit button

    const {
      name,
      order,
      product,
      options,
      default_category,
      default_type,
      default_file,
      default_link,
      product_operator,
      download_previous_report,
      report_period,
      memberGroups
    } = values;

    let dropdown_options = processOptions(options);

    const formBody = {
      name,
      default_category,
      product_operator,
      default_type: !hasChilds(values) ? default_type.value : "dropdown",
      default_file,
      default_link,
      order,
      products: product.map((product) => product.value),
      optionsToDelete,
      dropdown_options,
      memberGroupIds: memberGroups?.map((memberGroup) => memberGroup.value),
    };

    const formData = new FormData();

    formData.append("file", default_file);

    // Append file to dropdown_options and child dropdown_options
    dropdown_options.forEach((option, firstIndex) => {
      if (option.default_type === "file") {
        formData.append(getIdentifier(firstIndex), getFile(option));
      }

      if (option.dropdown_options) {
        option.dropdown_options.forEach((secondLevelOption, secondIndex) => {
          if (secondLevelOption.default_type === "file") {
            formData.append(
              getIdentifier(firstIndex, secondIndex),
              getFile(secondLevelOption)
            );
          }

          if (secondLevelOption.dropdown_options) {
            secondLevelOption.dropdown_options.forEach(
              (thirdLevelOption, thirdIndex) => {
                if (thirdLevelOption.default_type === "file") {
                  formData.append(
                    getIdentifier(firstIndex, secondIndex, thirdIndex),
                    getFile(thirdLevelOption)
                  );
                }
              }
            );
          }
        });
      }
    });

    formData.append("dropdown", JSON.stringify(formBody));

    console.log('formData', formData)
    if (entityId) {
      await dropdownAction.update(dispatch, entityId, formData);
    } else {
      await dropdownAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && dropdown) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      } else {
        dropdownAction.fetchDetails(dispatch, entityId);
      }
    }
  }, [entityId, dropdown, submittedForm, navigate, customResetForm, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          resetForm,
        } = formik;


        return (
          <div className="dropdown">
            <div className="dropdown-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Dropdown ${entityId ? "edited" : "created"} successfully.`}
                </Alert>
              )}
              <div className="dropdown-header">
                <h1 className="dropdown-title">
                  {`${entityId ? "Edit" : "New"} Dropdown`}
                </h1>
              </div>
              <Form className="dropdown-form">
                <div className="dropdown-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>First Level Name</Form.Label>
                      <Form.Control
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.name && touched.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </div>
                    <div>
                      <div className="form-input">
                        <Form.Label>Required Products</Form.Label>
                        <Select
                          theme={(theme) => getTheme(theme)}
                          className="form-select-custom dropdown-select"
                          value={values.product}
                          isMulti
                          styles={customStyles}
                          onChange={(option) =>
                            setFieldValue("product", option)
                          }
                          closeMenuOnSelect={true}
                          onBlur={handleBlur}
                          options={productList?.data?.map(
                            ({ id, attributes: { name } }) => ({
                              value: id,
                              label: name,
                            })
                          )}
                          isInvalid={errors.product && touched.product}
                        />
                        <span className="input-info-error">
                          {errors.product}
                        </span>
                      </div>
                      <div className="dropdown-operator-container">
                        <div className="dropdown-operator">
                          <span
                            className={
                              values.product_operator === "or"
                                ? "dropdown-operator-selected"
                                : undefined
                            }
                          >
                            OR
                          </span>
                          <Form.Check
                            bsPrefix="form-custom-switch"
                            type="switch"
                            value={values.product_operator}
                            onChange={() => {
                              setFieldValue(
                                "product_operator",
                                values.product_operator === "or" ? "and" : "or"
                              );
                            }}
                            onBlur={handleBlur}
                            id="product_operator"
                            name="product_operator"
                            checked={values.product_operator !== "or"}
                          ></Form.Check>
                          <span
                            className={
                              values.product_operator !== "or"
                                ? "dropdown-operator-selected"
                                : undefined
                            }
                          >
                            AND
                          </span>
                          <span className="dropdown-operator-feedback">{`Dropdown will be displayed if the member purchased ${
                            values.product_operator === "or" ? "any" : "all"
                          } of the selected products`}</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-input">
                      <Form.Label>Order</Form.Label>
                      <Form.Control
                        name="order"
                        value={values.order}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.order && touched.order}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.order}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  {!hasChilds(values) && (
                    <>
                      <div className="form-row">
                        <div className="form-input">
                          <Form.Label>Type</Form.Label>
                          <Select
                            className="modal-option-value-form-select"
                            theme={(theme) => getTheme(theme)}
                            closeMenuOnSelect={true}
                            value={values.default_type}
                            onChange={(value) => {
                              setFieldValue("default_type", value);
                            }}
                            options={[
                              {
                                value: "internal_link",
                                label: "Internal Link",
                              },
                              {
                                value: "external_link",
                                label: "External Link",
                              },
                              { value: "file", label: "File" },
                              { value: "email", label: "Send an email" },
                              { value: "ftp", label: "Download a Report" },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        {values?.default_type?.value === "file" && (
                          <div className="form-input">
                            <FileInput
                              title="Browse a File"
                              allowedTypes={"PDF, DOC, XLS"}
                              value={values?.default_file}
                              customFieldName={"default_file"}
                              setFieldValue={setFieldValue}
                              errorMessage={errors}
                            />
                          </div>
                        )}
                        {["internal_link", "external_link", "email"].includes(
                          values?.default_type?.value
                        ) && (
                          <div className="form-input">
                            <Form.Label>
                              {values?.default_type?.value === "email"
                                ? "Email"
                                : "URL"}
                            </Form.Label>
                            <Form.Control
                              name={"default_link"}
                              value={values?.default_link}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                errors.default_link && touched.default_link
                              }
                            />
                            <Form.Control.Feedback default_type="invalid">
                              {errors.default_link}
                            </Form.Control.Feedback>
                          </div>
                        )}
                        {values?.default_type?.value === "ftp" && (
                          <div className="form-input">
                            <Form.Label>Available Reports</Form.Label>
                            <Select
                              className="modal-option-value-form-select"
                              theme={(theme) => getTheme(theme)}
                              closeMenuOnSelect={true}
                              value={values?.default_category}
                              onChange={(value) => {
                                setFieldValue("default_category", value);
                              }}
                              options={categoryOptions}
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-row">
                        {
                          values?.default_type?.value === "ftp" &&
                          < Form.Check
                            type="checkbox"
                            label="Report of previous month/quarter/year/semester"
                            checked={values?.download_previous_report}
                            onChange={(e) => setFieldValue("download_previous_report", e.target.checked)}
                          />
                          }
                          {
                            values?.download_previous_report &&
                            <Select
                              className="modal-option-value-form-select"
                              theme={(theme) => getTheme(theme)}
                              closeMenuOnSelect={true}
                              value={values?.report_period}
                              onChange={(value) => {
                                setFieldValue("report_period", value);
                              }}
                            options={
                              [
                                { value: "current", label: "Current" },
                                { value: "second_most_recent", label: "Second Most Recent" },
                                { value: "previous_month", label: "Previous Month" },
                                { value: "previous_bimester", label: "Previous Bimester" },
                                { value: "previous_quarter", label: "Previous Quarter" },
                                { value: "previous_semester", label: "Previous Semester" },
                                { value: "previous_year", label: "Previous Year" },
                              ]}
                          />
                        }
                      </div>
                    </>
                  )}
                  {values.options?.length > 0 && (
                    <Form.Label className="dropdown-form-option-title">
                      Options
                    </Form.Label>
                  )}
                  {
                    <FieldArray
                      name="options"
                      render={(arrayHelpers) => (
                        <div className="options-container">
                          {values.options?.map((option, index) => (
                            <div key={`p-${index}`}>
                              <div className="form-row">
                                <Option
                                  option={option}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  onOpenVisibilityModal={() => {
                                    setVisibilityModalContext({
                                      levelName: `options.${index}`,
                                      option,
                                      index,
                                      secondLevelIndex: null
                                    });
                                    setShowVisibilityModal(true);
                                  }}
                                  onClose={() => {
                                    arrayHelpers.remove(index);
                                    addDeletedOption(
                                      option?.id,
                                      option?.second_level_options
                                    );
                                  }}
                                  levelValue={values.options[index]}
                                  levelName={`options.${index}`}
                                  onOpenLinkModal={() => {
                                    setContext({
                                      level: 1,
                                      name: option.name,
                                      firstLevelIndex: index,
                                    });
                                    setShowFormModal(true);
                                  }}
                                  onAddOption={() => {
                                    if (
                                      option?.default_type?.value === "table"
                                    ) {
                                      setFieldValue(`options.${index}`, {
                                        ...option,
                                        second_level_options: [
                                          ...option.second_level_options,
                                          {
                                            name: "New Second Level Option",
                                            default_type: {
                                              value: "internal_link",
                                              label: "Internal Link",
                                            },
                                            is_visible: true,
                                            third_level_options: [],
                                          },
                                        ],
                                      });
                                      return;
                                    }

                                    setOptionModalContext({
                                      fieldName: `options.${index}`,
                                      fieldData: {
                                        ...option,
                                        second_level_options: [
                                          ...option.second_level_options,
                                        ],
                                      },
                                    });
                                    setShowAddOptionModal(true);
                                  }}
                                  setFieldValue={setFieldValue}
                                  index={index}
                                />
                              </div>
                              {option.second_level_options?.length > 0 &&
                                option.second_level_options.map(
                                  (secondLevelOption, secondLevelIndex) => (
                                    <div className="second-level-container">
                                      <div
                                        key={`${index}-${secondLevelIndex}`}
                                        className="form-row"
                                      >
                                        <Option
                                          secondLevel={true}
                                          option={secondLevelOption}
                                          onOpenVisibilityModal={() => {
                                            setVisibilityModalContext({
                                              secondLevelOption,
                                              index,
                                              secondLevelIndex,
                                              levelName: `options.${index}.second_level_options.${secondLevelIndex}`,
                                            });
                                            setShowVisibilityModal(true);
                                          }}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          parentOption={option}
                                          onClose={() => {
                                            setFieldValue(`options.${index}`, {
                                              ...option,
                                              second_level_options: [
                                                ...option.second_level_options.filter(
                                                  (option, optionIndex) =>
                                                    optionIndex !==
                                                    secondLevelIndex
                                                ),
                                              ],
                                            });
                                            addDeletedOption(
                                              secondLevelOption?.id,
                                              []
                                            );
                                          }}
                                          levelValue={
                                            values.options[index]
                                              .second_level_options[
                                              secondLevelIndex
                                            ]
                                          }
                                          levelName={`options.${index}.second_level_options.${secondLevelIndex}`}
                                          onOpenLinkModal={() => {
                                            setContext({
                                              level: 2,
                                              name: secondLevelOption.name,
                                              firstLevelIndex: index,
                                              secondLevelIndex:
                                                secondLevelIndex,
                                            });
                                            setShowFormModal(true);
                                          }}
                                          onAddOption={() =>
                                            setFieldValue(
                                              `options.${index}.second_level_options.${secondLevelIndex}`,
                                              {
                                                ...secondLevelOption,
                                                third_level_options: [
                                                  ...secondLevelOption.third_level_options,
                                                  {
                                                    name: "New Third Level Option",
                                                    default_type: {
                                                      value: "internal_link",
                                                      label: "Internal Link",
                                                    },
                                                  },
                                                ],
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                      {secondLevelOption.third_level_options
                                        ?.length > 0 &&
                                        secondLevelOption.third_level_options?.map(
                                          (
                                            thirdLevelOption,
                                            thirdLevelIndex
                                          ) => (
                                            <div
                                              key={`${index}-${secondLevelIndex}-${thirdLevelIndex}`}
                                              className="form-row"
                                            >
                                              <Option
                                                thirdLevel={true}
                                                option={thirdLevelOption}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                parentOption={secondLevelOption}
                                                onClose={() => {
                                                  setFieldValue(
                                                    `options.${index}.second_level_options.${secondLevelIndex}`,
                                                    {
                                                      ...secondLevelOption,
                                                      third_level_options: [
                                                        ...secondLevelOption.third_level_options.filter(
                                                          (
                                                            option,
                                                            optionIndex
                                                          ) =>
                                                            optionIndex !==
                                                            thirdLevelIndex
                                                        ),
                                                      ],
                                                    }
                                                  );
                                                  addDeletedOption(
                                                    thirdLevelOption?.id,
                                                    []
                                                  );
                                                }}
                                                levelValue={
                                                  values.options[index]
                                                    .second_level_options[
                                                    secondLevelIndex
                                                  ].third_level_options[
                                                    thirdLevelIndex
                                                  ]
                                                }
                                                levelName={`options.${index}.second_level_options.${secondLevelIndex}.third_level_options.${thirdLevelIndex}`}
                                                onOpenLinkModal={() => {
                                                  setContext({
                                                    level: 3,
                                                    name: thirdLevelOption.name,
                                                    firstLevelIndex: index,
                                                    thirdLevelIndex:
                                                      thirdLevelIndex,
                                                    secondLevelIndex:
                                                      secondLevelIndex,
                                                  });
                                                  setShowFormModal(true);
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                    </div>
                                  )
                                )}
                            </div>
                          ))}
                          <div className="form-row">
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  name: "New Option",
                                  default_type: {
                                    value: "table",
                                    label: "Table",
                                  },
                                  second_level_options: [],
                                })
                              }
                              className="form-action dropdown-option-action"
                            >
                              Add a New Table Option
                            </Button>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  name: "New Option",
                                  default_type: {
                                    value: "internal_link",
                                    label: "Internal Link",
                                  },
                                  second_level_options: [],
                                })
                              }
                              className="form-action dropdown-option-action"
                            >
                              Add a New Option
                            </Button>
                          </div>
                        </div>
                      )}
                    />
                  }
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    Back
                  </Button>
                  <Button
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    disabled={disableSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
            <ModalOptionForm
              show={showFormModal}
              handleShow={setShowFormModal}
              title="Default Link"
              formik={formik}
              context={context}
              categoryOptions={categoryOptions}
              mainAction={() => {}}
              secondaryAction={() => {}}
            />
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
            <ModalAddOption
              show={showAddOptionModal}
              handleShow={setShowAddOptionModal}
              setFieldValue={setFieldValue}
              optionModalContext={optionModalContext}
            />
            <ModalVisibility
              show={showVisibilityModal}
              handleShow={setShowVisibilityModal}
              setFieldValue={setFieldValue}
              productList={productList}
              values={values}
              visibilityModalContext={visibilityModalContext}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default DropdownForm;
