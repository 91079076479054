import * as action from "../reducers/newsletterReducer";
import axios, { authHeader } from "../../services/HTTPService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchDetails = async (dispatch) => {
  return axios
    .get(API_URL + `/api/newsletters/active`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setNewsletterDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setNewsletterError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/newsletters/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setNewsletter({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setNewsletterError({ error: error?.response?.data?.error?.message })
      );
    });
};
