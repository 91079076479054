// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-option-body {
  display: flex;
}

.modal-option-description {
  font-size: 0.8em;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/modalAddOption/modalAddOption.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AAEA;EACC,gBAAA;EACA,WAAA;AACD","sourcesContent":[".modal-option-body {\n\tdisplay: flex;\n}\n\n.modal-option-description {\n\tfont-size: 0.8em;\n\tcolor: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
