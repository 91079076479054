import Badge from "react-bootstrap/Badge";

export const getInitialMemberValues = (details, all) => {
  if (!details) return;

  const { locations = [], location_groups = [] } = details;

  if (!locations.length && !location_groups.length && all) {
    return [
      {
        value: "all",
        label: "All Members",
        secondaryLabel: "",
        type: "all",
      },
    ];
  }

  let initialLocationGroups = location_groups?.map(({ id, title, locations }) => ({
    id,
    value: `${title}-${id}`,
    label: title,
    locations,
    secondaryLabel: "Group",
    type: "group",
  }));
  let initialLocations = locations?.map(({ id, name, type }) => ({
    id,
    value: `${name}-${id}`,
    label: name,
    secondaryLabel: type,
    type: "member",
  }));

  return [...initialLocationGroups, ...initialLocations];
};

export const getInitialTagValues = (details) => {
  if (!details) return;

  const { tags = [], tag_groups = [] } = details;

  let initialTagGroups = tag_groups?.map(({ id, title }) => ({
    id,
    value: `${title}-${id}`,
    label: title,
    secondaryLabel: "Group",
    type: "group",
  }));
  let initialTags = tags?.map(({ id, name, type }) => ({
    id,
    value: `${name}-${id}`,
    label: name,
    secondaryLabel: "",
    type: "tag",
  }));

  return [...initialTagGroups, ...initialTags];
};

export const getMemberOptions = (memberGroupList, memberList) => {
  return [
    {
      value: "all",
      label: "All Members",
      secondaryLabel: "",
      type: "all",
    },
    {
      label: "Groups",
      options: memberGroupList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.title}-${id}`,
        label: attributes.title,
        locations: attributes.locations,
        secondaryLabel: "Group",
        type: "group",
      })) || [],
    },
    {
      label: "Members",
      options: memberList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.name}-${id}`,
        label: attributes.name,
        secondaryLabel: attributes.type,
        type: "member",
      })) || [],
    },
  ];
};

export const getContactRoleOptions = (contactRoleList) => {
  return contactRoleList?.data?.map(({ id, attributes }) => ({
    id,
    value: `${attributes.title}-${id}`,
    label: attributes.title,
    secondaryLabel: "",
    type: "contactRole",
  }));
};

export const getInitialContactRoleValues = (details) => {
  if (!details || !details?.contact_role?.id || !details?.contact_role?.title) return;

  const { contact_role: { id, title } } = details;

  let initialContactRole = {
    id,
    value: `${title}-${id}`,
    label: title,
    secondaryLabel: "",
    type: "contactRole",
  };

  return initialContactRole;
};

export const getOnlyMemberAndGroupOptions = (memberGroupList, memberList) => {
  return [
    {
      label: "Groups",
      options: memberGroupList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.title}-${id}`,
        label: attributes.title,
        locations: attributes.locations,
        secondaryLabel: "Group",
        type: "group",
      })),
    },
    {
      label: "Members",
      options: memberList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.name}-${id}`,
        label: attributes.name,
        secondaryLabel: attributes.type,
        type: "member",
      })),
    },
  ];
};

export const getTagOptions = (tagGroupList, tagList) => {
  return [
    {
      label: "Groups",
      options: tagGroupList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.title}-${id}`,
        label: attributes.title,
        secondaryLabel: "",
        type: "group",
      })),
    },
    {
      label: "Tags",
      options: tagList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.name}-${id}`,
        label: attributes.name,
        secondaryLabel: "",
        type: "tag",
      })),
    },
  ];
};

export const getTypeOptions = (types) => {
  return types?.data?.map((type) => ({
    value: type,
    label: type,
  }));
};

export const getOnlyTagGroupOptions = (tagGroupList) => {
  return tagGroupList?.data?.map(({ id, attributes }) => ({
    id,
    value: `${attributes.title}-${id}`,
    label: attributes.title,
    secondaryLabel: "",
    type: "group",
  }));
};

export const getOnlyTagOptions = (tagList) => {
  return tagList?.data?.map(({ id, attributes }) => ({
    id,
    value: `${attributes.name}-${id}`,
    label: attributes.name,
    secondaryLabel: "",
    type: "tag",
  }));
};

export const getOnlyMemberGroupOptions = (memberGroupList) => {
  return memberGroupList?.data?.map(({ id, attributes }) => ({
    id,
    value: `${attributes.title}-${id}`,
    label: attributes.title,
    secondaryLabel: "",
    type: "group",
  }));
};

export const getOnlyMemberGroupOptionsAndAllMembers = (memberGroupList) => {
  return [
    {
      value: "all",
      label: "All Members",
      secondaryLabel: "",
      type: "all",
    },
    {
      label: "Groups",
      options: memberGroupList?.data?.map(({ id, attributes }) => ({
        id,
        value: `${attributes.title}-${id}`,
        label: attributes.title,
        locations: attributes.locations,
        secondaryLabel: "Group",
        type: "group",
      })),
    },
  ];
};

export const getRoleOptions = (roleList) => {
  return [
    {
      value: "0",
      label: "All Roles",
      secondaryLabel: "",
      type: "all",
    },
    {
      label: "Roles",
      options: roleList?.data?.map(({ id, attributes }) => ({
        id,
        value: id,
        label: attributes.name,
        secondaryLabel: attributes.type,
        type: "role",
      })),
    },
  ];
};

export const getOnlyMemberOptions = (memberList) => {
  return memberList?.data?.map(({ id, attributes }) => ({
    id,
    value: `${attributes.name}-${id}`,
    label: attributes.name,
    secondaryLabel: "",
    type: "member",
  }));
};

export const processMemberOptions = (members) =>
  members?.reduce(
    (acum, curr) => {
      switch (curr.type) {
        case "all":
          acum.is_all_locations = true;
          break;
        case "group":
          acum.location_groups.push(curr.id);
          break;
        case "member":
          acum.locations.push(curr.id);
          break;
        default:
          break;
      }
      return acum;
    },
    {
      location_groups: [],
      locations: [],
      is_all_locations: false,
    }
  );

export const processTagOptions = (tags) =>
  tags?.reduce(
    (acum, curr) => {
      switch (curr.type) {
        case "group":
          acum.tag_groups.push(curr.id);
          break;
        case "tag":
          acum.tags.push(curr.id);
          break;
        default:
          break;
      }
      return acum;
    },
    {
      tag_groups: [],
      tags: [],
    }
  );

export const formatGroupLabel = (data) => (
  <div className="form-select-custom-label">
    <span className="select-input-label">{data.label}</span>
    <Badge bg="secondary">{data.options.length}</Badge>
  </div>
);