/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as notificationAction from "../../../redux/actions/notification.action";
import * as contactAction from "../../../redux/actions/contact.action";
import * as memberAction from "../../../redux/actions/member.action";
import * as userAction from "../../../redux/actions/user.action";
import List from '../../list/list';
import SimpleMap from '../../mapoverview/MapOverview';
import { formatDate } from "../../../helpers/date";
import './overview.scss';
import { useTranslation } from "react-i18next";
import OverviewInfo from "../../overviewinfo/OverviewInfo";
import { Alert } from "react-bootstrap";

const memberFields = [
	//list will sort by the first element by default
	"name",
	"type",
	"short_name",
	"dor_code",
	"phone",
	"publishedAt",
];

const userFields = [
  //list will sort by the first element by default
  "full_name",
  "email",
  "publishedAt",
];


const notificationFields = [
	//list will sort by the first element by default
	"type",
	"start_date",
	"end_date",
	"scope",
	"createdAt",
];

const contactFields = [
	//list will sort by the first element by default
	"full_name",
	"department",
	"position",
	"email",
	"phone_number",
	"createdAt",
];

const Overview = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		members,
		notifications,
		contacts,
		memberMetadata,
		notificationMetadata,
		userMetadata,
		contactMetadata,
		memberListLoading,
		notificationListLoading,
		contactListLoading,
		users,
		usersMetadata,
		usersListLoading,
		userListError,
      	memberError,
	} = useSelector((state) => ({
		notifications: state.notification.notificationList,
		contacts: state.contact.contactList,
		memberMetadata: state.member.memberMetadata,
		memberListLoading: state.member.listLoading,
		members: state.member.memberList,
    	users: state.user.userList,
    	usersMetadata: state.user.userMetadata,
    	usersListLoading: state.user.listLoading,
    	userHardDeleted: state.user.userHardDeleted,
      	userListError: state.user.errorUser,
      	memberError: state.member.memberError,
		contactMetadata: state.contact.contactMetadata,
		notificationMetadata: state.notification.notificationMetadata,
		userMetadata: state.user.userMetadata,
		notificationListLoading: state.notification.listLoading,
		contactListLoading: state.contact.listLoading,
	}));

	const initialSearch = {
		filter: "",
		fieldToSort: "id",
		sortDirection: "asc",
		pageSize: 10,
	}

	const getNotificationParams = (paginationAndBasicParams = {}) => {
		const notificationParams = {
			...initialSearch,
			...paginationAndBasicParams,
			fieldTofilter: "type",
			memberFilters: selectedMembers,
			includePublicNotifications: true,
		}
		// If selectedMembers all is selected then query notifications with all_users scope
		if (selectedMembers.all) {
			notificationParams.filter = "all_users";
			notificationParams.typeOfFilter = "$eq";
			notificationParams.fieldTofilter = "user_scope";
		}
		return notificationParams;
	};

	const getUsersParams = (paginationAndBasicParams = {}) => {
		return {
			...initialSearch,
			...paginationAndBasicParams,
			backOfficeUser: false,
			includeLocationName: true,
			fieldTofilter: "full_name",
			member: !selectedMembers?.all && selectedMembers.members.length > 0 ?
				selectedMembers.members[0] : undefined,
		}
	};

	const getContactParams = (paginationAndBasicParams = {}) => {
		const contactsParams = {};
		return {
			...contactsParams,
			...initialSearch,
			includeContacsForAllMembers: true,
			...paginationAndBasicParams,
			fieldTofilter: "full_name",
			memberFilters: selectedMembers,
		}
	};


	const getMemberParams = (paginationAndBasicParams = {}) => {
		return {
			...initialSearch,
			...paginationAndBasicParams,
			fieldTofilter: "name",
			memberFilters: selectedMembers,
		}
	};

	const [selectedMembers, setSelectedMembers] = useState({
		all: true,
		members: [],
		groups: [],
	});

	  const [userDisabledSuccessMessage, setUserDisabledSuccessMessage] = useState('');
	  const [notificationDisabledSuccessMessage, setNotificationDisabledSuccessMessage] = useState('');
	  const [contactDeletedSuccessMessage, setContactDeletedSuccessMessage] = useState('');
	  const [memberDeletedSuccessMessage, setMemberDeletedSuccessMessage] = useState('');

	useEffect(() => {
		memberAction.fetchAll(dispatch, { ...initialSearch, fieldTofilter: 'name' });
		userAction.fetchAllPortalUsers(dispatch, { ...initialSearch, fieldTofilter: 'full_name', backOfficeUser: false, });
	}, []);

	// on start clear errors
	useEffect(() => {
		userAction.clearErrors(dispatch);
	}, [dispatch]);

	return (
		<div className='overview'>
			<SimpleMap
				getNotificationsParams={getNotificationParams}
				getMemberParams={getMemberParams}
				getUserParams={getUsersParams}
				getContactParams={getContactParams}
				selectedMembers={selectedMembers}
				setSelectedMembers={setSelectedMembers} />
			<OverviewInfo memberMetadata={memberMetadata} notificationMetadata={notificationMetadata} userMetadata={userMetadata} />
			<div>

				<List
					includeSortByBlocked={true}
					iconOfDeleteAction={"block"}
					title="Web Portal Users"
					redirect={
						`/web-portal-users/member/${selectedMembers.all ? undefined : selectedMembers.members[0] }/form`
					}
					// In this list, the redirect attribute is only used to create a new user
					// The redirectAttribute is used to redirect on user edit requests
					// Because if ALL members are selected then multiple users from different members
					// Will be in the same overview list
					canCreateNew={!selectedMembers.all}
					columnNames={["FULL NAME", "DISABLED", "EMAIL", "ROLE", "Member"]}
					entities={users?.data?.map(
						({
							id,
							attributes: { 
								full_name,
								blockedOnKeycloak,
								email,
								portal_user_role,
								kcUserId,
								deleted,
								usersLocationName,
								usersLocationId,
							 },
						}) => ({
							id,
							columns: [
								{ primary: full_name },
								{ primary: blockedOnKeycloak ? "Yes" : "No" },
								{ primary: email },
								{ primary: portal_user_role?.data?.attributes?.name },
								{ primary: usersLocationName },
							],
							name: full_name,
							redirectAttribute:`/web-portal-users/member/${usersLocationId}/form`,
							kcUserId,
							deleted,
						})
					)}
					fieldsToSort={userFields}
					fetchAction={(params) =>
						 userAction.fetchAllPortalUsers(dispatch, getUsersParams(params) )
					}
					isOverview={true}
					metadata={usersMetadata}
					showFilterTab={false}
					showUnDeleteOption={true}
					warningOnDelete={' The user will no longer be able to access the portal.'}
					customListError={userListError}
					tooltipTextDelete={"Disable User"}
					tooltipTextUnDelete={"Enable User"}
					successAlertMessage={userDisabledSuccessMessage}
					showAlert={!!userDisabledSuccessMessage}
					unDeleteAction={(id, params, kcUserId) => {
						userAction.clearErrors(dispatch);
						setUserDisabledSuccessMessage('');
						userAction.unblockUser({
							dispatch,
							strapiId: id,
							params: getUsersParams(params),
							setSuccessMessage: setUserDisabledSuccessMessage,
							keycloakId: kcUserId,
							redirectToList: true
						})
					}
					}
					deleteAction={(id, params) =>
					{
						setUserDisabledSuccessMessage('');
						userAction.clearErrors(dispatch);
						userAction.blockPortalUser({
							dispatch,
							id,
							params: getUsersParams(params),
							setSuccessMessage: setUserDisabledSuccessMessage
						})
					}
					}
					loading={usersListLoading}
				/>
				<List
					redirect='/members/form'
					title={t('overview.members')}
					tooltipTextDelete="Delete Member"
					isOverview={true}
					showFilterTab={false}
					columnNames={["NAME", "TYPE", "SHORT NAME", "DOR CODE", "ZIP CODE", "PHONE", "DATE"]}
					showAlert={!!memberDeletedSuccessMessage}
					successAlertMessage={memberDeletedSuccessMessage}
					customListError={memberError}
					entities={members?.data?.map(
						({
							id,
							attributes: {
								name,
								type,
								short_name,
								dor_code,
								zip_code,
								phone,
								publishedAt,
							},
						}) => ({
							id,
							columns: [
								{ primary: name },
								{ primary: type },
								{ primary: short_name },
								{ primary: dor_code },
								{ primary: zip_code },
								{ primary: phone },
								{ primary: formatDate(publishedAt) },
							],
							name,
						})
					)}

					fieldsToSort={memberFields}
					fetchAction={(params) => memberAction.fetchAll(dispatch, getMemberParams(params))}
					metadata={memberMetadata}
					deleteAction={(id, params) => memberAction.remove(dispatch, id, getMemberParams(params), setMemberDeletedSuccessMessage)}
					loading={memberListLoading}
					tab="general"
				/>
				<List
					redirect='/notifications/form'
					title={t('overview.notifications')}
					isOverview={true}
					showFilterTab={false}
					tooltipTextDelete="Delete Notification"
					showAlert={!!notificationDisabledSuccessMessage}
					successAlertMessage={notificationDisabledSuccessMessage}
					columnNames={[
						"TYPE",
						"DATE SENT",
						"SCOPE",
						"START DATE",
						"END DATE",
					]}
					entities={notifications?.data?.map(
						({ id, attributes: { type, createdAt, start_date, end_date, user_scope } }) => ({
							id,
							columns: [
								{ primary: type },
								{ primary: formatDate(createdAt) },
								{ primary: user_scope },
								{ primary: start_date },
								{ primary: end_date },
							],
							name: "this notification",
						})
					)}
					isEditable={false}
					searchIsEnabled={false}
					fieldsToSort={notificationFields}
					metadata={notificationMetadata}
      				fetchAction={(params) => notificationAction.fetchAll(dispatch, getNotificationParams(params))}
					deleteAction={(id, params) => notificationAction.remove( dispatch, id, getNotificationParams(params), setNotificationDisabledSuccessMessage)
					}

					loading={notificationListLoading}
				/>
				<List
					redirect='/contacts/form'
					title={t('overview.miiacontact')}
					isOverview={true}
					showFilterTab={false}
					showAlert={!!contactDeletedSuccessMessage}
					successAlertMessage={contactDeletedSuccessMessage}
					tooltipTextDelete="Delete Contact"
					columnNames={[
						"FULL NAME",
						"DATE",
						"DEPARTMENT",
						"POSITION",
						"EMAIL",
						"PHONE NUMBER",
					]}
					entities={contacts?.data?.map(
						({
							id,
							attributes: {
								full_name,
								createdAt,
								department,
								position,
								email,
								phone_number,
							},
						}) => ({
							id,
							columns: [
								{ primary: full_name },
								{ primary: formatDate(createdAt) },
								{ primary: department },
								{ primary: position },
								{ primary: email },
								{ primary: phone_number },
							],
							name: full_name,
						})
					)}
					fieldsToSort={contactFields}
					fetchAction={(params) => contactAction.fetchAll(dispatch, getContactParams(params))}
					metadata={contactMetadata}
					deleteAction={(id, params) => contactAction.remove(dispatch, id, getContactParams(params), setContactDeletedSuccessMessage)}
					loading={contactListLoading}
				/>
			</div>
		</div>
	);
};

export default Overview;