import Keycloak from "keycloak-js";
import { setInfoUser, setLoginStatus } from "../redux/reducers/userReducer";

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback, store) => {
  _kc
    .init({
      onLoad: "login-required",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      if (authenticated) {
        store.dispatch(setLoginStatus({ loginStatus: isLoggedIn() }));
        store.dispatch(setInfoUser({ infoUser: getUsername() }));
        onAuthenticatedCallback();
      } else {
        store.dispatch(setLoginStatus({ loginStatus: isLoggedIn() }));
      }
    })
    .catch(console.error);
};

const doLogin = _kc.login;
const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc
    .updateToken(60 * 10)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => {
  return _kc.tokenParsed;
};

const hasRole = (role) => _kc.hasRealmRole(role);

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;
