import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const Contact = ({ tab, setTab, childToParent }) => {
  const navigate = useNavigate();

  const initialValues = {
    phone_extension: "",
    phone: "",
    email: "",
    mailing_address_1: "",
    mailing_address_2: "",
    mailing_city: "",
    mailing_state: "",
    mailing_county: "",
  };

  const formSchema = Yup.object()
    .shape({
      phone_extension: Yup.string(),
      phone: Yup.string()
        .matches(/^[0-9\-]+$/, "Only numbers and dashes are allowed")
        .required("Required field"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Required field"),
      mailing_address_1: Yup.string().required("Required field"),
      mailing_address_2: Yup.string(),
      mailing_city: Yup.string().required("Required field"),
      mailing_state: Yup.string().required("Required field"),
      mailing_county: Yup.string().required("Required field"),
    })
    .required("Required field");

  const onSubmitForm = (values) => {
    const processedPhone = values.phone.trim().replace(/\s+/g, " "); //remove extra spaces
    childToParent("contact", { ...values, phone: processedPhone });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, actions) => {
        onSubmitForm(values);
        actions.setSubmitting(false);
        setTab("product");
      }}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
        } = formik;

        return (
          <div className="member create-member contact">
            <div className="title-members-create contact-title">
              <h3>Contact Information</h3>
              <label>
                Enter some general information about the new member:
              </label>
            </div>
            <Form
              onSubmit={handleSubmit}
              className="member-form contact-form form-create"
            >
              <div className="container-inputs">
                <div className="box-1-create-member">
                  <div className="form-input r1c1">
                    <Form.Label>Phone Extension</Form.Label>
                    <Form.Control
                      name="phone_extension"
                      value={values.phone_extension}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.phone_extension && touched.phone_extension}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone_extension}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input r1c1">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.phone && touched.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input r1c1">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.email && touched.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input r1c2">
                    <Form.Label>Mailing City</Form.Label>
                    <Form.Control
                      name="mailing_city"
                      value={values.mailing_city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.mailing_city && touched.mailing_city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mailing_city}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input r1c1">
                    <Form.Label>Mailing State</Form.Label>
                    <Form.Control
                      name="mailing_state"
                      value={values.mailing_state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.mailing_state && touched.mailing_state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mailing_state}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="box-2-create-member">
                  <div className="form-input r1c3">
                    <Form.Label>Mailing Address 1</Form.Label>
                    <Form.Control
                      name="mailing_address_1"
                      value={values.mailing_address_1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        errors.mailing_address_1 && touched.mailing_address_1
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mailing_address_1}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input r2c3">
                    <Form.Label>Mailing Address 2</Form.Label>
                    <Form.Control
                      name="mailing_address_2"
                      value={values.mailing_address_2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        errors.mailing_address_2 && touched.mailing_address_2
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mailing_address_2}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input r2c1">
                    <Form.Label>Mailing County</Form.Label>
                    <Form.Control
                      name="mailing_county"
                      value={values.mailing_county}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        errors.mailing_county && touched.mailing_county
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mailing_county}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
              <div className="member-form-action-group">
                <Button
                  variant="primary"
                  onClick={() => navigate("/members")}
                  className="form-action-cancel"
                >
                  Cancel
                </Button>
                <div className="form-subgroup">
                  <Button
                    onClick={() => {
                      setTab("type");
                    }}
                    className="form-action"
                    variant="primary"
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => {
                      errors === {} ? setTab("product") : setTab("contact");
                    }}
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Contact;
