import axios, { authHeader } from "../../services/HTTPService";
import * as action from "../reducers/programReducer";
const API_URL = process.env.REACT_APP_API_URL;

export const reOrder = async ({ dispatch, body, filterParams, type }) => {
  return axios
    .put(API_URL + `/api/programs/reorder`, body, { headers: authHeader() })
    .then(({ data }) => {
      fetchAll(dispatch,type, filterParams);
    })
    .catch((error) => {
      dispatch(
        action.setProgramError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchAll = async (
  dispatch,
  type,
  { fieldTofilter, filter, fieldToSort, sortDirection, page, pageSize } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "order",
    sortDirection: "desc",
    page: 1,
    pageSize: 10,
  }
) => {
  dispatch(action.setListLoading(true));
  dispatch(action.setProgramList({ data: []}));

  return axios
    .get(API_URL + `/api/programs/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        type
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setProgramList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setProgramError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/programs/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setProgramDetail({ data: data?.data?.attributes }));
    })
    .catch((error) => {
      dispatch(
        action.setProgramError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/programs", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setProgram({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProgramError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/programs/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setProgram({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProgramError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const remove = async (dispatch, id, params, type) => {
  return axios
    .delete(API_URL + `/api/programs/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, type, params);
    })
    .catch((error) => {
      dispatch(
        action.setProgramError({ error: error?.response?.data?.error?.message })
      );
    });
};
