/* eslint-disable react-hooks/exhaustive-deps */
import "./memberForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import * as memberAction from "../../../../redux/actions/member.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import {
  getOnlyMemberGroupOptions,
  processMemberOptions,
  getInitialMemberValues,
  getTypeOptions,
} from "../../../../helpers/select";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { customStyles } from '../../../../helpers/select.custom';

const MemberForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [showExitModal, setShowExitModal] = useState(false);
  const { t } = useTranslation();

  const { memberDetails, memberGroupList, member, error, types } = useSelector(
    (state) => ({
      memberDetails: state.member.memberDetails,
      memberGroupList: state.memberGroup.memberGroupSelectList,
      member: state.member.member,
      error: state.member.memberError,
      types: state.member.memberTypes,
    })
  );

  const initialValues = {
    short_name: entityId ? memberDetails?.short_name || "" : "",
    name: entityId ? memberDetails?.name || "" : "",
    type: entityId
      ? { value: memberDetails?.type, label: memberDetails?.type }
      : "",
    dor_code: entityId ? memberDetails?.dor_code || "" : "",
    zip_code: entityId ? memberDetails?.zip_code || "" : "",
    phone_extension: entityId ? memberDetails?.phone_extension || "" : "",
    phone: entityId ? memberDetails?.phone || "" : "",
    groups: entityId ? getInitialMemberValues(memberDetails, false) : [],
    mailing_address_1: entityId ? memberDetails?.mailing_address_1 || "" : "",
    mailing_address_2: entityId ? memberDetails?.mailing_address_2 || "" : "",
    mailing_city: entityId ? memberDetails?.mailing_city || "" : "",
    mailing_county: entityId ? memberDetails?.mailing_county || "" : "",
    mailing_state: entityId ? memberDetails?.mailing_state || "" : "",
    nickname: entityId ? memberDetails?.title || "" : "",
  };

  const formSchema = Yup.object().shape({
    short_name: Yup.string().required("Required field"),
    name: Yup.string().required("Required field"),
    type: Yup.mixed().required("Required field"),
    dor_code: Yup.number()
      .typeError("DOR Code must be of type 'Number'")
      .required("Required field"),
    zip_code: Yup.string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid ZIP Code")
      .required("Required field"),
    phone_extension: Yup.string(),
    phone: Yup.string()
      .matches(/^[0-9\-]+$/, "Only numbers and dashes are allowed")
      .required("Required field"),
    mailing_address_1: Yup.string().required("Required field"),
    mailing_address_2: Yup.string(),
    mailing_city: Yup.string().required("Required field"),
    mailing_county: Yup.string().required("Required field"),
    mailing_state: Yup.string().required("Required field"),
    groups: Yup.mixed(),
    nickname: Yup.string().required("Required field"),
  });

  const onSubmitForm = (values) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      groups = [],
      name,
      short_name,
      type,
      dor_code,
      zip_code,
      phone_extension,
      phone,
      mailing_address_1,
      mailing_address_2,
      mailing_city,
      mailing_county,
      mailing_state,
      nickname,
    } = values;

    const { location_groups } = processMemberOptions(groups);

    const formBody = {
      location_groups,
      name,
      short_name,
      type: type.value,
      dor_code,
      zip_code,
      phone_extension,
      phone,
      mailing_address_1,
      mailing_address_2,
      mailing_city,
      mailing_county,
      mailing_state,
      title: nickname,
    };

    formData.append("location", JSON.stringify(formBody));

    memberAction.update(dispatch, entityId, formData);

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
    memberAction.fetchTypes(dispatch);

    if (entityId) {
      //If we have an id then load data
      memberAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setMemberOptions(getOnlyMemberGroupOptions(memberGroupList));
  }, [JSON.stringify(memberGroupList)]);

  useEffect(() => {
    setTypeOptions(getTypeOptions(types));
  }, [types]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && member) {
      setRenderSuccessAlert(true);
    }
  }, [entityId, member, submittedForm, navigate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          handleBlur,
        } = formik;

        return (
          <div className="member">
            <Form className="member-form">
              {error && <Alert variant="danger">{error?.error || error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  Member edited successfully
                </Alert>
              )}
              <div className="member-grid">
                <div className="form-input r1c1">
                  <Form.Label>Short Name</Form.Label>
                  <Form.Control
                    name="short_name"
                    value={values.short_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.short_name && touched.short_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.short_name}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r1c3">
                  <Form.Label>Member Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r1c4">
                  <Form.Label>Member Type</Form.Label>
                  <Select
                    theme={(theme) => getTheme(theme)}
                    className="form-select-custom"
                    value={values.type}
                    options={typeOptions}
                    onChange={(option) => setFieldValue("type", option)}
                    closeMenuOnSelect={true}
                    onBlur={handleBlur}
                    isInvalid={errors.type && touched.type}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.type}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r2c1">
                  <Form.Label>DOR Number (Alpha)</Form.Label>
                  <Form.Control
                    name="dor_code"
                    value={values.dor_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.dor_code && touched.dor_code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dor_code}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r2c3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.phone && touched.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r2c3">
                  <Form.Label>Phone Extension</Form.Label>
                  <Form.Control
                    name="phone_extension"
                    value={values.phone_extension}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.phone_extension && touched.phone_extension}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone_extension}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r2c2">
                  <Form.Label>ZIP Code</Form.Label>
                  <Form.Control
                    name="zip_code"
                    value={values.zip_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.zip_code && touched.zip_code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zip_code}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r2c4">
                  <Form.Label>Member Groups</Form.Label>
                  <Select
                    theme={(theme) => getTheme(theme)}
                    className="form-select-custom"
                    isMulti
                    styles={customStyles}
                    value={values.groups}
                    onChange={(option) => setFieldValue("groups", option)}
                    closeMenuOnSelect={false}
                    onBlur={handleBlur}
                    options={memberOptions}
                    isInvalid={errors.groups && touched.groups}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.groups}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r4c1">
                  <Form.Label>Mailing Address 1</Form.Label>
                  <Form.Control
                    name="mailing_address_1"
                    value={values.mailing_address_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors.mailing_address_1 && touched.mailing_address_1
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mailing_address_1}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r4c2">
                  <Form.Label>Mailing Address 2</Form.Label>
                  <Form.Control
                    name="mailing_address_2"
                    value={values.mailing_address_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors.mailing_address_2 && touched.mailing_address_2
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mailing_address_2}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r4c3">
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    name="nickname"
                    value={values.nickname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors.nickname && touched.nickname
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nickname}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r5c1">
                  <Form.Label>Mailing City</Form.Label>
                  <Form.Control
                    name="mailing_city"
                    value={values.mailing_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.mailing_city && touched.mailing_city}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mailing_city}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r5c2">
                  <Form.Label>Mailing Country</Form.Label>
                  <Form.Control
                    name="mailing_county"
                    value={values.mailing_county}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.mailing_county && touched.mailing_county}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mailing_county}
                  </Form.Control.Feedback>
                </div>
                <div className="form-input r5c3">
                  <Form.Label>Mailing State</Form.Label>
                  <Form.Control
                    name="mailing_state"
                    value={values.mailing_state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.mailing_state && touched.mailing_state}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mailing_state}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="form-action-group">
                <Button
                  onClick={() => {
                    if (formik.dirty && !renderSuccessAlert) {
                      setShowExitModal(true);
                    } else {
                      navigate("/members");
                    }
                  }}
                  className="form-action"
                  variant="outline-primary"
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  className="form-action form-action-primary"
                  variant="outline-primary"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Save
                </Button>
              </div>
            </Form>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default MemberForm;
