// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipe {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
}
.recipe-header {
  display: flex;
  gap: 1em;
  align-items: center;
}
.recipe-title {
  font-size: 1.5em;
  font-weight: bold;
}
.recipe-container {
  flex: 1 1;
  margin-top: 2em;
  margin-inline: 2em;
  padding: 2em;
  border-radius: 1em;
  background-color: #fff;
}
.recipe-form {
  display: flex;
  flex-direction: column;
}
.recipe-form-layout {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2em;
}`, "",{"version":3,"sources":["webpack://./src/components/views/recipe/recipeForm/recipeForm.scss","webpack://./src/sass/globals/mixins.scss","webpack://./src/sass/globals/globals.scss"],"names":[],"mappings":"AAGA;ECFC,aAAA;EACA,uBDEe;ECDf,uBDCuB;EACtB,SAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;AAAF;AAEC;EACC,aAAA;EACA,QAAA;EACA,mBAAA;AAAF;AAGE;EACE,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,SAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBEvBI;AFqBR;AAKE;EACE,aAAA;EACA,sBAAA;AAHJ;AAKI;ECjCH,aAAA;EACA,6BDiCmB;EChCnB,uBDgCiC;EAC5B,eAAA;EACA,QAAA;AADN","sourcesContent":["@import \"../../../../sass/globals/globals.scss\";\n@import \"../../../../sass/globals/mixins.scss\";\n\n.recipe {\n  @include flex(center, flex-start);\n  flex: 1;\n  height: 100%;\n  box-sizing: border-box;\n  overflow: scroll;\n\n\t&-header{\n\t\tdisplay: flex;\n\t\tgap: 1em;\n\t\talign-items: center;\n\t}\n\n  &-title {\n    font-size: 1.5em;\n    font-weight: bold;\n  }\n\n  &-container {\n    flex: 1;\n    margin-top: 2em;\n    margin-inline: 2em;\n    padding: 2em;\n    border-radius: 1em;\n    background-color: $white;\n  }\n\n  &-form {\n    display: flex;\n    flex-direction: column;\n\n    &-layout {\n      @include flex(space-evenly, flex-start);\n      flex-wrap: wrap;\n      gap: 2em;\n    }\n  }\n}\n","@mixin flex($justify-content, $align-items) {\n\tdisplay: flex;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n\n@mixin flexColumn($justify-content, $align-items) {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n","$dark-red: #8f003c;\n$sidebar-selected-color: #c4145d;\n$sidebar-logo-color: #a53363;\n$logo-color: #920942;\n$white: #fff;\n$grey: #f3f6f9;\n$grey2: #e2e5e7;\n$dark-grey: #777776;\n$font-color: #fff;\n$shadow-color: #4e7da2;\n$font: 'Rubik';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
