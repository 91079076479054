import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
import "./MapOverview.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as memberAction from "../../redux/actions/member.action";
import * as notificationAction from "../../redux/actions/notification.action";
import * as userAction from "../../redux/actions/user.action";
import * as contactAction from "../../redux/actions/contact.action";
import { getMemberOptions } from "../../helpers/select";
import { customStylesForOverview } from "../../helpers/select.custom";
import { getTheme } from "../../helpers/select.theme";
import Select from "react-select";

const DEFAULT = { lat: 42.407211, long: -71.382439, zoom: 8 };

export default function SimpleMap(
  {
    getUserParams,
    getNotificationsParams,
    getMemberParams,
    selectedMembers,
    setSelectedMembers,
    getContactParams
  }) {
  const { t } = useTranslation();
  const [townSelected, setTownSelected] = useState({
    name: "All Members",
    lat: DEFAULT.lat,
    long: DEFAULT.long,
    zoom: DEFAULT.zoom,
  });
  const { memberList } = useSelector((state) => ({
    memberList: state.member.mapMemberList,
  }));
  const dispatch = useDispatch();
  const [memberOptions, setMemberOptions] = useState([]);

  useEffect(() => {
    memberAction.fetchSelectOptions(dispatch, { isOverview: true });
  }, []);

  useEffect(() => {
    setMemberOptions(getMemberOptions([], memberList));
  }, [JSON.stringify(memberList)]);

  const handleSelectMember = (type, memberId) => {
    if (type === "all") {
      setSelectedMembers((prev) => ({
        ...prev,
        all: true,
        members: [],
        groups: [],
      }));
    } else if (type === "member") {
      if (selectedMembers.members.includes(memberId)) {
        setSelectedMembers((prev) => ({
          ...prev,
          all: false,
          members: prev.members.filter((m) => m !== memberId),
        }));

        if (
          selectedMembers.members.length === 1 &&
          selectedMembers.groups.length === 0
        ) {
          setSelectedMembers((prev) => ({
            ...prev,
            all: true,
            members: [],
          }));
        }
      } else {
        setSelectedMembers((prev) => ({
          ...prev,
          all: false,
          members: [memberId],
        }));
      }
    }
  };

  const handleMemberFilter = () => {
    memberAction.fetchAll(dispatch, getMemberParams() );
    notificationAction.fetchAll(dispatch,  getNotificationsParams() );
    userAction.fetchAllPortalUsers(dispatch, getUserParams() );
    contactAction.fetchAll(dispatch, getContactParams() );
  };

  useEffect(() => {
    handleMemberFilter();
  }, [selectedMembers]);

  return (
    <div className="overview-container">
      <div className="overview-map-title">
        <h5 className="title-box">{`Overview for ${townSelected?.name}`}</h5>
        <div className="overview-member-select"> 
          <Select
            theme={(theme) => getTheme(theme)}
            className="form-select-custom dropdowns-towns"
            styles={customStylesForOverview}
            options={memberOptions}
            onChange={(option) => {
              const type = option?.value === "all" ? "all" : "member";
              const member = option?.value === "all" ? undefined : option?.id;
              handleSelectMember(type, member);
            }}
            placeholder="All Members"
          />
        </div>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB5BtHgKpaZUNxHp5z40OVF1Ccr52NgFd0" }}
        center={{
          lat: townSelected?.lat || DEFAULT.lat,
          lng: townSelected?.long || DEFAULT.long,
        }}
        zoom={townSelected?.zoom || DEFAULT.zoom}
        key={townSelected?.name}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}
      />
    </div>
  );
}
