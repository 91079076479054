import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as notificationAction from "../../../../redux/actions/notification.action";
import { formatDate } from "../../../../helpers/date";
import List from '../../../list/list';
import { useTranslation } from "react-i18next";

const notificationFields = [
  //list will sort by the first element by default
  "createdAt",
  "type",
  "start_date",
  "end_date",
  "scope",
];

const NotificationList = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation()

  const { notifications, meta, listLoading } = useSelector((state) => ({
    notifications: state.notification.notificationList,
    meta: state.notification.notificationMetadata,
    listLoading: state.notification.listLoading,
  }));

  useEffect(() => {
    notificationAction.fetchAll(dispatch);
  }, [dispatch]);

	return (
		<List
			redirect='/notifications/form'
			title={t('notifications.notifications')}
			isOverview={false}
      columnNames={[
        "TYPE",
        "DATE SENT",
        "SCOPE",
        "START DATE",
        "END DATE",
      ]}
			entities={notifications?.data?.map(
        ({ id, attributes: { type, createdAt, start_date, end_date, user_scope} }) => ({
          id,
          columns: [
						{ primary: type },
            { primary: formatDate(createdAt) },
            { primary: user_scope},
						{ primary: start_date },
						{ primary: end_date },
					],
          name: "this notification",
        })
      )}
      isEditable={true}
      tooltipTextDelete="Delete Notification"
      fieldsToSort={notificationFields}
      fetchAction={(params) => notificationAction.fetchAll(dispatch, params)}
      metadata={meta}
      deleteAction={(id, params) => notificationAction.remove(dispatch, id, params)}
      loading={listLoading}
		/>
	);
};

export default NotificationList;
