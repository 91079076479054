const constants = {
	section: {
		ARTICLE: "article",
		RECIPE: "recipe",
		PROGRAM: "program",
		PRODUCT: "product",
		MEMBER: "location",
		MEMBER_GROUP: "location-group",
		ADMIN_PORTAL_USER: "miia-user-bo",
		WEB_PORTAL_USER: "miia-user-portal",
		ADMIN_PORTAL_ROLE: "admin-portal-role",
		WEB_PORTAL_ROLE: "portal-user-role",
		BUTTON_IN_HOME: "button-in-home",
		CONTACT: "contact",
		DROPDOWN: "dropdown",
		DROPDOWN_OPTION: "dropdown-option",
		DROPDOWN_OPTION_VALUE: "dropdown-option-value",
		TAG: "tag",
		TAG_GROUP: "tag-group",
		PAGE: "page",
		NOTIFICATION: "notification",
		QUICK_ACCESS_LINK: "quick-access-link",
		PURCHASED_PRODUCT: "purchased-product",
	},
}

export {
	constants,
}