import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./modalVisibility.scss";
import { getTheme } from "../../helpers/select.theme";
import Select from "react-select";
import { customStyles } from "../../helpers/select.custom";

const ModalVisibility = ({
  visibilityModalContext,
  setFieldValue,
  productList,
  handleShow,
  show,
  values,
}) => {
  const isSecondlevel = visibilityModalContext.secondLevelIndex !== null;
  
  const firstLevelValue = values?.options?.at(visibilityModalContext?.index);
  const secondLevelValue = firstLevelValue?.second_level_options?.at(visibilityModalContext?.secondLevelIndex);
  
  const option = !isSecondlevel ? firstLevelValue : secondLevelValue;

  const levelName = visibilityModalContext.levelName;

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => handleShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Visibility Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-visibility" style={{padding: "1em"}}>
          <div style={{ display: "flex", paddingInline: "0.5em" }}>
            <div
              onClick={() => {
                setFieldValue(
                  `${levelName}.is_visible`,
                  true
                );
              }}
              className={`sort-button ${
                option?.is_visible ? "sort-button-selected" : ""
              }`}
            >
              <span>SHOW</span>
            </div>
            <div
              onClick={() => {
                setFieldValue(
                  `${levelName}.is_visible`,
                  false
                );
              }}
              className={`sort-button ${
                !option?.is_visible ? "sort-button-selected" : ""
              }`}
            >
              <span>HIDE</span>
            </div>
          </div>
          <div className="form-input">
            <Select
              theme={(theme) => getTheme(theme)}
              className="form-select-custom dropdown-select"
              value={option?.products}
              isMulti
              styles={customStyles}
              onChange={(option) =>
                setFieldValue(
                  `${levelName}.products`,
                  option
                )
              }
              closeMenuOnSelect={true}
              options={productList?.data?.map(
                ({ id, attributes: { name } }) => ({
                  value: id,
                  label: name,
                })
              )}
            />
          </div>
          <div className="dropdown-operator-container">
            <div className="dropdown-operator">
              <span
                className={
                  option?.product_operator === "or"
                    ? "dropdown-operator-selected"
                    : undefined
                }
              >
                OR
              </span>
              <Form.Check
                bsPrefix="form-custom-switch"
                type="switch"
                value={option?.product_operator}
                onChange={() => {
                  setFieldValue(
                    `${levelName}.product_operator`,
                    option?.product_operator === "or" ? "and" : "or"
                  );
                }}
                id="product_operator"
                name="product_operator"
                checked={option?.product_operator !== "or"}
              ></Form.Check>
              <span
                className={
                  option?.product_operator !== "or"
                    ? "dropdown-operator-selected"
                    : undefined
                }
              >
                AND
              </span>
              <span className="dropdown-operator-feedback">
                Dropdown Option will be{" "}
                <strong>
                  {option?.is_visible === true ? "DISPLAYED" : "HIDDEN"}
                </strong>{" "}
                if the member purchased{" "}
                <strong>
                  {option?.product_operator === "or" ? "ANY" : "ALL"}
                </strong>{" "}
                of the selected products
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleShow(false)}
          className="form-action form-action-primary btn btn-outline-primary"
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalVisibility;
