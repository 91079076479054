import "./contactForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import * as contactAction from "../../../../redux/actions/contact.action";
import * as memberAction from "../../../../redux/actions/member.action";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import {
  getInitialMemberValues,
  getMemberOptions,
  getContactRoleOptions,
  processMemberOptions,
  formatGroupLabel,
  getInitialContactRoleValues,
} from "../../../../helpers/select";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from "../../../../helpers/select.custom";

const ContactForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [contactRoleOptions, setContactRoleOptions] = useState([]);
  const [showExitModal, setShowExitModal] = useState(false);

  const { contactDetails, contact, error, memberList, memberGroupList, contactRoleList } =
    useSelector((state) => ({
      contactDetails: state.contact.contactDetails,
      contact: state.contact.contact,
      error: state.contact.contactError,
      memberList: state.member.memberSelectList,
      memberGroupList: state.memberGroup.memberGroupSelectList,
      contactRoleList: state.contact.contactRoleSelectList,
    }));

  const initialValues = {
    member: entityId
      ? getInitialMemberValues(contactDetails, true)
      : [
          {
            value: "all",
            label: "All Members",
            secondaryLabel: "",
            type: "all",
          },
        ],
    full_name: entityId ? contactDetails?.full_name || "" : "",
    department: entityId ? contactDetails?.department || "" : "",
    phone_number: entityId ? contactDetails?.phone_number || "" : "",
    contact_role: entityId ? getInitialContactRoleValues(contactDetails) || "" : "",
    email: entityId ? contactDetails?.email || "" : "",
  };

  const formSchema = Yup.object().shape({
    member: Yup.mixed(),
    full_name: Yup.string().required("Required field"),
    department: Yup.string(),
    contact_role: Yup.mixed(),
    phone_number: Yup.string().matches(
      /^[0-9 ]+$/,
      "Only numbers and spaces are allowed"
    ),
    email: Yup.string().email("Invalid email format"),
  });

  const onSubmitForm = async (values, { resetForm }) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      member = [],
      full_name,
      department,
      position,
      phone_number,
      contact_role,
      email,
    } = values;

    const locationOptions = processMemberOptions(member);

    const formBody = {
      locations: locationOptions.locations,
      location_groups: locationOptions.location_groups,
      is_all_locations: locationOptions.is_all_locations,
      full_name,
      department,
      position,
      phone_number,
      email,
      contact_role,
    };

    formData.append("contact", JSON.stringify(formBody));

    if (entityId) {
      await contactAction.update(dispatch, entityId, formData);
    } else {
      await contactAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    memberAction.fetchSelectOptions(dispatch, { isDropdown: true });
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
    contactAction.fetchContactRoles(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      contactAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setMemberOptions(getMemberOptions(memberGroupList, memberList));
  }, [JSON.stringify(memberGroupList), JSON.stringify(memberList)]);
  

  useEffect(() => {
    setContactRoleOptions(getContactRoleOptions(contactRoleList));
  }, [JSON.stringify(contactRoleList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && contact) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, contact, submittedForm, navigate]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
          resetForm,
        } = formik;

        return (
          <div className="contact">
            <div className="contact-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {entityId
                    ? t("contacts.editContactSuccess")
                    : t("contacts.createContactSuccess")}
                </Alert>
              )}
              <div className="contact-header">
                <h1 className="contact-title">
                  {entityId
                    ? t("contacts.editcontact")
                    : t("contacts.newcontact")}
                </h1>
              </div>
              <Form className="contact-form">
                <div className="contact-form-layout">
                  <div className="form-input">
                    <Form.Label>{t("contacts.published")}</Form.Label>
                    <Select
                      theme={(theme) => getTheme(theme)}
                      styles={customStyles}
                      className="form-select-custom"
                      isMulti
                      value={values.member}
                      onChange={(option) => setFieldValue("member", option)}
                      closeMenuOnSelect={false}
                      onBlur={handleBlur}
                      options={memberOptions}
                      isInvalid={errors.member && touched.member}
                      formatGroupLabel={formatGroupLabel}
                      getOptionLabel={(option) => (
                        <div className="form-select-custom-label">
                          <span className="form-select-custom-label-primary">
                            {option.label}
                          </span>
                          <span className="form-select-custom-label-secondary">
                            {option.secondaryLabel}
                          </span>
                        </div>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.member}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label>{t("contacts.fullname")}</Form.Label>
                    <Form.Control
                      name="full_name"
                      value={values.full_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.full_name && touched.full_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.full_name}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("contacts.department")}</Form.Label>
                      <Form.Control
                        name="department"
                        value={values.department}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.department && touched.department}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.department}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                    <Form.Label>{"Position"}</Form.Label>
                    <Select
                      theme={(theme) => getTheme(theme)}
                      styles={customStyles}
                      className="form-select-custom"
                      value={values.contact_role}
                      onChange={(option) => setFieldValue("contact_role", option)}
                      closeMenuOnSelect={false}
                      onBlur={handleBlur}
                      options={contactRoleOptions}
                      isInvalid={errors.contact_role && touched.contact_role}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contact_role}
                    </Form.Control.Feedback>
                  </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("contacts.phone")}</Form.Label>
                      <Form.Control
                        name="phone_number"
                        value={values.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.phone_number && touched.phone_number}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("contacts.email")}</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>

                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
