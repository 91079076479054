import "./quickLinkForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import FileInput from "../../../fileInput/fileInput";
import * as productAction from "../../../../redux/actions/product.action";
import * as linkAction from "../../../../redux/actions/link.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from "../../../../helpers/select.custom";
import { getTheme } from "../../../../helpers/select.theme";
import Select from "react-select";

const QuickLinkForm = ({ type }) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const { memberId, entityId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const { linkDetails, link, error, productList } = useSelector((state) => ({
    linkDetails: state.link.linkDetails,
    productList: state.product.productList,
    link: state.link.link,
    error: state.link.linkError,
  }));
  const [showExitModal, setShowExitModal] = useState(false);

  const initialValues = {
    title: entityId ? linkDetails?.name || "" : "",
    order: entityId ? linkDetails?.order || "" : "",
    source: entityId
      ? ["internal_link", "external_link"].includes(linkDetails?.type)
        ? "url"
        : "upload"
      : "url",
    linkType: entityId
      ? linkDetails?.type === "internal_link"
        ? "internal_link"
        : "external_link"
      : "internal_link",
    url: entityId ? linkDetails?.link || "" : "",
    file: entityId ? linkDetails?.file || "" : "",
    product_operator:
      entityId && !memberId ? linkDetails?.product_operator || "" : "and",
    product:
      entityId && !memberId
        ? linkDetails?.products?.map((product) => ({
            value: product.id,
            label: product.name,
          }))
        : [],
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const VALID_FILE_TYPES = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "csv",
  ];

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required field"),
    order: Yup.number().typeError("Order must be of type 'Number'"),
    source: Yup.string(),
    linkType: Yup.string(),
    url: Yup.string(),
    file: Yup.mixed()
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid file extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
    product_operator: Yup.string(),
    product: Yup.mixed(),
  });

  useEffect(() => {
    productAction.fetchSelectOptions(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      linkAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && link) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, link, submittedForm, navigate]);

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    let type = "";

    let {
      file,
      linkType,
      url,
      source,
      title,
      order,
      product,
      product_operator,
    } = values;

    if (source === "upload") {
      type = "file";
      if (file && file.type === "application/pdf") {
        type = "pdf_file";
      }
      url = "";
    } else {
      type = linkType;
    }

    const formData = new FormData();

    const formBody = {
      type,
      link: url,
      name: title,
      location: memberId,
      order,
      products: product?.map((product) => product.value),
      product_operator,
    };

    formData.append("quickAccessLink", JSON.stringify(formBody));
    formData.append("file", file);

    if (entityId) {
      await linkAction.update(dispatch, entityId, formData);
    } else {
      await linkAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          resetForm,
        } = formik;

        return (
          <div className="quick-link">
            <div className="quick-link-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Link ${entityId ? "edited" : "created"} successfully.`}
                </Alert>
              )}
              <div className="quick-link-header">
                <h1 className="quick-link-title">
                  {entityId ? "Edit Quick Link" : "Create Quick Link"}
                </h1>
              </div>
              <Form className="quick-link-form">
                <div className="quick-link-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    {!memberId && (
                      <div>
                        <div className="form-input">
                          <Form.Label>Required Products</Form.Label>
                          <Select
                            theme={(theme) => getTheme(theme)}
                            className="form-select-custom quick-link-select"
                            value={values.product}
                            isMulti
                            styles={customStyles}
                            onChange={(option) =>
                              setFieldValue("product", option)
                            }
                            closeMenuOnSelect={true}
                            onBlur={handleBlur}
                            options={productList?.data?.map(
                              ({ id, attributes: { name } }) => ({
                                value: id,
                                label: name,
                              })
                            )}
                            isInvalid={errors.product && touched.product}
                          />
                          <span className="input-info-error">
                            {errors.product}
                          </span>
                        </div>
                        <div className="quick-link-operator-container">
                          <div className="quick-link-operator">
                            <span
                              className={
                                values.product_operator === "or"
                                  ? "quick-link-operator-selected"
                                  : undefined
                              }
                            >
                              OR
                            </span>
                            <Form.Check
                              bsPrefix="form-custom-switch"
                              type="switch"
                              value={values.product_operator}
                              onChange={() => {
                                setFieldValue(
                                  "product_operator",
                                  values.product_operator === "or"
                                    ? "and"
                                    : "or"
                                );
                              }}
                              onBlur={handleBlur}
                              id="product_operator"
                              name="product_operator"
                              checked={values.product_operator !== "or"}
                            ></Form.Check>
                            <span
                              className={
                                values.product_operator !== "or"
                                  ? "quick-link-operator-selected"
                                  : undefined
                              }
                            >
                              AND
                            </span>
                            <span className="quick-link-operator-feedback">{`Link will be displayed if the member purchased ${
                              values.product_operator === "or" ? "any" : "all"
                            } of the selected products`}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-input">
                      <Form.Label>Order</Form.Label>
                      <Form.Control
                        name="order"
                        value={values.order}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.order && touched.order}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.order}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="quick-link-form-radio-group">
                    <Form.Label>Link source</Form.Label>
                    <div className="quick-link-form-radio">
                      <Form.Check
                        type="radio"
                        id="url"
                        label="URL"
                        bsPrefix="form-check-input-custom"
                        name="source"
                        value="url"
                        checked={values.source !== "upload"}
                        onChange={(e) => {
                          // setFieldValue("file", "");
                          handleChange(e);
                        }}
                        isInvalid={errors.source && touched.source}
                      />
                      <Form.Check
                        type="radio"
                        id="upload"
                        bsPrefix="form-check-input-custom"
                        label="Upload File"
                        name="source"
                        value="upload"
                        checked={values.source === "upload"}
                        onChange={(e) => {
                          // setFieldValue("url", "");
                          handleChange(e);
                        }}
                        isInvalid={errors.source && touched.source}
                      />
                    </div>
                  </div>
                  <div className="link-content-container">
                    {values.source === "upload" ? (
                      <div className="form-input">
                        <FileInput
                          title="Browse a File"
                          allowedTypes={"PDF, DOC, XLS"}
                          value={values.file}
                          setFieldValue={setFieldValue}
                          errorMessage={errors}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="quick-link-form-radio-group">
                          <Form.Label>Points to</Form.Label>
                          <div className="quick-link-form-radio">
                            <Form.Check
                              type="radio"
                              id="internal_link"
                              bsPrefix="form-check-input-custom"
                              label="Internal Link"
                              name="linkType"
                              value="internal_link"
                              checked={values.linkType !== "external_link"}
                              onChange={handleChange}
                              isInvalid={errors.linkType && touched.linkType}
                            />
                            <Form.Check
                              type="radio"
                              bsPrefix="form-check-input-custom"
                              id="external_link"
                              label="External link"
                              name="linkType"
                              value="external_link"
                              checked={values.linkType === "external_link"}
                              onChange={handleChange}
                              isInvalid={errors.linkType && touched.linkType}
                            />
                          </div>
                        </div>
                        <div className="form-input">
                          <Form.Label>URL</Form.Label>
                          <Form.Control
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.url && touched.url}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.url}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        if(memberId) {
                          navigate(`/members/form/${memberId}/quickaccess`);
                        } else {
                          navigate(`/quick-access`);
                        }
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default QuickLinkForm;
