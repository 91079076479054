import { createSlice } from "@reduxjs/toolkit";

const productReducer = createSlice({
  name: "product",
  initialState: {
    productDetails: null,
    product: null,
    productList: null,
    productMetadata: null,
    productError: '',
    productDeletedSuccess: null,
    productEditSuccess: null,
    purchasedProducts: null,
  },
  reducers: {
    setProductDetail: (state, action) => {
      state.productDetails = action.payload.data;
      state.productError = null;
    },
    setProductList: (state, action) => {
      state.productList = action.payload.data;
      state.productError = null;
    },
    setPurchasedProductList: (state, action) => {
      state.purchasedProducts = action.payload.data;
      state.productError = null;
    },
    setMetadata: (state, action) => {
      state.productMetadata = action.payload.meta;
    },
    setProduct: (state, action) => {
      state.product = action.payload.data;
      state.productError = null;
    },
    setProductError: (state, action) => {
      state.productError = action.payload.error;
    },
    setProductDeleted: (state, action) => {
      state.productDeletedSuccess = action.payload.data;
      state.productError = null;
    },
    setProductEdited: (state, action) => {
      state.productEditSuccess = action.payload.data;
      state.productError = null;
    },
    cleanProductState: (state) => {
      state = productReducer.getInitialState();
    },
  },
});

export const {
  setProductDetail,
  setProductList,
  setProductError,
  setProduct,
  setMetadata,
  setProductDeleted,
  setProductEdited,
  cleanProductState,
  setPurchasedProductList
} = productReducer.actions;

export default productReducer.reducer;