// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-tabs-container {
  flex: 1 1;
  margin-top: 1em;
  margin-inline: 3.5em;
  padding-block: 2em;
  border-radius: 1em;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tag-tabs-container > .tab-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/views/tag/tagTabs/tagTabs.scss","webpack://./src/sass/globals/globals.scss","webpack://./src/sass/globals/mixins.scss"],"names":[],"mappings":"AAIC;EACC,SAAA;EACA,eAAA;EACA,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBCNM;EDON,WAAA;EEJD,aAAA;EACA,sBAAA;EACA,uBFGqB;EEFrB,mBFE6B;AAA9B;AAEE;EACC,WAAA;AAAH","sourcesContent":["@import '../../../../sass/globals/globals.scss';\n@import '../../../../sass/globals/mixins.scss';\n\n.tag-tabs {\n\t&-container {\n\t\tflex: 1;\n\t\tmargin-top: 1em;\n\t\tmargin-inline: 3.5em;\n\t\tpadding-block: 2em;\n\t\tborder-radius: 1em;\n\t\tbackground-color: $white;\n\t\twidth: 100%;\n\t\t@include flexColumn(center, center);\n\n\t\t& > .tab-content {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}","$dark-red: #8f003c;\n$sidebar-selected-color: #c4145d;\n$sidebar-logo-color: #a53363;\n$logo-color: #920942;\n$white: #fff;\n$grey: #f3f6f9;\n$grey2: #e2e5e7;\n$dark-grey: #777776;\n$font-color: #fff;\n$shadow-color: #4e7da2;\n$font: 'Rubik';\n","@mixin flex($justify-content, $align-items) {\n\tdisplay: flex;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n\n@mixin flexColumn($justify-content, $align-items) {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
