import { createSlice } from "@reduxjs/toolkit";

const memberGroupReducer = createSlice({
  name: "memberGroup",
  initialState: {
    memberGroupDetails: null,
    memberGroup: null,
    memberGroupList: null,
    memberGroupFilterList: null,
    memberGroupMetadata: null,
    memberGroupError: "",
    memberGroupSelectList: null,
    slideDuration: 4000,
    listLoading: false,
  },
  reducers: {
    setMemberGroupDetail: (state, action) => {
      state.memberGroupDetails = action.payload.data;
      state.memberGroupError = null;
    },
    setMemberGroupList: (state, action) => {
      state.memberGroupList = action.payload.data;
      state.memberGroupError = null;
    },
    setMemberGroupSelectList: (state, action) => {
      state.memberGroupSelectList = action.payload.data;
      state.memberGroupError = null;
    },
    setMemberGroupFilterList: (state, action) => {
      state.memberGroupFilterList = action.payload.data;
      state.memberGroupError = null;
    },
    setMetadata: (state, action) => {
      state.memberGroupMetadata = action.payload.meta;
    },
    setMemberGroup: (state, action) => {
      state.memberGroup = action.payload.data;
      state.memberGroupError = null;
    },
    setMemberGroupError: (state, action) => {
      state.memberGroupError = action.payload.error;
    },
    setSlideDuration: (state, action) => {
      state.slideDuration = action.payload.data;
    },
    cleanMemberGroupState: (state) => {
      state = memberGroupReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
  },
});

export const {
  setMemberGroupDetail,
  setMemberGroupList,
  setMemberGroupError,
  setMemberGroup,
  setMetadata,
  cleanMemberGroupState,
  setListLoading,
  setMemberGroupFilterList,
  setMemberGroupSelectList,
  setSlideDuration,
} = memberGroupReducer.actions;

export default memberGroupReducer.reducer;
