import { createSlice } from "@reduxjs/toolkit";

const roleReducer = createSlice({
  name: "role",
  initialState: {
    roleDetails: null,
    role: null,
    roleList: null,
    roleMetadata: null,
    roleError: '',
    listLoading: false,
  },
  reducers: {
    setRoleDetail: (state, action) => {
      state.roleDetails = action.payload.data;
      state.roleError = null;
    },
    setRoleList: (state, action) => {
      state.roleList = action.payload.data;
      state.roleError = null;
    },
    setMetadata: (state, action) => {
      state.roleMetadata = action.payload.meta;
    },
    setRole: (state, action) => {
      state.role = action.payload.data;
      state.roleError = null;
    },
    setRoleError: (state, action) => {
      state.roleError = action.payload.error;
    },
    cleanRoleState: (state) => {
      state = roleReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setRoleDetail,
  setRoleList,
  setRoleError,
  setCreatedRole,
  setEditedRole,
  setMetadata,
  setRole,
  cleanRoleState,
  setListLoading,
} = roleReducer.actions;

export default roleReducer.reducer;