/* eslint-disable react-hooks/exhaustive-deps */
import "./recipeForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../../fileInput/fileInput";
import { Formik } from "formik";
import * as Yup from "yup";
import * as recipeAction from "../../../../redux/actions/recipe.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ReactMde from "react-mde";
import * as tagAction from "../../../../redux/actions/tag.action";
import * as tagGroupAction from "../../../../redux/actions/tagGroup.action";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import {
  getInitialTagValues,
  getTagOptions,
  processTagOptions,
  formatGroupLabel,
} from "../../../../helpers/select";
import RecipePreviewModal from "../recipePreviewModal/recipePreviewModal";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from '../../../../helpers/select.custom';

const RecipeForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState("write");
  const [selectedTabIngredients, setSelectedTabIngredients] =
    React.useState("write");
  const [createMode, setCreateMode] = useState({ isDraft: null });
  const [editMode, setEditMode] = useState(null); // draft, publish
  const [editAction, setEditAction] = useState({ change: null });
  const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
  const VALID_FILE_TYPES = ["image/png", "image/jpeg"];
  const [tagOptions, setTagOptions] = useState([]);
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showPreview, setShowPreview] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  const handleClose = () => setShowPreview(false);
  const handleShow = () => setShowPreview(true);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const { recipeDetails, recipe, error, tagList, tagGroupList } = useSelector(
    (state) => ({
      recipeDetails: state.recipe.recipeDetails,
      recipe: state.recipe.recipe,
      error: state.recipe.recipeError,
      tagList: state.tag.tagList,
      tagGroupList: state.tagGroup.tagGroupList,
    })
  );

  const initialValues = {
    title: entityId ? recipeDetails?.name || "" : "",
    instructions: entityId ? recipeDetails?.instructions || "" : "",
    ingredients: entityId ? recipeDetails?.ingredients || "" : "",
    source: entityId ? recipeDetails?.source || "" : "",
    file: entityId ? recipeDetails?.image : "",
    description: entityId ? recipeDetails?.description || "" : "",
    tags: entityId ? getInitialTagValues(recipeDetails) : [],
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required field"),
    instructions: Yup.string().required("Required field"),
    ingredients: Yup.string().required("Required field"),
    description: Yup.string().required("Required field"),
    source: Yup.string().required("Required field"),
    tags: Yup.mixed(),
    file: Yup.mixed()
      .required("Required field")
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid file extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
  });

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      title,
      source,
      file,
      tags = [],
      description,
      instructions,
      ingredients,
    } = values;

    const tagOptions = processTagOptions(tags);

    const formBody = {
      name: title,
      instructions,
      ingredients,
      source,
      description,
      draft: createMode.isDraft, // create as draft or published
      change: editAction.change, // switch from draft to publish and vice versa
      tags: tagOptions.tags,
      tag_groups: tagOptions.tag_groups,
    };

    formData.append("recipe", JSON.stringify(formBody));
    formData.append("image", file);

    if (entityId) {
      await recipeAction.update(dispatch, entityId, formData);
    } else {
      await recipeAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); // Reenable submit button
  };

  useEffect(() => {
    tagAction.fetchSelectOptions(dispatch);
    tagGroupAction.fetchSelectOptions(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      recipeAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setTagOptions(getTagOptions(tagGroupList, tagList));
  }, [JSON.stringify(tagGroupList), JSON.stringify(tagList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && recipe) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, recipe, submittedForm, navigate]);

  useEffect(() => {
    if (entityId && recipeDetails) {
      setEditMode(recipeDetails?.publishedAt ? "publish" : "draft");
    }
  }, [recipeDetails]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          resetForm,
        } = formik;

        return (
          <div className="recipe">
            <div className="recipe-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {entityId
                    ? t("recipes.editSuccess")
                    : t("recipes.createSuccess")}
                </Alert>
              )}
              <div className="recipe-header">
                <h1 className="recipe-title">
                  {entityId ? t("recipes.editRecipe") : t("recipes.newRecipe")}
                </h1>
                <Button
                  onClick={handleShow}
                  className="form-action preview-btn"
                >
                  Preview
                </Button>
              </div>
              <Form className="recipe-form">
                <div className="recipe-form-layout">
                  <div className="form-column">
                    <div className="form-input">
                      <Form.Label>{t("recipes.title")}</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-area">
                      <Form.Label>{t("recipes.description")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-area ">
                      <Form.Label>{t("recipes.intructions")}</Form.Label>
                      <ReactMde
                        as="textarea"
                        name="instructions"
                        value={values.instructions}
                        onChange={(value) =>
                          setFieldValue("instructions", value)
                        }
                        onBlur={handleBlur}
                        isInvalid={errors.instructions && touched.instructions}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                          Promise.resolve(converter.makeHtml(markdown))
                        }
                        childProps={{
                          writeButton: {
                            tabIndex: -1,
                          },
                        }}
                      />
                    </div>
                    <p
                      className="input-info-error"
                      style={{ marginLeft: ".5em" }}
                    >
                      {errors.instructions}
                    </p>
                    <div className="form-area ingredients">
                      <Form.Label>{t("recipes.ingredients")}</Form.Label>
                      <ReactMde
                        as="textarea"
                        name="ingredients"
                        value={values.ingredients}
                        onChange={(value) =>
                          setFieldValue("ingredients", value)
                        }
                        onBlur={handleBlur}
                        isInvalid={errors.ingredients && touched.ingredients}
                        selectedTab={selectedTabIngredients}
                        onTabChange={setSelectedTabIngredients}
                        generateMarkdownPreview={(markdown) =>
                          Promise.resolve(converter.makeHtml(markdown))
                        }
                        childProps={{
                          writeButton: {
                            tabIndex: -1,
                          },
                        }}
                      />
                    </div>
                    <p
                      className="input-info-error"
                      style={{ marginLeft: ".5em" }}
                    >
                      {errors.ingredients}
                    </p>
                  </div>

                  <div className="form-column">
                    <div className="form-input">
                      <Form.Label>{t("recipes.image")}</Form.Label>
                      <FileInput
                        title="Browse an Image"
                        allowedTypes={"PNG, JPG"}
                        value={values.file}
                        setFieldValue={setFieldValue}
                        errorMessage={errors}
                      />
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("recipes.source")}</Form.Label>
                      <Form.Control
                        name="source"
                        value={values.source}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.source && touched.source}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.source}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("articles.tags")}</Form.Label>
                      <Select
                        isMulti
                        styles={customStyles}
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        value={values.tags}
                        onChange={(option) => setFieldValue("tags", option)}
                        closeMenuOnSelect={false}
                        onBlur={handleBlur}
                        options={tagOptions}
                        isInvalid={errors.tags && touched.tags}
                        formatGroupLabel={formatGroupLabel}
                        getOptionLabel={(option) => (
                          <div className="form-select-custom-label">
                            <span className="form-select-custom-label-primary">
                              {option.label}
                            </span>
                            <span className="form-select-custom-label-secondary">
                              {option.secondaryLabel}
                            </span>
                          </div>
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.tags}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>

                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if(formik.dirty && !renderSuccessAlert){
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      if (editMode) {
                        setEditAction({ change: true });
                      } else {
                        setCreateMode({ isDraft: true });
                        setCustomResetForm(() => resetForm);
                      }
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {editMode === "draft"
                      ? t("buttons.publish")
                      : editMode === "publish"
                      ? t("buttons.draft")
                      : t("buttons.draft")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      if (editMode) {
                        setEditAction({ change: false });
                      } else {
                        setCreateMode({ isDraft: false });
                        setCustomResetForm(() => resetForm);
                      }
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {editMode ? t("buttons.save") : t("buttons.savepublish")}
                  </Button>
                </div>
              </Form>
            </div>
            <RecipePreviewModal
              recipe={values}
              handleClose={handleClose}
              show={showPreview}
            />
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default RecipeForm;
