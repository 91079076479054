import axios, { authHeader } from "../../services/HTTPService";
import { processMemberOptions } from "../../helpers/select";
const API_URL = process.env.REACT_APP_API_URL;
const action = require("../reducers/memberReducer");

export const createMember = (dispatch, data, callback, setMemberId) => {
  const {
    general: {
      name,
      groups,
      short_name,
      title,
      dor_code,
      date_started_working_with_miia,
      date_when_they_become_active,
    },
    type,
    contact: {
      mailing_address_1,
      mailing_address_2,
      mailing_city,
      mailing_state,
      mailing_county,
      phone_extension,
      phone,
    },
    product: { productsIds },
  } = data;

  const { location_groups: locationGroupsIds } = processMemberOptions(groups);

  const memberFormated = {
    name,
    short_name,
    title,
    locationGroupsIds,
    dor_code,
    date_started_working_with_miia,
    date_when_they_become_active,
    mailing_address_1,
    mailing_address_2,
    mailing_city,
    mailing_state,
    mailing_county,
    phone_extension,
    phone,
    type,
    productsIds,
  };

  const dataFormated = new FormData();

  dataFormated.append("data", `${JSON.stringify(memberFormated)}`);

  var config = {
    method: "post",
    url: API_URL + "/api/locations",
    headers: authHeader(),
    data: dataFormated,
  };
  axios(config)
    .then(function ({ data: { data } }) {
      setMemberId(data?.id);
      callback();
    })
    .catch((error) => {
      const {
        data: {
          error: {
            message,
            details: { errors },
          },
        },
      } = error.response;

      let errorMessage = "";

      if (errors?.length > 0) {
        errors.forEach((fieldError) => {
          errorMessage += fieldError?.message + ": " + fieldError?.path + ".";
        });
      } else {
        errorMessage = message;
      }

      dispatch(action.setMemberError(errorMessage));
    });
};

export const fetchAll = async (
  dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    isFilter,
    memberFilters,
  } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    memberFilters: { all: true, members: [], groups: [] },
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/locations/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        isFilter,
        memberFilters,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      if (!isFilter) {
        dispatch(action.setMemberList({ data }));
        dispatch(action.setMetadata({ meta }));
      } else {
        dispatch(action.setMemberFilterList({ data }));
        dispatch(action.setFilterMetadata({ meta }));
      }
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/locations/${id}?source=backoffice`, {
      headers: authHeader(),
    })
    .then(({ data: { data } }) => {
      dispatch(action.setMemberDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchCarrouselSlides = async (dispatch, id) => {
  try {
    axios
      .get(API_URL + "api/carrousel-slides/time-shown", {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(
          action.setCarrouselTime({ carrouselTimeSlide: response.data })
        );
      });
  } catch (error) {
    dispatch(action.setCarrouselTime({ error: error.response.data }));
  }
};

export const fetchTypes = async (dispatch) => {
  return axios
    .get(API_URL + `/api/locations/types`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setMemberTypes({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/locations", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setMember({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/locations/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setMember({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const remove = async (dispatch, id, params, setMemberDeletedSuccessMessage = () => {} ) => {
  return axios
    .delete(API_URL + `/api/locations/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
      setMemberDeletedSuccessMessage("Member deleted successfully");
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchSelectOptions = async (
  dispatch,
  { isOverview, isDropdown } = {}
) => {
  return axios
    .get(API_URL + `/api/locations/options`, { headers: authHeader() })
    .then(({ data }) => {
      if (isOverview) {
        dispatch(action.setMapMemberList({ data }));
      } else if (isDropdown) {
        dispatch(action.setMemberSelectList({ data }));
      } else {
        dispatch(action.setMemberList({ data }));
      }
    })
    .catch((error) => {
      dispatch(
        action.setMemberError({ error: error?.response?.data?.error?.message })
      );
    });
};
