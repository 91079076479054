import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as recipeAction from "../../../../redux/actions/recipe.action";
import List from "../../../list/list";
import { fetchSASToken } from "../../../../services/SASTokenService";
import { formatDate } from "../../../../helpers/date";
import { useTranslation } from "react-i18next";
import placeholder from "../../../../assets/images/placeholder.jpg";

const recipeFields = [
  //list will sort by the first element by default
  "name",
  "source",
  "publishedAt",
];

const RecipeList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [updatedRecipes, setUpdatedRecipes] = useState([]);

  const { recipes, meta, listLoading } = useSelector((state) => ({
    recipes: state.recipe.recipeList,
    meta: state.recipe.recipeMetadata,
    listLoading: state.recipe.listLoading,
  }));

  useEffect(() => {
    recipeAction.fetchAll(dispatch);
    const fetchToken = async () => {
      const newToken = await fetchSASToken();
      setToken(newToken);
    };
    fetchToken();
  }, [dispatch]);


  useEffect(() => {
    if (recipes?.data && recipes?.data?.length > 0 && token) {
      setUpdatedRecipes(
recipes?.data?.map(
        ({ id, attributes: { image, name, source, publishedAt, createdAt,order } }) => ({
          id,
          image: 
image?.data?.attributes?.formats?.thumbnail?.url ||
          image?.data?.attributes?.url
            ? `${
image?.data?.attributes?.formats?.thumbnail?.url ||
              image?.data?.attributes?.url}?${token}`
            : placeholder,
          columns: [{ primary: name, secondary: formatDate(createdAt) }, { primary: source }],
          draft: publishedAt === null,
          name,
          order
        })
      )
      );
    }
  }
  , [recipes, token, dispatch]);

  return (
    <List
      title={t("recipes.recipes")}
      redirect="/recipes/form"
      tooltipTextDelete="Delete Recipe"
      columnNames={["","IMAGE", "NAME", "SOURCE"]}
      hasImage={true}
      entities={updatedRecipes}
      fieldsToSort={recipeFields}
      fetchAction={(params) => recipeAction.fetchAll(dispatch, params)}
      isOverview={false}
      metadata={meta}
      showFilterTab={false}
      deleteAction={(id, params) => recipeAction.remove(dispatch, id, params)}
      loading={listLoading}
      sortIsDisabled={false}
      reOrderAction={(body, filterParams) =>{recipeAction.reOrder(dispatch, body, filterParams) }}
    />
  );
};

export default RecipeList;
