export const customStyles = {
	// control: (provided, state) => ({
	// 	...provided,
	// 	background: '#fff',
	// 	borderColor: '#9e9e9e',
	// 	minHeight: '30px',
	// 	height: '30px',
	// 	boxShadow: state.isFocused ? null : null,
	// }),

	valueContainer: (provided, state) => ({
		...provided,
	}),

	// input: (provided, state) => ({
	// 	...provided,
	// 	margin: '0px',
	// }),
	// indicatorSeparator: state => ({
	// 	display: 'none',
	// }),
	// indicatorsContainer: (provided, state) => ({
	// 	...provided,
	// 	height: '30px',
	// }),
};

export const customStylesForTable = {
	valueContainer: (provided) => ({
		...provided,
		width: '8em',
	}),
};

export const customStylesForOverview = {
	valueContainer: (provided) => ({
		...provided,
	}),
};