import { t } from "i18next";
import { React } from "react";
import { Link, useMatch } from "react-router-dom";
import { useMatchAny } from "../../services/hooks/useMatchAny";
import "./sidebar.scss";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div
        className={`sidebar-container ${
          useMatch("/") ? "sidebar-selected" : ""
        }`}
      >
        <Link to="/" className="sidebar-item">
          <span className="material-symbols-outlined sidebar-item-logo">
            grid_view
          </span>
          <span className="sidebar-item-name">OVERVIEW</span>
        </Link>
      </div>
      <div className="sidebar-container menu-on-hover">
        <div className="sidebar-item--nohover">
          <span className="material-symbols-outlined sidebar-item-logo">
            view_quilt
          </span>
          <span className="sidebar-item-name">CONTENT</span>
        </div>
        <div id="content" className="sidebar-submenu-container">
          <Link
            to="/healthprograms"
            className={`sidebar-submenu ${useMatchAny(["/healthprograms", "/healthprograms/form/", "/healthprograms/form/:id"])
                ? "sidebar-selected"
                : ""
              }`}
          >
            <span className="sidebar-submenu-item">Health Programs</span>
          </Link>
          <Link
            to="/rmprograms"
            className={`sidebar-submenu ${useMatchAny(["/rmprograms", "/rmprograms/form/", "/rmprograms/form/:id"])
                ? "sidebar-selected"
                : ""
              }`}
          >
            <span className="sidebar-submenu-item">RM Programs</span>
          </Link>
          <Link
            to="/recipes"
            className={`sidebar-submenu ${
              useMatchAny(["/recipes", "/recipes/form/", "/recipes/form/:id"])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Recipes</span>
          </Link>
          <Link
            to="/articles"
            className={`sidebar-submenu ${
              useMatchAny([
                "/articles",
                "/articles/form/",
                "/articles/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Articles</span>
          </Link>
          <Link
            to="/tags"
            className={`sidebar-submenu ${
              useMatchAny(["/tags", "/tags/form/", "/tags/form/:id"])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Tags</span>
          </Link>
          <Link
            to="/contacts"
            className={`sidebar-submenu ${
              useMatchAny([
                "/contacts",
                "/contacts/form/",
                "/contacts/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">MIIA Contacts</span>
          </Link>
          <Link
            to="/news"
            className={`sidebar-submenu ${
              useMatchAny(["/news", "/news/form/:id"]) ? "sidebar-selected" : ""
            }`}
          >
            <span className="sidebar-submenu-item">Featured News</span>
          </Link>
          <Link
            to="/carrousel"
            className={`sidebar-submenu ${
              useMatchAny([
                "/carrousel",
                "carrousel/form/",
                "/carrousel/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">
              {t("carrousel.titleCarousel")}
            </span>
          </Link>
          <Link
            to="/newsletter"
            className={`sidebar-submenu ${
              useMatchAny([
                "/newsletter",
                "/newsletter/form/",
                "/newsletter/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Newsletter</span>
          </Link>
        </div>
      </div>
      <div
        className={`sidebar-container ${
          useMatchAny([
            "/bulk",
            "/bulk/form/",
            "/bulk/form/:id",
          ])
            ? "sidebar-selected"
            : ""
        }`}
      >
        <Link to="/bulk" className="sidebar-item">
          <span className="material-symbols-outlined sidebar-item-logo">
            shelves
          </span>
          <span className="sidebar-item-name">BULK ACTIONS</span>
        </Link>
      </div>
      <div className="sidebar-container menu-on-hover">
        <div className="sidebar-item--nohover">
          <span className="material-symbols-outlined sidebar-item-logo">
            format_list_bulleted
          </span>
          <span className="sidebar-item-name">PRODUCTS</span>
        </div>
        <div id="products" className="sidebar-submenu-container">
          <Link
            to="/dropdowns"
            className={`sidebar-submenu ${
              useMatchAny([
                "/dropdowns",
                "/dropdowns/form/",
                "/dropdowns/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Dropdowns</span>
          </Link>
          <Link
            to="/quick-access"
            className={`sidebar-submenu ${
              useMatchAny([
                "/quick-access",
                "/quick-access/form/",
                "/quick-access/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Quick Access Link</span>
          </Link>
          <Link
            to="/roles"
            className={`sidebar-submenu ${
              useMatchAny(["/roles", "/roles/form/", "/roles/form/:id"])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Roles</span>
          </Link>
        </div>
      </div>
      <div className="sidebar-container menu-on-hover">
        <div className="sidebar-item--nohover">
          <span className="material-symbols-outlined sidebar-item-logo">
            group
          </span>
          <span className="sidebar-item-name">MEMBERS</span>
        </div>
        <div id="members" className="sidebar-submenu-container">
          <Link
            to="/members"
            className={`sidebar-submenu ${
              useMatchAny([
                "/members",
                "/members/form/",
                "/members/form/:id/:currentTab",
                "/dropdown-option/member/:memberId/option/:optionId/form",
                "/web-portal-users/member/:memberId/form",
                "/web-portal-users/member/:memberId/form/:entityId",
                "/quick-access/member/:memberId/form",
                "/quick-access/member/:memberId/form/:entityId",
                "/buttons-in-home/form/:entityId",
                "/buttons-in-home/form",
                "/buttons-in-home/member/:memberId/form",
                "/buttons-in-home/member/:memberId/form/:entityId",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Member List</span>
          </Link>
          <Link
            to="/member-groups"
            className={`sidebar-submenu ${
              useMatchAny([
                "/member-groups",
                "/member-groups/form/",
                "/member-groups/form/:id",
              ])
                ? "sidebar-selected"
                : ""
            }`}
          >
            <span className="sidebar-submenu-item">Member Groups</span>
          </Link>
        </div>
      </div>
      <div
        className={`sidebar-container ${
          useMatchAny([
            "/notifications",
            "/notifications/form/",
          ])
            ? "sidebar-selected"
            : ""
        }`}
      >
        <Link to="/notifications" className="sidebar-item">
          <span className="material-symbols-outlined sidebar-item-logo">
            notifications
          </span>
          <span className="sidebar-item-name">NOTIFICATIONS</span>
        </Link>
      </div>
      {/* <div className="sidebar-container">
        <a
          href={`${process.env.REACT_APP_API_URL}/admin/plugins/upload`}
          className="sidebar-item sidebar-file-item-container"
        >
          <div className="sidebar-file-item">
            <span className="material-symbols-outlined sidebar-item-logo">
              folder
            </span>
            <span className="sidebar-item-name">FILES</span>
          </div>
          <span className="external-icon material-symbols-outlined">
            open_in_new
          </span>
        </a>
      </div> */}
    </div>
  );
};

export default Sidebar;
