import "./dropdownOptionValueForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import FileInput from "../../../fileInput/fileInput";
import * as dropdownAction from "../../../../redux/actions/dropdown.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { getTheme } from '../../../../helpers/select.theme';
import Select from "react-select";

const DropdownOptionValueForm = () => {
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const navigate = useNavigate();
  const { memberId, optionId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { optionValueDetails, optionDetails, dropdownOptionValue, error } =
    useSelector((state) => ({
      optionValueDetails: state.dropdown.dropdownOptionValueDetails,
      optionDetails: state.dropdown.dropdownOptionDetails,
      dropdownOptionValue: state.dropdown.dropdownOptionValue,
      error: state.dropdown.dropdownError,
    }));

  const MAX_FILE_SIZE = 50 * 1024 * 1024;
  const VALID_FILE_TYPES = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "csv",
  ];

  const getName = (type, { label = false }) => {
    const mapping = {
      email: label ? "Email" : "email",
      internal_link: label ? "URL" : "url",
      external_link: label ? "URL" : "url",
    }
    return mapping[type.value];
  };

  const getValue = (values) => {
    const mapping = {
      email: values.email,
      internal_link: values.url,
      external_link: values.url,
    }
    return mapping[values.type.value];
  };

  const getValidState = (type, touched, errors) => {
    const mapping = {
      email: touched.email && errors.email,
      internal_link: touched.url && errors.url,
      external_link: touched.url && errors.url,
    }
    return mapping[type.value];
  };

  const getError = (type, errors) => {
    const mapping = {
      email: errors.email,
      internal_link: errors.url,
      external_link: errors.url,
    }
    return mapping[type.value];
  };

  const getType = {
    internal_link: "Internal Link",
    external_link: "External Link",
    email: "Send an email",
    file: "File",
  };

  const initialValues = {
    type: { value: optionValueDetails?.type, label: getType[optionValueDetails?.type] } || "",
    url: optionValueDetails?.link || "",
    email: optionValueDetails?.email || "",
    file: optionValueDetails?.file || "",
  };

  const formSchema = Yup.object().shape({
    type: Yup.mixed(),
    url: Yup.string(),
    email: Yup.string().email("Invalid email"),
    file: Yup.mixed()
      .test(
        "is-correct-size",
        "File too large",
        (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
      )
      .test(
        "is-correct-format",
        "Invalid file extension",
        (value, _) =>
          VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
      ),
  });

  useEffect(() => {
    dropdownAction.fetchOptionDetails(dispatch, optionId);
    dropdownAction.fetchOptionValueDetails(dispatch, optionId, memberId);
  }, [dispatch, optionId, memberId]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && dropdownOptionValue) {
      setRenderSuccessAlert(true);
    }
  }, [dropdownOptionValue, submittedForm, navigate]);

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    let { file, url, email, type } = values;

    const formData = new FormData();

    const formBody = {
      type: type.value,
      link: url,
      email,
      location: memberId,
      dropdown_option: optionId,
    };

    formData.append("data", JSON.stringify(formBody));
    formData.append("file", file);

    if (optionValueDetails) {
      await dropdownAction.updateOptionValue(
        dispatch,
        optionValueDetails.id,
        formData
      );
    } else {
      await dropdownAction.createOptionValue(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <div className="option-value">
            <div className="option-value-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Dropdown option edited successfully.`}
                </Alert>
              )}
              <div className="option-value-header">
                <h1 className="option-value-title">
                  {`Editing Dropdown Option Value: ${optionDetails?.name}`}
                </h1>
              </div>
              <Form className="option-value-form">
                <div className="option-value-form-layout">
                <div className="form-input">
                  <Form.Label>Type</Form.Label>
                  <Select
                    className="modal-option-value-form-select"
                    theme={(theme) => getTheme(theme)}
                    closeMenuOnSelect={true}
                    value={values.type}
                    onChange={(value) => {
                      setFieldValue("type", value);
                    }}
                    options={[
                      { value: "internal_link", label: "Internal Link" },
                      { value: "external_link", label: "External Link" },
                      { value: "file", label: "File" },
                      { value: "email", label: "Send an email" },
                    ]}
                  />
                </div>
                  {values.type.value === "file" && (
                    <div className="form-input">
                      <FileInput
                          title="Browse a File"
                          allowedTypes={"PDF, DOC, XLS"}
                          value={values.file}
                          setFieldValue={setFieldValue}
                          errorMessage={errors}
                          fileSize={"50 MB"}
                        />
                    </div>
                  )}
                  {["internal_link", "external_link", "email"].includes(
                    values.type.value
                  ) && (
                    <div className="form-input">
                      <Form.Label>{getName(values.type, { label: true })}</Form.Label>
                      <Form.Control
                        name={getName(values.type, { label: false })}
                        value={getValue(values)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={getValidState(values.type, touched, errors)}
                      />
                      <Form.Control.Feedback default_type="invalid">
                        {getError(values.type, errors)}
                      </Form.Control.Feedback>
                    </div>
                  )}
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        navigate(`/members/form/${memberId}/dropdowns`)
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button className="form-action form-action-primary" onClick={()=>{
                    dropdownAction.deleteDropdownOptionValue(dispatch, optionValueDetails?.id);
                    navigate(`/members/form/${memberId}/dropdowns`)
                  }} >
                    Clear / Return to default
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default DropdownOptionValueForm;
