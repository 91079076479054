import List from '../../../list/list';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as buttonInHomeAction from "../../../../redux/actions/button.action";

const buttonInHomeFields = [
  //list will sort by the first element by default
	"name",
  "link",
  "publishedAt",
];

const ButtonInHomeList = ({member}) => {
	const dispatch = useDispatch();

  const initialParams = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    member
  };

  const { buttons, meta, listLoading } = useSelector((state) => ({
    buttons: state.button.buttonList,
    meta: state.button.buttonMetadata,
    listLoading: state.button.listLoading,
  }));

  useEffect(() => {
    buttonInHomeAction.fetchAll(dispatch, initialParams);
  }, [dispatch]);

	return (
		<List
			title='Buttons In Home'
			redirect={`/buttons-in-home/member/${member}/form`}
      columnNames={['NAME', "LINK"]}
			entities={buttons?.data?.map(({ id, attributes: { name, link } }) => ({
        id,
				columns: [
					{ primary: name },
          { primary: link },
				],
        name,
      }))}
      fieldsToSort={buttonInHomeFields}
      fetchAction={(params) => buttonInHomeAction.fetchAll(dispatch, {...params, member})}
      isOverview={false}
      metadata={meta}
      tooltipTextDelete='Delete Button In Home'
      showFilterTab={false}
      member={member}
      deleteAction={(id, params) => buttonInHomeAction.remove(dispatch, id, params)}
      listLoading={listLoading}
		/>
	);
};

export default ButtonInHomeList;
