import { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { fetchSASToken } from '../../../../services/SASTokenService';
import "./articlePreview.scss";
import preview1 from "../../../../assets/images/articles/preview1.jpg";
import preview2 from "../../../../assets/images/articles/preview2.jpg";
import Image from "react-bootstrap/Image";

const ArticlePreview = ({ article }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      setToken(await fetchSASToken());
    };
    fetchToken();
  }, []);

  return (
		<Container fluid className="article-box-data">
      <Container fluid className="container-img-article">
        <div
          style={{
            height: "350px",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${
              article.file?.url ? article.file?.url + "?" + token : URL.createObjectURL(article?.file || new Blob())
            })`,
          }}
        ></div>
      </Container>
      <Container fluid>
        <Row className="article-box">
          <Col className="article-detail">
            <h3>{article.title}</h3>
            <h6 className="by-author">
              <b>
                {t("utils.by")} {article.author}
              </b>
            </h6>
            <ReactMarkdown children={article.content} />
          </Col>
          <Col className="more-articles">
            <Container fluid className="box-more">
              <p>
                {t("articles.more")} <b>{t("articles.articles")}</b>
              </p>
            </Container>
            <div>
						<Image
							fluid
							src={preview1}
							className="article-img-preview"
						/>
						<Container fluid className="article-preview">
							<h3>Yoga</h3>
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis tempore nobis temporibus distinctio dolores omnis quae totam tenetur est molestiae.</p>
						</Container>
						<Image
							fluid
							src={preview2}
							className="article-img-preview"
						/>
						<Container fluid className="article-preview">
							<h3>Worker compensation</h3>
							<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim ullam consequuntur ad autem reiciendis temporibus nostrum unde placeat ipsam dolorum!</p>
						</Container>
						</div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ArticlePreview;
