/* eslint-disable react-hooks/exhaustive-deps */
import "./dropdownList.scss";
import { Link, useParams } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState, useRef, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as dropdownAction from "../../../../redux/actions/dropdown.action";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { paginate, populatePagination } from "../../../../helpers/pagination";
import * as productAction from "../../../../redux/actions/product.action";
import { Spinner } from "react-bootstrap";
import { Draggable } from "react-drag-reorder";

const DropdownList = ({ member }) => {
  const [modalAction, setModalAction] = useState(null);
  const [entityName, setEntityName] = useState("");
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);
  const pageRef = useRef([]);
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useState({
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 2,
  });
  const [elements, setElements] = useState([]);

  const { dropdownList, metadata, loading } = useSelector((state) => ({
    dropdownList: state.dropdown.dropdownList,
    metadata: state.dropdown.dropdownMetadata,
    loading: state.dropdown.listLoading,
  }));

  const getElements = () => {
    setTimeout(() => {
      setElements(
        Array.from(document.getElementsByClassName("draggable-container"))
      );
    }, 0);
  };

  const onClickPage = (number) => {
    setFilterParams((prev) => ({
      ...prev,
      page: number,
    }));
  };

  useEffect(() => {
    const pageCount = metadata?.pagination?.pageCount;

    const items = paginate({
      current: filterParams.page,
      max: pageCount,
    })?.items;

    pageRef.current = [];

    const paginationList = populatePagination(
      items,
      pageRef,
      filterParams,
      onClickPage
    );

    setItems(paginationList);
  }, [
    JSON.stringify(filterParams),
    metadata?.pagination?.pageCount,
    JSON.stringify(items),
  ]);

  useEffect(() => {
    pageRef?.current.forEach((page) => {
      page?.classList?.remove("pagination-container-selected");
    });
  }, [filterParams.page]);

  useEffect(() => {
    if (member) {
      productAction.fetchPurchasedProducts(dispatch, member);
    }
    dropdownAction.fetchAll(dispatch, filterParams);
  }, [JSON.stringify(filterParams)]);

  const getPositions = () => elements.map((container) =>
      Array.from(container?.children).map((child, index) => ({
        id: parseInt(child?.firstChild?.id),
        order: index,
      }))
    ).flat();

  useEffect(() => {
    const positions = getPositions();

    if(!positions.length > 0) return;

    dropdownAction.orderDropdownsOptions(
      { dropdownsOptionsOrder: getPositions() },
      dispatch,
      filterParams
    );
  }, [elements]);

  return (
    <div className="dropdown-main-container">
      <div className="dropdown-list">
        <div className="dropdown-header">
          <div className="dropdown-header-main">
            <span className="dropdown-title">{t("dropdowns.dropdowns")}</span>
          </div>
          {!member && (
            <Link
              to={"/dropdowns/form"}
              className="dropdown-list-header-action"
              variant="outline-primary"
            >
              {t("buttons.new")}
            </Link>
          )}
        </div>
        {loading ? (
          <div className="list-loading-spinner-container">
            <Spinner className="list-loading-spinner" animation="border" />
          </div>
        ) : (
          dropdownList?.data?.map(
            (
              { id, attributes: { name, products, dropdown_options } },
              index
            ) => {
              return (
                <div key={index} className="dropdown-list-container">
                  <div id={`product-${products?.data?.id}`} className="product">
                    <div className="dropdown-list-element-text">
                      <span className="dropdown-element-primary-text">
                        {products?.data?.map((product, index) => {
                          return (
                            <span
                              className="dropdown-product-badge"
                              key={product.id}
                            >
                              {product.attributes.name}
                            </span>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="dropdown-list-element">
                    <div className="dropdown-list-element-text">
                      <p className="dropdown-element-secondary-text">
                        <span className="material-symbols-outlined icon-dropdowns">
                          menu
                        </span>
                        {name}
                      </p>
                    </div>
                    {!member && (
                      <div className="dropdown-element-actions">
                        <Link
                          to={
                            member
                              ? `/dropdowns/member/${member}/form/${id}`
                              : `/dropdowns/form/${id}`
                          }
                          className="button-options-link"
                        >
                          <span className="material-symbols-outlined dropdown-element-action">
                            edit
                          </span>
                        </Link>
                        <span
                          onClick={() => {
                            setShow(true);
                            setEntityName(name);
                            setModalAction(() => () => {
                              dropdownAction.remove(dispatch, id, filterParams);
                              setShow(false);
                            });
                          }}
                          className="material-symbols-outlined dropdown-element-action"
                        >
                          delete
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      dropdown_options?.data?.length
                        ? "draggable-container"
                        : "default-container"
                    }
                  >
                    <Draggable onPosChange={getElements}>
                      {dropdown_options?.data?.map(
                        (
                          {
                            id: id_lvl_1,
                            attributes: {
                              name,
                              default_type,
                              dropdown_options,
                            },
                          },
                          index_lvl_1
                        ) => (
                          <div
                            id={id_lvl_1}
                            className="dropdown-options-container"
                            draggable={true}
                            key={index_lvl_1}
                          >
                            <div className="dropdown-list-subelement">
                              <div className="dropdown-list-subelement-text">
                                <span className="dropdown-list-element-secondary-text">
                                  {name}
                                </span>
                              </div>
                              {member && !dropdown_options.length && (
                                <Link
                                  to={`/dropdown-option/member/${member}/option/${id_lvl_1}/form`}
                                  className="button-options-link"
                                >
                                  {default_type !== "table" && (
                                    <span className="material-symbols-outlined dropdown-element-action">
                                      edit
                                    </span>
                                  )}
                                </Link>
                              )}
                            </div>
                            <div
                              className={
                                dropdown_options?.length
                                  ? "draggable-container"
                                  : "default-container"
                              }
                            >
                              <Draggable onPosChange={getElements}>
                                {dropdown_options?.map(
                                  (
                                    {
                                      id: id_lvl_2,
                                      name,
                                      default_type,
                                      dropdown_options,
                                    },
                                    index_lvl_2
                                  ) => (
                                    <div
                                      id={id_lvl_2}
                                      className="dropdown-options-container"
                                      key={index_lvl_2}
                                      draggable={true}
                                    >
                                      <div className="dropdown-list-subelement dropdown-child">
                                        <div className="dropdown-list-subelement-text">
                                          <span className="dropdown-list-element-secondary-text">
                                            {name}
                                          </span>
                                        </div>
                                        {member && (
                                          <Link
                                            to={`/dropdown-option/member/${member}/option/${id_lvl_2}/form`}
                                            className="button-options-link"
                                          >
                                            {default_type !== "table" && (
                                              <span className="material-symbols-outlined dropdown-element-action">
                                                edit
                                              </span>
                                            )}
                                          </Link>
                                        )}
                                      </div>
                                      <div
                                        className={
                                          dropdown_options?.length
                                            ? "draggable-container"
                                            : "default-container"
                                        }
                                      >
                                        <Draggable onPosChange={getElements}>
                                          {dropdown_options?.map(
                                            (
                                              {
                                                id: id_lvl_3,
                                                default_type,
                                                name,
                                              },
                                              index_lvl_3
                                            ) => (
                                              <div
                                                id={id_lvl_3}
                                                key={index_lvl_3}
                                                className="dropdown-list-subelement dropdown-child-2"
                                                draggable={true}
                                              >
                                                <div className="dropdown-list-subelement-text">
                                                  <span className="dropdown-list-element-secondary-text">
                                                    {name}
                                                  </span>
                                                </div>
                                                {member && (
                                                  <Link
                                                    to={`/dropdown-option/member/${member}/option/${id_lvl_3}/form`}
                                                    className="button-options-link"
                                                  >
                                                    {default_type !==
                                                      "table" && (
                                                      <span className="material-symbols-outlined dropdown-element-action">
                                                        edit
                                                      </span>
                                                    )}
                                                  </Link>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </Draggable>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Draggable>
                            </div>
                          </div>
                        )
                      )}
                    </Draggable>
                  </div>
                </div>
              );
            }
          )
        )}
      </div>
      {!loading && (
        <Pagination className="pagination-container">
          <Pagination.First onClick={() => onClickPage(1)} />
          <Pagination.Prev
            onClick={() =>
              onClickPage(filterParams?.page > 1 ? filterParams?.page - 1 : 1)
            }
          />
          {items}
          <Pagination.Next
            onClick={() =>
              onClickPage(
                filterParams?.page < metadata?.pagination?.pageCount
                  ? filterParams?.page + 1
                  : metadata?.pagination?.pageCount
              )
            }
          />
          <Pagination.Last
            onClick={() => onClickPage(metadata?.pagination?.pageCount)}
          />
        </Pagination>
      )}

      <ModalConfirmation
        title={t("modalconfirmation.titleDelete")}
        question={t("modalconfirmation.deletequestion", {
          entity: entityName || "this entity", action: "delete"
        })}
        mainAction={modalAction}
        secondaryAction={() => {}}
        mainButton={t("modalconfirmation.yesdelete")}
        secondaryButton={t("modalconfirmation.cancel")}
        handleShow={setShow}
        show={show}
      />
    </div>
  );
};

export default DropdownList;
