import axios, { authHeader } from "../../services/HTTPService";
import * as action from "../reducers/carrouselReducer";
import * as articleAction from "../reducers/articleReducer";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    memberFilters,
  } = {
    fieldTofilter: "title",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    memberFilters: { all: true, members: [], groups: [] },
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/carrousel-slides/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        memberFilters,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setCarrouselList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setCarrouselError({
          error: error?.response?.data?.error?.message,
        })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/carrousel-slides/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setCarrouselDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setCarrouselError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/carrousel-slides", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setCarrousel({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setCarrouselError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/carrousel-slides/${id}`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setCarrousel({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setCarrouselError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/carrousel-slides/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setCarrouselError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const reOrder = async (dispatch, body, filterParams) => {
  return axios
    .put(API_URL + `/api/carrousel-slides/reOrder`, body, { headers: authHeader() })
    .then(({ data }) => {
      console.log('data', filterParams)
      fetchAll(dispatch, filterParams);
    })


    .catch((error) => {
      dispatch(
        articleAction.setArticleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchInitialDuration = async (dispatch) => {
  return axios
    .get(API_URL + `/api/carrousel-slides/time-shown`, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setInitialDuration({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setCarrouselError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};
