import * as action from "../reducers/dropdownReducer";
import axios, { authHeader } from "../../services/HTTPService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  { fieldTofilter, filter, fieldToSort, sortDirection, page, pageSize } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/dropdowns/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setDropdownList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/dropdowns/${id}`, { headers: authHeader() })
    .then(({ data: { data } }) => {
      dispatch(action.setDropdownDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/dropdowns", body, { headers: authHeader() })
    .then(({ data: { data } }) => {
      dispatch(action.setDropdown({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/dropdowns/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setDropdown({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/dropdowns/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchOptionDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/dropdown-options/${id}`, { headers: authHeader() })
    .then(({ data: { data } }) => {
      dispatch(action.setDropdownOptionDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchOptionValueDetails = async (dispatch, option, location) => {
  return axios
    .get(API_URL + `/api/dropdown-option-values/${option}/${location}`, {
      headers: authHeader(),
    })
    .then(({ data: { data } }) => {
      dispatch(action.setDropdownOptionValueDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const orderDropdownsOptions = async (body, dispatch, params) => {
  return axios
    .put(API_URL + "/api/dropdown-options/setOrderOfDropdownsOptions/", body, {
      headers: authHeader(),
    }).then(async () => {
      await fetchAll(dispatch, params);
    });
};

export const orderDropdowns = async (dispatch, body) => {
  return axios
    .put(API_URL + "/api/dropdowns/setOrderOfDropdowns/", body, {
      headers: authHeader(),
    })
    .then((data) => {
      dispatch(action.setOrderDropdowns({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const createOptionValue = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/dropdown-option-values", body, {
      headers: authHeader(),
    })
    .then(({ data: { data } }) => {
      dispatch(action.setDropdownOptionValue({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const updateOptionValue = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/dropdown-option-values/${id}`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setDropdownOptionValue({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchSelectOptions = async (dispatch) => {
  return axios
    .get(API_URL + `/api/dropdowns/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setDropdownList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const deleteDropdownOptionValue = async (dispatch, id) => {
  return axios
    .delete(API_URL + `/api/dropdown-option-values/${id}`, {
      headers: authHeader(),
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchReportCategories = async (dispatch) => {
  return axios
    .get(API_URL + `/api/imported-report/categories`, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setReportCategories({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setDropdownError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};
