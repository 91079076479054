import { createSlice } from "@reduxjs/toolkit";

const notificationReducer = createSlice({
  name: "notification",
  initialState: {
    notificationDetails: null,
    notification: null,
    notificationList: null,
    notificationMetadata: null,
    notificationError: '',
    listLoading: false,
  },
  reducers: {
    setNotificationDetail: (state, action) => {
      state.notificationDetails = action.payload.data;
      state.notificationError = null;
    },
    setNotificationList: (state, action) => {
      state.notificationList = action.payload.data;
      state.notificationError = null;
    },
    setMetadata: (state, action) => {
      state.notificationMetadata = action.payload.meta;
    },
    setNotification: (state, action) => {
      state.notification = action.payload.data;
      state.notificationError = null;
    },
    setNotificationError: (state, action) => {
      state.notificationError = action.payload.error;
    },
    cleanNotificationState: (state) => {
      state = notificationReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setNotificationDetail,
  setNotificationList,
  setNotificationError,
  setNotification,
  setMetadata,
  cleanNotificationState,
  setListLoading,
} = notificationReducer.actions;

export default notificationReducer.reducer;