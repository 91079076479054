import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./modalAddOption.scss";
import { useState } from "react";

const ModalAddOption = ({
  optionModalContext,
  setFieldValue,
  handleShow,
  show,
}) => {
  const [selectedType, setSelectedType] = useState("normal");

  const handleChange = (e) => {
    setSelectedType(e.target.value);
  };

  const generateOption = () => {
    const { fieldName, fieldData } = optionModalContext;

    const newItem = {
      name: "New Second Level Option",
      default_type: {
        value: selectedType === "normal" ? "internal_link" : "table",
        label: selectedType === "normal" ? "Internal Link" : "Table",
      },
      is_visible: true,
      third_level_options: [],
    };

    fieldData.second_level_options = [
      ...fieldData.second_level_options,
      newItem,
    ];

    setFieldValue(fieldName, fieldData);

    handleShow(false);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => handleShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select option type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-option-body">
          <div>
            <div>
              <Form.Check
                type="radio"
                id="radio1"
                bsPrefix="form-check-input-custom"
                label="Link option"
                name="option_type"
                value="normal"
                onChange={handleChange}
                checked={selectedType === "normal"}
              />
              <span className="modal-option-description">
                Direct link to a file, link or report
              </span>
            </div>
            <div>
              <Form.Check
                type="radio"
                id="radio2"
                bsPrefix="form-check-input-custom"
                label="Table option"
                name="option_type"
                value="table"
                onChange={handleChange}
                checked={selectedType === "table"}
              />
              <span className="modal-option-description">
                Redirect to a new page containing a list of options
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleShow(false)}
          className="form-action btn btn-outline-primary"
        >
          Cancel
        </Button>
        <Button
          onClick={generateOption}
          className="form-action form-action-primary btn btn-outline-primary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddOption;
