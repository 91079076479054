import { createSlice } from "@reduxjs/toolkit";

const linkReducer = createSlice({
  name: "link",
  initialState: {
    linkDetails: null,
    link: null,
    linkList: null,
    linkMetadata: null,
    linkError: '',
    listLoading: false,
  },
  reducers: {
    setLinkDetail: (state, action) => {
      state.linkDetails = action.payload.data;
      state.linkError = null;
    },
    setLinkList: (state, action) => {
      state.linkList = action.payload.data;
      state.linkError = null;
    },
    setMetadata: (state, action) => {
      state.linkMetadata = action.payload.meta;
    },
    setLink: (state, action) => {
      state.link = action.payload.data;
      state.linkError = null;
    },
    setLinkError: (state, action) => {
      state.linkError = action.payload.error;
    },
    cleanLinkState: (state) => {
      state = linkReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setLinkDetail,
  setLinkList,
  setLinkError,
  setCreatedLink,
  setEditedLink,
  setMetadata,
  setLink,
  cleanLinkState,
  setListLoading,
} = linkReducer.actions;

export default linkReducer.reducer;