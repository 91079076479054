import { createSlice } from "@reduxjs/toolkit";

const memberOptionsReducer = createSlice({
  name: "memberOptions",
  initialState: {
    memberOptionsDetails: null,
    memberOptions: null,
    memberOptionsError: "",
  },
  reducers: {
    setMemberOptionsError: (state, action) => {
      state.memberOptionsError = action.payload;
    },
    setMemberOptionsDetail: (state, action) => {
      state.memberOptionsDetails = action.payload.data;
      state.memberOptionsError = null;
    },
    setMemberOptions: (state, action) => {
      state.memberOptions = action.payload.data;
      state.memberOptionsError = null;
    },
    cleanMemberOptionsState: (state) => {
      state = memberOptionsReducer.getInitialState();
    },
  },
});

export const {
  setMemberOptionsDetail,
  setMemberOptionsError,
  setMemberOptions,
  cleanMemberOptionsState,
} = memberOptionsReducer.actions;

export default memberOptionsReducer.reducer;
