import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as carrouselAction from "../../../../redux/actions/carrousel.action";
import { useTranslation } from "react-i18next";
import List from "../../../list/list";
import placeholder from "../../../../assets/images/placeholder.jpg";
import { fetchSASToken } from "../../../../services/SASTokenService";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import {
  formatGroupLabel,
  getOnlyMemberAndGroupOptions,
} from "../../../../helpers/select";
import { customStyles, customStylesForTable } from "../../../../helpers/select.custom";

const carrouselFields = [
  //list will sort by the first element by default
  "title",
  "active",
  "description",
  "url",
  "createdAt",
];
const CarrouselList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [memberOptions, setMemberOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const { carrousels, meta, listLoading } = useSelector((state) => ({
    carrousels: state.carrousel.carrouselList,
    meta: state.carrousel.carrouselMetadata,
    listLoading: state.carrousel.listLoading,
  }));

  useEffect(() => {
    carrouselAction.fetchAll(dispatch);
    const fetchToken = async () => {
      setToken(await fetchSASToken());
    };
    fetchToken();
  }, [dispatch]);

  useEffect(() => {
    if (carrousels?.data && carrousels?.data?.length > 0) {
      const options = carrousels?.data?.reduce(
        (obj, { id, attributes: { locations, location_groups } }) => {
          obj[id] = getOnlyMemberAndGroupOptions(location_groups, locations);
          return obj;
        },
        {}
      );
      setMemberOptions(options);
    }
  }, [carrousels]);

  const isAll = (members, member_groups) =>
    !members.data.length && !member_groups.data.length;

  return (
    <List
      showAlert={showAlert}
      setShowAlert={setShowAlert}
      successAlertMessage ={ "Slide duration was successfully updated." }
      sortIsDisabled={false}
      reOrderAction={(body, filterParams) =>{carrouselAction.reOrder(dispatch, body, filterParams) }}
      redirect="/carrousel/form"
      title={t("carrousel.carrousel")}
      isOverview={false}
      tooltipTextDelete="Delete Carrousel"
      columnNames={[
        "",
        "IMAGE",
        "TITLE",
        "STATUS",
        "DESCRIPTION",
        "URL",
        "VISIBLE IN",
      ]}
      hasImage={true}
      entities={carrousels?.data?.map(
        ({
          id,
          attributes: {
            image,
            title,
            active,
            description,
            url,
            locations,
            location_groups,
            order
          },
        }) => ({
          order,
          id,
          image: image?.data?.attributes?.url
            ? `${image?.data?.attributes?.url}?${token}`
            : placeholder,
          columns: [
            { primary: title },
            { primary: active ? "ACTIVE" : "INACTIVE" },
            { primary: description },
            { primary: url },
            {
              primary: isAll(locations, location_groups) ? (
                "All Members"
              ) : (
                <Select
                  theme={(theme) => getTheme(theme)}
                  className="form-select-custom"
                  placeholder="Show"
                  isMulti
                  styles={customStylesForTable}
                  isOptionDisabled={() => true}
                  options={memberOptions[id]}
                  formatGroupLabel={formatGroupLabel}
                  getOptionLabel={(option) => (
                    <div className="form-select-custom-label">
                      <span className="form-select-custom-label-primary">
                        {option.label}
                      </span>
                    </div>
                  )}
                />
              ),
            },
          ],
          name: title,
        })
      )}
      fieldsToSort={carrouselFields}
      fetchAction={(params) => carrouselAction.fetchAll(dispatch, params)}
      metadata={meta}
      deleteAction={(id, params) =>
        carrouselAction.remove(dispatch, id, params)
      }
      loading={listLoading}
    />
  );
};

export default CarrouselList;
