/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as productAction from "../../../../redux/actions/product.action";
import { createMember } from "../../../../redux/actions/member.action";
import { useNavigate } from "react-router-dom";

const Dropdowns = ({ setTab, childToParent, parentToChild, setMemberId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productList, error } = useSelector((state) => ({
    productList: state.product.productList,
    error: state.member.memberError,
  }));

  const initialValues = {
    productsIds: [],
  };

  const formSchema = Yup.object().shape({
    productsIds: Yup.array()
      .required()
      .min(1, "Please select at least one product"),
  });

  const onSubmitForm = (values) => {
    childToParent("product", values);
    createMember(
      dispatch,
      { ...parentToChild, product: values },
      () => setTab("done"),
      setMemberId
    );
  };

  useEffect(() => {
    productAction.fetchAll(dispatch);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
        } = formik;

        return (
          <div className="all-container-member-create ">
            <div className="title-members-create contact-title">
              <h3>Products</h3>
              <label>
                Please select the products this member will have access to
              </label>
              <span className="input-info-error-member">
                {errors.productsIds}
              </span>
              {error && (
                <span className="input-info-error-member" variant="danger">
                  {error}
                </span>
              )}
            </div>
            <Row className="row-dropdowns-member-create">
              <Col className="inputs-dropdowns-container">
                {productList?.length > 0 &&
                  productList?.map(({ id, name }, index) => (
                    <div key={index} className="col-products">
                      <div className="form-check form-switch">
                        <Form.Check
                          onChange={handleChange}
                          onBlur={handleBlur}
                          bsPrefix="form-custom-switch"
                          type="switch"
                          name="productsIds"
                          value={id}
                          id={`${name}-${index}`}
                          label={name}
                        />
                        </div>
                    </div>
                  ))}
              </Col>
            </Row>
            <div className="member-form-action-group">
              <Button
                variant="primary"
                onClick={() => navigate("/members")}
                className="form-action-cancel"
              >
                Cancel
              </Button>
              <div className="form-subgroup">
                <Button
                  onClick={() => {
                    setTab("contact");
                  }}
                  className="form-action"
                  variant="primary"
                >
                  Back
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  className="form-action form-action-primary"
                  variant="outline-primary"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Dropdowns;
