import { React } from 'react';
import { useSelector } from 'react-redux';
import './header.scss';
import {
	Container,
	Button,
	Nav,
	Navbar,
	Popover,
	OverlayTrigger,
	Col,
} from "react-bootstrap";
import UserService from '../../services/UserService';
import { Link } from 'react-router-dom';

const Header = () => {
	const { infoUser } = useSelector((state) => ({
		infoUser: state.user.infoUser
	}));

	// set first name with the first letter in uppercase
	const firstName = infoUser.given_name.charAt(0).toUpperCase() + infoUser.given_name.slice(1);
	// set last name (family_name) with the first letter in uppercase
	const lastName = infoUser.family_name.charAt(0).toUpperCase() + infoUser.family_name.slice(1);
	const nameToDisplay = firstName + ' ' + lastName || infoUser?.preferred_username

	return (
		<div className='header'>
			<Link to={'/'} className='link'>
				<div className='header-logo-container'>
					<h1 className='header-logo'>MIIA</h1>
					<h5 className='header-app'>Admin Portal</h5>
				</div>
			</Link>
			<OverlayTrigger
				trigger="click"
				key={"bottom"}
				placement={"bottom"}
				rootClose={true}
				overlay={
					<Popover id={`popover-positioned-${"bottom"}`} className="popover-box">
						<Popover.Header className="popover-header-user" bsPrefix="body-popover">
							<Link to={`/users`} className="link">
								<p className="option-label">
									Users Administration
								</p>
							</Link>
						</Popover.Header>
						<Popover.Body bsPrefix="body-popover">
							<Button
								bsPrefix="btn button-popover"
								onClick={() => UserService.doLogout()}
							>
								<p className='label-button-logout'>
									Log out
								</p>
							</Button>
						</Popover.Body>
					</Popover>
				}
			>
				<Button className="header-username" bsPrefix="button-profile">
					Hey, {nameToDisplay}
					<span className="header-username-arrow material-symbols-outlined">
						expand_more
					</span>
				</Button>
			</OverlayTrigger>
		</div>
	);
};

export default Header;
