import { createSlice } from "@reduxjs/toolkit";

const memberReducer = createSlice({
  name: "member",
  initialState: {
    memberCreate: null,
    memberDetails: null,
    member: null,
    memberList: null,
    memberFilterList: null,
    memberMetadata: null,
    memberFilterMetadata: null,
    memberTypes: null,
    memberError: "",
    groups: null,
    listLoading: false,
    mapMemberList: null,
    memberSelectList: null,
    carrouselTimeSlide: null,
  },
  reducers: {
    setMemberCreate: (state, action) => {
      state.memberCreate = action.payload;
    },
    setMemberError: (state, action) => {
      state.memberError = action.payload;
    },
    setMemberTypes: (state, action) => {
      state.memberTypes = action.payload;
    },
    setMemberDetail: (state, action) => {
      state.memberDetails = action.payload.data;
      state.memberError = null;
    },
    setMemberList: (state, action) => {
      state.memberList = action.payload.data;
      state.memberError = null;
    },
    setMemberFilterList: (state, action) => {
      state.memberFilterList = action.payload.data;
      state.memberError = null;
    },
    setMemberSelectList: (state, action) => {
      state.memberSelectList = action.payload.data;
      state.memberError = null;
    },
    setMapMemberList: (state, action) => {
      state.mapMemberList = action.payload.data;
      state.memberError = null;
    },
    setMetadata: (state, action) => {
      state.memberMetadata = action.payload.meta;
    },
    setFilterMetadata: (state, action) => {
      state.memberFilterMetadata = action.payload.meta;
    },
    setMember: (state, action) => {
      state.member = action.payload.data;
      state.memberError = null;
    },
    cleanMemberState: (state) => {
      state = memberReducer.getInitialState();
    },
    fetchGroupsSuccess: (state, action) => {
      state.groups = action.payload;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setCarrouselTime: (state, action) => {
      state.carrouselTimeSlide = action.payload.carrouselTimeSlide;
    },
  },
});

export const {
  setMemberCreate,
  setMemberDetail,
  setMemberList,
  setMemberError,
  setMemberTypes,
  setMember,
  setMetadata,
  cleanMemberState,
  fetchGroupsSuccess,
  setListLoading,
  setMemberFilterList,
  setMapMemberList,
  setMemberSelectList,
  setCarrouselTime,
  setFilterMetadata,
} = memberReducer.actions;

export default memberReducer.reducer;
