import { createSlice } from "@reduxjs/toolkit";

const programReducer = createSlice({
  name: "program",
  initialState: {
    programDetails: null,
    program: null,
    programList: null,
    programMetadata: null,
    listLoading: false,
    programError: '',
  },
  reducers: {
    setProgramDetail: (state, action) => {
      state.programDetails = action.payload.data;
      state.programError = null;
    },
    setProgramList: (state, action) => {
      state.programList = action.payload.data;
      state.programError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.programMetadata = action.payload.meta;
    },
    setProgram: (state, action) => {
      state.program = action.payload.data;
      state.programError = null;
    },
    setProgramError: (state, action) => {
      state.programError = action.payload.error;
    },
    cleanProgramState: (state) => {
      state = programReducer.getInitialState();
    },
  },
});

export const {
  setProgramDetail,
  setProgramList,
  setProgramError,
  setCreatedProgram,
  setEditedProgram,
  setMetadata,
  setProgram,
  cleanProgramState,
  setListLoading
} = programReducer.actions;

export default programReducer.reducer;