import * as action from "../reducers/roleReducer";
import axios, { authHeader } from "../../services/HTTPService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  { fieldTofilter, filter, fieldToSort, sortDirection, page, pageSize } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/portal-user-roles/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setRoleList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchAllAdminPortalRoles = async (
  dispatch,
  { fieldTofilter, filter, fieldToSort, sortDirection, page, pageSize } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    // page: 1,
    // pageSize: 8
  }
) => {
  return axios
    .get(API_URL + `/api/admin-portal-roles/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setRoleList({ data }));
      dispatch(action.setMetadata({ meta }));
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/portal-user-roles/${id}`, { headers: authHeader() })
    .then(({ data: { data } }) => {
      dispatch(action.setRoleDetail({ data }));
    })
    .catch((error) => {
      dispatch(action.setRoleDetail({ data: null }));
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchAdminPortalRoleDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/admin-portal-roles/${id}`, { headers: authHeader() })
    .then(({ data: { data } }) => {
      dispatch(
        action.setRoleDetail({ data: { id: data.id, ...data.attributes } })
      );
    })
    .catch((error) => {
      dispatch(action.setRoleDetail({ data: null }));
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/portal-user-roles", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setRole({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/portal-user-roles/${id}`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setRole({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/portal-user-roles/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchSelectOptionsWebPortal = async (dispatch) => {
  return axios
    .get(API_URL + `/api/portal-user-roles/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setRoleList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchSelectOptionsAdminPortal = async (dispatch) => {
  return axios
    .get(API_URL + `/api/admin-portal-roles/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setRoleList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setRoleError({ error: error?.response?.data?.error?.message })
      );
    });
};
