import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as contactAction from "../../../../redux/actions/contact.action";
import { formatDate } from "../../../../helpers/date";
import List from "../../../list/list";
import { useTranslation } from "react-i18next";

const contactFields = [
  //list will sort by the first element by default
  "full_name",
  "department",
  "contact_role.title",
  "email",
  "phone_number",
  "publishedAt",
];

const ContactList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { contacts, meta, listLoading } = useSelector((state) => ({
    contacts: state.contact.contactList,
    meta: state.contact.contactMetadata,
    listLoading: state.contact.listLoading,
  }));

  useEffect(() => {
    contactAction.fetchAll(dispatch);
  }, [dispatch]);

  return (
    <List
      redirect="/contacts/form"
      tooltipTextDelete="Delete Contact"
      title={t("contacts.contacts")}
      isOverview={false}
      columnNames={[
        "POSITION",
        "FULL NAME",
        "DEPARTMENT",
        "EMAIL",
        "PHONE NUMBER",
        "LAST UPDATED",
      ]}
      entities={contacts?.data?.map(
        ({
          id,
          attributes: {
            contact_role,
            full_name,
            department,
            email,
            phone_number,
            updatedAt,
          },
        }) => ({
          id,
          columns: [
            { primary: contact_role?.data?.attributes?.title },
            { primary: full_name },
            { primary: department },
            { primary: email },
            { primary: phone_number },
            { primary: formatDate(updatedAt) },
          ],
          name: full_name,
        })
      )}
      fieldsToSort={contactFields}
      fetchAction={(params) => contactAction.fetchAll(dispatch, params)}
      metadata={meta}
      deleteAction={(id, params) => contactAction.remove(dispatch, id, params)}
      loading={listLoading}
    />
  );
};

export default ContactList;
