import { Formik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import InputGroup from "react-bootstrap/InputGroup";
import "./webPortalOptions.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import * as memberAction from "../../../redux/actions/member.action";

const WebPortalOptions = ({ member }) => {
  const { t } = useTranslation();
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const dispatch = useDispatch();
  const { entityId } = useParams();

  const { memberDetails, memberOptions, error } = useSelector((state) => ({
    memberDetails: state.member.memberDetails,
    memberOptions: state.memberOptions.memberOptions,
    error: state.memberOptions.memberOptionsError,
  }));

  const initialValues = {
    title: memberDetails?.portal_title || `${memberDetails?.name} Web Portal`,
    url: memberDetails?.uniqueNonGuessableId,
    portal_visibility:
      memberDetails?.portal_visibility === false ? false : true,
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    portal_visibility: Yup.boolean(),
  });

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && memberOptions) {
      setRenderSuccessAlert(true);
    }
  }, [memberOptions, submittedForm]);

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    let { title, portal_visibility } = values;

    const formData = new FormData();

    const formBody = {
      portal_title: title,
      portal_visibility,
    };

    formData.append("location", JSON.stringify(formBody));

    await memberAction.update(dispatch, entityId, formData);

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(`www.miiamemberportal.org/${text}` || "www.miiamemberportal.org");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
        } = formik;

        return (
          <div className="portal-options">
            <div className="portal-options-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Member Options edited successfully.`}
                </Alert>
              )}
              <div className="portal-options-header">
                <h1 className="portal-options-title">Web Portal Options</h1>
              </div>
              <Form>
                <div className="portal-options-form">
                  <div>
                    <Form.Label>Title</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <InputGroup.Text id="basic-addon2">
                        | MIIA
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </div>
                  <div>
                    <Form.Label>URL</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        www.miiamemberportal.org
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.url && touched.url}
                        style={{ width: "22em" }}
                        disabled
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.url}
                    </Form.Control.Feedback>
                  </div>
                  <div style={{paddingTop: "1em"}}>
                    <Button className="copy-clipboard-button" onClick={() => copyTextToClipboard(values.url)}>
                      <span class="material-symbols-outlined">
                        content_copy
                      </span>
                    </Button>
                  </div>
                  <div>
                    <Form.Check
                      type="switch"
                      id="portal_visibility"
                      label={
                        <span>
                          Portal Visibility:
                          <strong>{`${
                            values.portal_visibility ? "Enabled" : "Disabled"
                          }`}</strong>
                        </span>
                      }
                      name="portal_visibility"
                      value="portal_visibility"
                      onChange={handleChange}
                      bsPrefix="form-custom-switch"
                      checked={values.portal_visibility}
                    />
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default WebPortalOptions;
