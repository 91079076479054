import { useState, useEffect } from "react";
import { matchPath } from "react-router-dom";

export const useMatchAny = (paths) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const match = paths.some((path) =>
      matchPath({ path }, window.location.pathname)
    );
    setMatches(match);
  }, [paths]);

  return matches;
};