import { useSelector } from "react-redux";
import CheckingCredentials from "../components/views/checkingcredentials/CheckingCredentials";

const PrivateRoute = ({ children }) => {
    const { loginStatus } = useSelector((state) => ({
        loginStatus: state.user.loginStatus
      }));
    return loginStatus ? children : <CheckingCredentials />
}


export default PrivateRoute