import "./CheckingCredentials.css";

const CheckingCredentials = () => {
  return (
    <div className="container-fluid-loading-page">
      <img
        src="https://miiaprod.blob.core.windows.net/miia-prod-public/MIIA-0238-MIIA_Member-Portal-Sublogo_no-icon.svg"
        alt="MIIA Logo"
        style={{
          height: "4.69rem",
          width: "11.4rem",
        }}
      />
      <p className="checking">Checking Credentials</p>
      <div className="loader"></div>
    </div>
  );
};

export default CheckingCredentials;
