// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-tab-container {
  flex: 1 1;
  margin-inline: 2em;
  padding: 2em;
  border-radius: 1em;
  background-color: #fff;
  overflow: scroll;
  max-height: 100%;
  margin-top: 1em;
}

.member-form-action-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1em;
}

.member-tab-title {
  font-weight: bold;
  font-size: 1.3em;
}

.member-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.member-tab-delete-action {
  color: #777776;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/views/members/memberTabs/memberTabs.scss","webpack://./src/sass/globals/globals.scss","webpack://./src/sass/globals/mixins.scss"],"names":[],"mappings":"AAGA;EACC,SAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBCJO;EDKP,gBAAA;EACA,gBAAA;EACA,eAAA;AAFD;;AAKA;EEbC,aAAA;EACA,8BFac;EEZd,mBFY6B;EAC7B,WAAA;EACA,QAAA;AAAD;;AAGA;EACC,iBAAA;EACA,gBAAA;AAAD;;AAGA;EExBC,aAAA;EACA,8BFwBc;EEvBd,mBFuB6B;EAC7B,WAAA;AAED;;AACA;EACC,cCxBW;EDyBX,eAAA;AAED","sourcesContent":["@import '../../../../sass/globals/globals.scss';\n@import '../../../../sass/globals/mixins.scss';\n\n.member-tab-container {\n\tflex: 1;\n\tmargin-inline: 2em;\n\tpadding: 2em;\n\tborder-radius: 1em;\n\tbackground-color: $white;\n\toverflow: scroll;\n\tmax-height: 100%;\n\tmargin-top: 1em;\n}\n\n.member-form-action-group {\n\t@include flex(space-between, center);\n\twidth: 100%;\n\tgap: 1em;\n}\n\n.member-tab-title{\n\tfont-weight: bold;\n\tfont-size: 1.3em;\n}\n\n.member-header-main {\n\t@include flex(space-between, center);\n\twidth: 100%;\n}\n\n.member-tab-delete-action {\n\tcolor: $dark-grey;\n\tcursor: pointer;\n}","$dark-red: #8f003c;\n$sidebar-selected-color: #c4145d;\n$sidebar-logo-color: #a53363;\n$logo-color: #920942;\n$white: #fff;\n$grey: #f3f6f9;\n$grey2: #e2e5e7;\n$dark-grey: #777776;\n$font-color: #fff;\n$shadow-color: #4e7da2;\n$font: 'Rubik';\n","@mixin flex($justify-content, $align-items) {\n\tdisplay: flex;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n\n@mixin flexColumn($justify-content, $align-items) {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
