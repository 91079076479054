import { createSlice } from "@reduxjs/toolkit";

const recipeReducer = createSlice({
  name: "recipe",
  initialState: {
    recipeDetails: null,
    recipe: null,
    recipeList: null,
    recipeMetadata: null,
    listLoading: false,
    recipeError: '',
  },
  reducers: {
    setRecipeDetail: (state, action) => {
      state.recipeDetails = action.payload.data;
      state.recipeError = null;
    },
    setRecipeList: (state, action) => {
      state.recipeList = action.payload.data;
      state.recipeError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.recipeMetadata = action.payload.meta;
    },
    setRecipe: (state, action) => {
      state.recipe = action.payload.data;
      state.recipeError = null;
    },
    setRecipeError: (state, action) => {
      state.recipeError = action.payload.error;
    },
    cleanRecipeState: (state) => {
      state = recipeReducer.getInitialState();
    },
  },
});

export const {
  setRecipeDetail,
  setRecipeList,
  setRecipeError,
  setCreatedRecipe,
  setEditedRecipe,
  setMetadata,
  setRecipe,
  cleanRecipeState,
  setListLoading
} = recipeReducer.actions;

export default recipeReducer.reducer;