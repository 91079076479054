// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal-options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
}
.portal-options-title {
  font-size: 1.5em;
  font-weight: bold;
}
.portal-options-container {
  flex: 1 1;
  margin-top: 2em;
  margin-inline: 2em;
  padding: 2em;
  border-radius: 1em;
  background-color: #fff;
}
.portal-options-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
.portal-options-form .form-control {
  outline: none;
  box-shadow: none;
}
.portal-options-form .form-control:focus {
  border-color: #8f003c;
  box-shadow: none;
}
.portal-options-form .form-custom-switch {
  padding-top: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/views/webPortalOptions/webPortalOptions.scss","webpack://./src/sass/globals/mixins.scss","webpack://./src/sass/globals/globals.scss"],"names":[],"mappings":"AAGA;ECFC,aAAA;EACA,uBDEc;ECDd,uBDCsB;EACtB,SAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;AAAD;AAEC;EACC,gBAAA;EACA,iBAAA;AAAF;AAGC;EACC,SAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBEjBM;AFgBR;AAIC;ECvBA,aAAA;EACA,2BDuBe;ECtBf,mBDsB2B;EAC1B,QAAA;AAAF;AAEE;EACC,aAAA;EACA,gBAAA;AAAH;AAGE;EACC,qBElCQ;EFmCR,gBAAA;AADH;AAIE;EACC,gBAAA;AAFH","sourcesContent":["@import '../../../sass/globals/globals.scss';\n@import '../../../sass/globals/mixins.scss';\n\n.portal-options {\n\t@include flex(center, flex-start);\n\tflex: 1;\n\theight: 100%;\n\tbox-sizing: border-box;\n\toverflow: scroll;\n\n\t&-title {\n\t\tfont-size: 1.5em;\n\t\tfont-weight: bold;\n\t}\n\n\t&-container {\n\t\tflex: 1;\n\t\tmargin-top: 2em;\n\t\tmargin-inline: 2em;\n\t\tpadding: 2em;\n\t\tborder-radius: 1em;\n\t\tbackground-color: $white;\n\t}\n\n\t&-form {\n\t\t@include flex(flex-start, center);\n\t\tgap: 1em;\n\n\t\t.form-control{\n\t\t\toutline: none;\n\t\t\tbox-shadow: none;\n\t\t}\n\n\t\t.form-control:focus {\n\t\t\tborder-color: $dark-red;\n\t\t\tbox-shadow: none;\n\t\t}\n\n\t\t.form-custom-switch{\n\t\t\tpadding-top: 1em;\n\t\t}\n\t}\n}\n","@mixin flex($justify-content, $align-items) {\n\tdisplay: flex;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n\n@mixin flexColumn($justify-content, $align-items) {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n","$dark-red: #8f003c;\n$sidebar-selected-color: #c4145d;\n$sidebar-logo-color: #a53363;\n$logo-color: #920942;\n$white: #fff;\n$grey: #f3f6f9;\n$grey2: #e2e5e7;\n$dark-grey: #777776;\n$font-color: #fff;\n$shadow-color: #4e7da2;\n$font: 'Rubik';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
