import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";
import General from "./tabs/general";
import Type from "./tabs/type";
import Contact from "./tabs/contact";
import Products from "./tabs/products";
import Done from "./tabs/done";
import { useEffect } from 'react';

const MembersCreate = () => {
  const [tab, setTab] = useState("general");
  const [member, setMember] = useState([]);
  const [ memberId, setMemberId ] = useState(null);

  const childToParent = (key, data) => {
    setMember(prev => ({...prev, [key]: data}));
  };

  return (
    <div className="member-container member-tab-container all-container-member-create">
      <Tabs
        justify={true}
        activeKey={tab}
        onSelect={(k) => setTab(k)}
        transition={true}
      >
        <Tab disabled eventKey="general" title="General">
          <General
            childToParent={childToParent}
            tab={tab}
            setTab={setTab}
          />
        </Tab>
        <Tab disabled ref="type" eventKey="type" title="Type">
          <Type childToParent={childToParent} tab={tab} setTab={setTab} />
        </Tab>
        <Tab disabled ref="contact" eventKey="contact" title="Contact">
          <Contact childToParent={childToParent} tab={tab} setTab={setTab} />
        </Tab>
        <Tab disabled ref="products" eventKey="product" title="Products">
          <Products setMemberId={setMemberId} parentToChild={member} childToParent={childToParent} tab={tab} setTab={setTab} />
        </Tab>
        <Tab disabled ref="done" eventKey="done" title="Done">
          <Done memberId={memberId} parentToChild={member} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default MembersCreate;
