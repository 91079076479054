import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./OvewviewInfo.scss";

export default function OverviewInfo({
  memberMetadata,
  notificationMetadata,
  userMetadata,
}) {
  const { t } = useTranslation();

  return (
    <div className="box-container">
      <div className="box-data">
        <div className="rounded">
          <span className="material-symbols-outlined overview-icon">
            group
          </span>
        </div>
        <p className="box-data-title">
          {memberMetadata?.pagination?.total} {t("overview.members")}
        </p>
        <Link to="/members/form" className="action-box">
          <p className="action-box">{t("overview.createnewmember")}</p>
        </Link>
      </div>
      <div className="box-data">
        <div className="rounded">
          <span className="material-symbols-outlined overview-icon">
            person
          </span>
        </div>
        <p className="box-data-title">
          {userMetadata?.pagination?.total} {t("overview.portalusers")}
        </p>
      </div>
      <div className="box-data">
        <div className="rounded">
          <span className="material-symbols-outlined overview-icon">
            notifications
          </span>
        </div>
        <p className="box-data-title">
          {notificationMetadata?.pagination?.total}{" "}
          {t("overview.notifications")}
        </p>
        <Link to="/notifications/form" className="action-box">
          {t("overview.createanewnotification")}
        </Link>
      </div>
    </div>
  );
}
