import "./roleForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as dropdownAction from "../../../../redux/actions/dropdown.action";
import Alert from "react-bootstrap/Alert";
import { useParams, useNavigate } from "react-router-dom";
import * as roleAction from "../../../../redux/actions/role.action";
import { useTranslation } from "react-i18next";
import UserService from "../../../../services/UserService";
import { constants } from "../../../../helpers/constants";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";

const RoleForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const checkboxRef = useRef([]);
  const allCheckRef = useRef();
  const { t } = useTranslation();
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showExitModal, setShowExitModal] = useState(false);

  const { roleDetails, dropdownList, role, error } = useSelector((state) => ({
    roleDetails: state.role.roleDetails,
    dropdownList: state.dropdown.dropdownList,
    role: state.role.role,
    error: state.role.roleError,
  }));

  const initialValues = {
    name: entityId ? roleDetails?.name || "" : "",
    description: entityId ? roleDetails?.description || "" : "",
    sections_and_reports: entityId
      ? roleDetails?.sections_and_reports?.map(({ id }) => `${id}`) || ""
      : [],
    portal_user_administration: entityId
      ? roleDetails?.portal_user_administration || false
      : false,
    sso_access_portal_user: entityId
      ? roleDetails?.sso_access_portal_user || []
      : [],
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required field"),
    description: Yup.string(),
  });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      name,
      description,
      sections_and_reports,
      portal_user_administration,
      sso_access_portal_user,
    } = values;

    const formBody = {
      name,
      description,
      sections_and_reports,
      portal_user_administration,
      sso_access_portal_user,
      published: true,
    };

    formData.append("data", JSON.stringify(formBody));

    if (entityId) {
      await roleAction.update(dispatch, entityId, formData);
    } else {
      await roleAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    if (entityId) {
      //If id is passed as a parameter then load initial values
      roleAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    dropdownAction.fetchSelectOptions(dispatch);
  }, []);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && role) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, role, submittedForm, navigate]);

  useEffect(() => {
    checkboxRef.current.forEach((ref) => {
      ref.checked = selectedAll;
    });
  }, [selectedAll]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          setFieldValue,
          touched,
          handleBlur,
          resetForm,
        } = formik;

        return (
          <div className="roles">
            <div className="roles-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Role ${entityId ? "edited" : "created"} successfully.`}
                </Alert>
              )}
              <div className="roles-header">
                <h1 className="roles-title">
                  {entityId ? "Edit Role" : "New Role"}
                </h1>
              </div>
              <Form className="roles-form">
                <div className="roles-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.name && touched.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <span className="roles-access-label">Access settings</span>
                  <div className="form-row">
                    <div className="roles-access-col">
                      <div className="roles-sr-check-group-col-header">
                        <span>SECTIONS AND REPORTS</span>
                        <div className="check-group-line"></div>
                        <Form.Check
                          id="all"
                          bsPrefix="form-check-input-custom"
                          label="Select All"
                          name="sections_and_reports"
                          value={0}
                          ref={allCheckRef}
                          onChange={(e) => {
                            setFieldValue(
                              "sections_and_reports",
                              !selectedAll
                                ? dropdownList?.data?.map(({ id }) => `${id}`)
                                : []
                            );
                            setSelectedAll((prev) => !prev);
                          }}
                        />
                      </div>
                      <div className="roles-check-group">
                        <div className="roles-sr-check-group-col">
                          {dropdownList?.data?.map(
                            ({ id, attributes: { name } }, index) => (
                              <Form.Check
                                id={id}
                                key={index}
                                bsPrefix="form-check-input-custom"
                                checked={values.sections_and_reports.includes(
                                  id.toString()
                                )}
                                ref={(element) => {
                                  checkboxRef.current[index] = element;
                                }}
                                label={name}
                                name="sections_and_reports"
                                value={id}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="roles-access-col">
                      <span>USERS ADMINISTRATION</span>
                      <div className="roles-check-group-col">
                        <Form.Check
                          id="editandcreateusers"
                          label="Edit and create users"
                          bsPrefix="form-check-input-custom"
                          name="portal_user_administration"
                          value="editandcreateusers"
                          checked={values.portal_user_administration}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="roles-access-col">
                      <span>SSO ACCESS</span>
                      <div className="roles-check-group-col">
                        <Form.Check
                          id="emiia"
                          label="emiia.org"
                          bsPrefix="form-check-input-custom"
                          name="sso_access_portal_user"
                          value="A"
                          checked={values.sso_access_portal_user?.includes("A")}
                          onChange={handleChange}
                        />
                        <Form.Check
                          id="utmc"
                          label="UTMC"
                          bsPrefix="form-check-input-custom"
                          name="sso_access_portal_user"
                          value="B"
                          checked={values.sso_access_portal_user?.includes("B")}
                          onChange={handleChange}
                        />
                        <Form.Check
                          id="webportal"
                          label="Web portal"
                          bsPrefix="form-check-input-custom"
                          name="sso_access_portal_user"
                          value="C"
                          disabled
                          checked={true}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default RoleForm;
