import * as action from "../reducers/bulkReducer";
import axios, { authHeader } from "../../services/HTTPService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
	dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    memberFilters,
  } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    memberFilters: { all: true, members: [], groups: [] },
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/bulk-actions/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        memberFilters,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setBulkList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setBulkError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/bulk-actions/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setBulkDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setBulkError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/bulk-actions", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setBulk({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setBulkError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/bulk-actions/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setBulk({ data }));
		})
    .catch((error) => {
      dispatch(
        action.setBulkError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/bulk-actions/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setBulkError({ error: error?.response?.data?.error?.message })
      );
    });
};
