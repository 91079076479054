import "./bulkActionForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import FileInput from "../../../fileInput/fileInput";
import * as productAction from "../../../../redux/actions/product.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from "../../../../helpers/select.custom";
import { getTheme } from "../../../../helpers/select.theme";
import Select from "react-select";
import * as bulkAction from "../../../../redux/actions/bulk.action";
import { downloadTemplateFile } from "../../../../helpers/files";

const mapTypeToLabel = {
  button_in_home: "Button In Home",
  quick_access_link: "Quick Access Link"
}

const BulkActionForm = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const dispatch = useDispatch();
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const { t } = useTranslation();
  const { bulk, bulkDetails, error, productList } = useSelector((state) => ({
    productList: state.product.productList,
    bulkDetails: state.bulk.bulkDetails,
    bulk: state.bulk.bulk,
    error: state.bulk.bulkError,
  }));
  const [showExitModal, setShowExitModal] = useState(false);

  const initialValues = {
    name: entityId ? bulkDetails?.name || "" : "",
    description: entityId ? bulkDetails?.description || "" : "",
    type: entityId ? { value: bulkDetails?.type, label: mapTypeToLabel[bulkDetails?.type]} || {} : {},
    link_name: entityId ? bulkDetails?.link_name || "" : "",
    product_operator:
      entityId ? bulkDetails?.product_operator || "" : "and",
    product:
      entityId
        ? bulkDetails?.products?.map((product) => ({
            value: product.id,
            label: product.name,
          }))
        : [],
    order: entityId ? bulkDetails?.order ?? "" : "",
    file: entityId ? bulkDetails?.file || "" : "",
  };

  const VALID_FILE_TYPES = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "csv",
  ];

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required field"),
    description: Yup.string().required("Required field"),
    type: Yup.mixed().required("Required field"),
    link_name: Yup.string().required("Required field"),
    product: Yup.mixed(),
    product_operator: Yup.string(),
    order: Yup.number()
      .required("Required field")
      .typeError("Order must be of type 'Number'"),
    file: Yup.mixed().required("Required field").test(
      "is-correct-format",
      "Invalid file extension",
      (value, _) =>
        VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
    ),
  });

  useEffect(() => {
    productAction.fetchSelectOptions(dispatch);
    if (entityId) {
      bulkAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && bulk) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, bulk, submittedForm, navigate]);

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    let {
      type,
      name,
      link_name,
      description,
      product,
      product_operator,
      order,
      file,
    } = values;

    const formData = new FormData();

    const formBody = {
      name,
      link_name,
      description,
      type: type.value,
      order,
      products: product?.map((product) => product.value),
      product_operator,
    };

    formData.append("bulk", JSON.stringify(formBody));
    formData.append("file", file);
    
    if (entityId) {
      await bulkAction.update(dispatch, entityId, formData);
    } else {
      await bulkAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          resetForm,
        } = formik;

        return (
          <div className="bulk">
            <div className="bulk-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Bulk operation finished successfully.`}
                </Alert>
              )}
              <div className="bulk-header">
                <h1 className="bulk-title">{"Bulk Operation"}</h1>
              </div>
              <Form className="bulk-form">
                <div className="bulk-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.name && touched.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.description && touched.description
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Select Entity Type</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        styles={customStyles}
                        value={values.type}
                        isDisabled={!!entityId}
                        onChange={(option) => setFieldValue("type", option)}
                        closeMenuOnSelect={true}
                        onBlur={handleBlur}
                        options={[
                          { value: "button_in_home", label: "Button In Home" },
                          {
                            value: "quick_access_link",
                            label: "Quick Access Link",
                          },
                        ]}
                      />
                      {touched.type && (
                        <span className="input-info-error">{errors.type}</span>
                      )}
                    </div>
                    <div className="form-input">
                      <Form.Label>Link Name</Form.Label>
                      <Form.Control
                        name="link_name"
                        value={values.link_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.link_name && touched.link_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.link_name}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div style={{ width: "100%", marginRight: "1em" }}>
                      <div className="form-input">
                        <Form.Label>Required Products</Form.Label>
                        <Select
                          theme={(theme) => getTheme(theme)}
                          className="form-select-custom bulk-select"
                          value={values.product}
                          isMulti
                          styles={customStyles}
                          onChange={(option) =>
                            setFieldValue("product", option)
                          }
                          closeMenuOnSelect={true}
                          onBlur={handleBlur}
                          options={productList?.data?.map(
                            ({ id, attributes: { name } }) => ({
                              value: id,
                              label: name,
                            })
                          )}
                          isInvalid={errors.product && touched.product}
                        />
                        <span className="input-info-error">
                          {errors.product}
                        </span>
                      </div>
                      <div className="bulk-operator-container">
                        <div className="bulk-operator">
                          <span
                            className={
                              values.product_operator === "or"
                                ? "bulk-operator-selected"
                                : undefined
                            }
                          >
                            OR
                          </span>
                          <Form.Check
                            bsPrefix="form-custom-switch"
                            type="switch"
                            value={values.product_operator}
                            onChange={() => {
                              setFieldValue(
                                "product_operator",
                                values.product_operator === "or" ? "and" : "or"
                              );
                            }}
                            onBlur={handleBlur}
                            id="product_operator"
                            name="product_operator"
                            checked={values.product_operator !== "or"}
                          ></Form.Check>
                          <span
                            className={
                              values.product_operator !== "or"
                                ? "bulk-operator-selected"
                                : undefined
                            }
                          >
                            AND
                          </span>
                          <span className="bulk-operator-feedback">{`Link will be displayed if the member purchased ${
                            values.product_operator === "or" ? "any" : "all"
                          } of the selected products`}</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-input">
                      <Form.Label>Order</Form.Label>
                      <Form.Control
                        name="order"
                        value={values.order}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.order && touched.order}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.order}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="bulk-step-container">
                    <div className="bulk-step">
                      <span>Step 1: Download Template</span>
                      <Button
                        className="form-action form-action-primary"
                        onClick={() => downloadTemplateFile()}
                      >
                        Download Template
                      </Button>
                    </div>
                    <div className="bulk-step">
                      <span>
                        Step 2: Fill Data & Upload (Do not modify column names)
                      </span>
                      <div className="form-input">
                        <FileInput
                          title="Browse a File"
                          allowedTypes={"XLSX"}
                          value={values.file}
                          setFieldValue={setFieldValue}
                          errorMessage={errors}
                          showDescripton={false}
                          allowDownload={!!entityId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        navigate(`/bulk`);
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("bulks.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("bulks.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default BulkActionForm;
