import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as programAction from "../../../../redux/actions/program.action";
import List from "../../../list/list";
import { fetchSASToken } from "../../../../services/SASTokenService";
import { formatDate } from "../../../../helpers/date";
import { useTranslation } from "react-i18next";
import placeholder from "../../../../assets/images/placeholder.jpg";

const programFields = [
  //list will sort by the first element by default
  "name",
  "title",
  "order"
];

const ProgramList = ({ type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [updatedPrograms, setUpdatedPrograms] = useState([]);

  const { programs, meta, listLoading } = useSelector((state) => ({
    programs: state.program.programList,
    meta: state.program.programMetadata,
    listLoading: state.program.listLoading,
  }));

  useEffect(() => {
    programAction.fetchAll(dispatch, type);
    const fetchToken = async () => {
      const newToken = await fetchSASToken();
      setToken(newToken);
    };
    fetchToken();
  }, [dispatch, type]);


  useEffect(() => {
    if (programs?.data && programs?.data?.length > 0 && token) {
      setUpdatedPrograms(
programs?.data?.map(
        ({ id, attributes: { image, name, title, description, createdAt,order, tooltip } }) => { 
          const columns = [{ primary: name, secondary: title}, {primary: title}, { primary: description?.substring(0, 50) + '...' }];
          return {
          id,
          image: 
image?.data? `${
              image?.data?.attributes?.url}?${token}`
            : placeholder,
          columns,
          name,
          order,
          title,
          description: description?.substring(0, 50) + '...' || "",
        } }
      )
      );
    }
  }
  , [programs, token, dispatch]);

  const columnsNames = ["","IMAGE", "NAME", "TITLE", "DESCRIPTION"];

  return (
    <List
      tooltipTextDelete="Delete Program"
      title={t("programs.programs")}
      redirect={`/${type.toLowerCase()}programs/form`}
      columnNames={columnsNames}
      hasImage={true}
      entities={updatedPrograms}
      fieldsToSort={programFields}
      fetchAction={(params) => programAction.fetchAll(dispatch, type, params)}
      isOverview={false}
      metadata={meta}
      showFilterTab={false}
      deleteAction={(id, params) => programAction.remove(dispatch, id, params, type)}
      loading={listLoading}
      sortIsDisabled={false}
      reOrderAction={(body, filterParams) =>{programAction.reOrder({ dispatch, body, filterParams, type }) }}
    />
  );
};

export default ProgramList;
