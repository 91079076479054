import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as tagGroupAction from "../../../../redux/actions/tagGroup.action";
import List from "../../../list/list";
import { useTranslation } from "react-i18next";

const tagGroupFields = [
  //list will sort by the first element by default
  "title",
  "publishedAt",
];

const TagGroupList = () => {
  const dispatch = useDispatch();
	const { t } = useTranslation()

  const { tagGroups, meta, listLoading } = useSelector((state) => ({
    tagGroups: state.tagGroup.tagGroupList,
    meta: state.tagGroup.tagGroupMetadata,
    listLoading: state.tagGroup.listLoading,
  }));

  useEffect(() => {
    tagGroupAction.fetchAll(dispatch);
  }, [dispatch]);

  return (
    <List
      title={t('tags.tagGroups')}
      redirect="/tag-groups/form"
      tooltipTextDelete="Delete Tag Group"
      columnNames={["TITLE"]}
      entities={tagGroups?.data?.map(({ id, attributes: { title } }) => ({
        id,
        columns: [{ primary: title }],
        name: title,
      }))}
      showFilterTab={false}
      fieldsToSort={tagGroupFields}
      fetchAction={(params) => tagGroupAction.fetchAll(dispatch, params)}
      isOverview={false}
      metadata={meta}
      deleteAction={(id, params) => tagGroupAction.remove(dispatch, id, params)}
      loading={listLoading}
    />
  );
};

export default TagGroupList;
