import * as action from "../reducers/userReducer";
import axios, { authHeader } from "../../services/HTTPService";
import { fetchAllAdminPortalRoles } from "./role.action";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchAllPortalUsers = async (
  dispatch,
  {
    includeLocationName = false,
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    backOfficeUser,
    member,
  } = {
    fieldTofilter: "full_name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/miia-users/searchBackofficePortalUsers`, {
      headers: authHeader(),
      params: {
        includeLocationName,
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        backOfficeUser,
        member,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setUserList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchAllAdminPortalUsers = async (
  dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    backOfficeUser,
    member,
  } = {
    fieldTofilter: "full_name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/miia-users/searchBackofficeAdminPortal`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        backOfficeUser,
        member,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setUserList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchPortalUserDetails = async (dispatch, id, getEnabledStatus = false) => {
  return axios
    .get(API_URL + `/api/miia-users/findOnePortalUser/${id}${getEnabledStatus ? "?getEnabledStatus=true" : ""}`, {
      headers: authHeader(),
    })
    .then(({ data: { data } }) => {
      dispatch(action.setUserDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchBackofficeUserDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/miia-users/findOneBackOfficeUser/${id}`, {
      headers: authHeader(),
    })
    .then(({ data: { data } }) => {
      dispatch(action.setUserDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const createBackofficeUser = async (dispatch, body) => {
  return axios
    .post(
      API_URL + "/api/miia-users/createBackOfficeUser",
      { data: body },
      { headers: authHeader() }
    )
    .then(({ data }) => {
      dispatch(action.setUser({ data }));
      dispatch(
        action.setUserError({ error: "" })
      );
    })
    .catch((error) => {
      dispatch(action.setUser({}));
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const createPortalUser = async (dispatch, body) => {
  return axios
    .post(
      API_URL + "/api/miia-users/createPortalUser",
      { data: body },
      { headers: authHeader() }
    )
    .then(({ data }) => {
      dispatch(action.setUser({ data }));
      dispatch(
        action.setUserError({ error: "" })
      );
    })
    .catch((error) => {
      dispatch(action.setUser({}));
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const updateBackofficeUser = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/miia-users/updateBackOfficeUser/${id}`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setUser({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const updatePortalUser = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/miia-users/updatePortalUser/${id}`, body, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setUser({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const removeBackofficeUser = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/miia-users/deleteBackOfficeUser/${id}`, {
      headers: authHeader(),
    })
    .then(async () => {
      await fetchAllAdminPortalRoles(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const blockPortalUser = async ({ dispatch, id, params, setSuccessMessage }) => {
  return axios
    .delete(API_URL + `/api/miia-users/deletePortalUser/${id}`, {
      headers: authHeader(),
    })
    .then(async () => {
      setSuccessMessage("User disabled successfully");
      await fetchAllPortalUsers(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const requestPasswordReset = async ({
  dispatch,
  email,
  setSuccessMessage,
  setRenderSuccessAlert,
  setDisableRequestPassword,
}) => {
  return axios
    .put(
      API_URL + `/api/miia-users/requestPasswordReset`,
      { data: { email } },
      {
        headers: authHeader(),
      }
    )
    .then(({ data }) => {
      setSuccessMessage(
        "An email has been sent to the user with instructions to reset the password"
      );
      setRenderSuccessAlert(true);
      setDisableRequestPassword(false)
      clearErrors(dispatch);
      return data;
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
      setDisableRequestPassword(false)
    });
};

export const unblockUser = async ({
  dispatch,
  keycloakId,
  strapiId,
  setSuccessMessage,
  setRenderSuccessAlert,
  redirectToList = false,
  params
}) => {
  return axios
    .put(
      API_URL + `/api/miia-users/unblockUser/${keycloakId}`,
      {},
      {
        headers: authHeader(),
      }
    )
    .then(async () => {
      await fetchPortalUserDetails(dispatch, strapiId, true);
      setSuccessMessage("User enabled successfully");
      if (redirectToList) await fetchAllPortalUsers(dispatch, params);
      clearErrors(dispatch);
      setRenderSuccessAlert(true);
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const hardDeleteUser = async ({
  dispatch,
  userEmail,
  setHardDeleteWasSuccessful
}) => {
  return axios
    .delete(API_URL + `/api/miia-users/hardDeletePortalUser/${userEmail}`, {
      headers: authHeader(),
    })
    .then(async () => {
      dispatch(action.setUserHardDeleted({ userHardDeleted: true }));
      setHardDeleteWasSuccessful(true);
    })
    .catch((error) => {
      dispatch(
        action.setUserError({ error: error?.response?.data?.error?.message })
      );
    });
}

export const clearErrors = (dispatch) => {
  dispatch(action.setUserError({ error: "" }));
};