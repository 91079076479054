import axios, { authHeader } from "../../services/HTTPService";
import * as action from "../reducers/productReducer";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  { fieldTofilter, filter, fieldToSort, sortDirection, page, pageSize } = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  return axios
    .get(API_URL + `/api/products`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
      },
    })
    .then(({ data: { data, meta } }) => {
      dispatch(action.setProductList({ data }));
      dispatch(action.setMetadata({ meta }));
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/products/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setProductDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const create = async (dispatch, body) => {
  let config = {
    method: "post",
    url: `${API_URL} + /api/products`,
    headers: authHeader(),
    data: body,
  };

  return axios(config)
    .then(({ data }) => {
      dispatch(action.setProduct({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const update = async (dispatch, id, body) => {
  let config = {
    method: "put",
    url: API_URL + `/api/products/${id}`,
    headers: authHeader(),
    data: body,
  };

  return axios(config)
    .then(({ data }) => {
      dispatch(action.setProduct({ data }));
      fetchAll(dispatch);
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/products/${id}`, { headers: authHeader() })
    .then(async (response) => {
      dispatch(action.setProductDeleted({ data: response?.data }));
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchPurchasedProducts = async (dispatch, location) => {
  return axios
    .get(API_URL + `/api/purchased-products/location/${location}`, {
      headers: authHeader(),
    })
    .then(({ data }) => {
      dispatch(action.setPurchasedProductList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const createPurchasedProduct = async (dispatch, body) => {
  return axios
    .post(
      API_URL + `/api/purchased-products`,
      { data: { ...body } },
      { headers: authHeader() }
    )
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const deletePurchasedProduct = async (dispatch, id) => {
  return axios
    .delete(API_URL + `/api/purchased-products/${id}`, {
      headers: authHeader(),
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchSelectOptions = async (dispatch) => {
  return axios
    .get(API_URL + `/api/products/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setProductList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};

export const fetchSelectOptionsPurchased = async (dispatch) => {
  return axios
    .get(API_URL + `/api/purchased-products/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setPurchasedProductList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setProductError({ error: error?.response?.data?.error?.message })
      );
    });
};
