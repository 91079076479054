import "./userForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect } from "react";
import * as userAction from "../../../../redux/actions/user.action";
import * as roleAction from "../../../../redux/actions/role.action";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import { getTheme } from "../../../../helpers/select.theme";
import UserService from "../../../../services/UserService";
import { constants } from "../../../../helpers/constants";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { Modal } from "react-bootstrap";

const UserForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { entityId } = useParams();
  const checkboxRef = useRef([]);
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { t } = useTranslation();
  const [touchedRoleField, setTouchedRoleField] = useState(false);
  const formRef = useRef({ values: null });
  const { values } = formRef.current;
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showModelToDelete, setShowModelToDelete] = useState(false);
  const [showButtonToHardDelete, setShowButtonToHardDelete] = useState(false);
  const [hardDeleteWasSuccessful, setHardDeleteWasSuccessful] = useState(false);

 // if hard delete was successful, redirect to members list
  useEffect(() => {
    if (hardDeleteWasSuccessful) {
      navigate(`/users`);
    }
  }, [hardDeleteWasSuccessful, navigate ]);

  const handleCloseModalToDeleteUser = () => setShowModelToDelete(false);
  const handleHardDeleteConfirmationCheckboxChange = () => setShowButtonToHardDelete(!showButtonToHardDelete);

  const { userDetails, roleDetails, roleList, user, error } = useSelector(
    (state) => ({
      userDetails: state.user.userDetails,
      roleList: state.role.roleList,
      user: state.user.user,
      roleDetails: state.role.roleDetails,
      error: state.user.errorUser,
    })
  );
  const [selectedRole, setSelectedRole] = useState(null);
  const [showExitModal, setShowExitModal] = useState(false);

  const processSectionsAndReports = (userDetails) => {
    if (!userDetails) return [];
    if (!userDetails.admin_portal_role) return [];

    // const allowed =
    // userDetails.portal_user_custom_entitlements_allowed_sections_and_reports;
    // const denied =
    // userDetails.portal_user_custom_entitlements_denied_sections_and_reports;
    const roleSectionsAndReports =
      userDetails.admin_portal_role?.sections_and_reports;
    // const newSectionsAndReports = [
    //   ...roleSectionsAndReports,
    //   ...allowed,
    // ].filter((sr) => !denied.includes(sr));

    return roleSectionsAndReports || [];
  };
  console.log('roleList', roleList)

  const processRoleDetails = (roleDetails) => {
    const sectionsAndReports = roleDetails?.sections_and_reports;
    return sectionsAndReports || [];
  };

  const initialValuesCreate = {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    role: selectedRole,
    title: values?.title,
    sections_and_reports: roleDetails ? processRoleDetails(roleDetails) : [],
    user_administration: roleDetails && roleDetails?.user_administration,
    sso_access: roleDetails ? roleDetails.sso_access : [],
  };

  const initialValuesEdit = {
    firstName: userDetails?.first_name,
    lastName: userDetails?.last_name,
    email: userDetails?.email,
    role: touchedRoleField
      ? { value: roleDetails?.id, label: roleDetails?.name }
      : {
          value: userDetails?.admin_portal_role?.id || "",
          label: userDetails?.admin_portal_role?.name || "",
        },
    title: userDetails?.title,
    sections_and_reports:
      roleDetails && touchedRoleField
        ? processRoleDetails(roleDetails)
        : processSectionsAndReports(userDetails),
    user_administration: roleDetails && roleDetails?.user_administration,
    sso_access: roleDetails ? roleDetails.sso_access : [],
  };

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required("Required field"),
    lastName: Yup.string().required("Required field"),
    email: Yup.string().required("Required field"),
    role: Yup.mixed().required("Required field"),
    title: Yup.string().required("Required field"),
  });

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button

    const { firstName, lastName, email, role, title } = values;

    const emailChanged = userDetails?.email !== email;
    console.log('role', role)

    const formBody = {
      first_name: firstName,
      last_name: lastName,
      email: entityId && !emailChanged ? undefined : email,
      title,
      // customEntitlements: {allow:[], deny: []},
      role: role.label,
    };

    if (entityId) {
      await userAction.updateBackofficeUser(dispatch, entityId, formBody);
    } else {
      await userAction.createBackofficeUser(dispatch, formBody);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    setTouchedRoleField(false);
    roleAction.fetchSelectOptionsAdminPortal(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      userAction.fetchBackofficeUserDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    if(selectedRole?.value){
      roleAction.fetchAdminPortalRoleDetails(dispatch, selectedRole?.value);
    }
  }, [dispatch, selectedRole?.value]);

  useEffect(() => {
    if (!entityId) {
      setSelectedRole({
        value: roleList?.data?.[0]?.id,
        label: roleList?.data?.[0]?.attributes.name,
      });
    }
  }, [JSON.stringify(roleList?.data)]);

  useEffect(() => {
    if (entityId && userDetails?.admin_portal_role?.id) {
      roleAction.fetchAdminPortalRoleDetails(
        dispatch,
        userDetails?.admin_portal_role?.id
      );
    }
  }, [JSON.stringify(userDetails)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && user) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, user, submittedForm, navigate]);

  return (
    <Formik
      initialValues={entityId ? initialValuesEdit : initialValuesCreate}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          resetForm,
        } = formik;

        return (
          <div className="user">
            <div className="user-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Admin Portal User ${
                    entityId ? "edited" : "created"
                  } successfully.`}
                </Alert>
              )}
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
              <div className="user-header">
                <h1 className="user-title">
                  {entityId ? t("users.editusers") : t("users.newusers")}
                </h1>
              </div>
              
              {
               userDetails && 
(
           <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="link"
                    className="hard-delete-user-button"
                    onClick={() => {
                      setShowModelToDelete(true);
                    }} 
                  >
                    <div style={{display: "flex", alignItems: "flex-start"}}>

                      <span className="material-symbols-outlined" > delete </span>
                      <p>Delete user </p>
                    </div>
                  </Button>
                  </div>

) }

</div>
              <Form className="user-form">
                <div className="user-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("users.name")}</Form.Label>
                      <Form.Control
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.firstName && touched.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("users.lastname")}</Form.Label>
                      <Form.Control
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.lastName && touched.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("users.email")}</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("users.role")}</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        onChange={(option) => {
                          setTouchedRoleField(true);
                          setSelectedRole(option);
                        }}
                        closeMenuOnSelect={true}
                        onBlur={handleBlur}
                        options={roleList?.data?.map(
                          ({ id, attributes: { name } }) => ({
                            value: id,
                            label: name,
                          })
                        )}
                        value={values.role}
                        isInvalid={errors.role && touched.role}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.role}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("users.title")}</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("users.password")}</Form.Label>
                      {entityId ? (
                        <Button
                          className="form-action"
                          onClick={() =>
                            userAction.requestPasswordReset({
                              email: userDetails?.email,
                            })
                          }
                        >
                          Request Password Update
                        </Button>
                      ) : (
                        <Form.Control
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!entityId}
                          placeholder={
                            !entityId &&
                            "Password will be generated automatically"
                          }
                        />
                      )}
                    </div>
                  </div>
                  <span className="user-access-label">
                    {t("users.settings")}
                  </span>
                  <div className="form-row">
                    <div className="user-access-col">
                      <div className="web-portal-sr-check-group-col-header">
                        <span>{t("users.sectionsandreports")}</span>
                        {/* <div className="check-group-line"></div>
                        <Form.Check
                          id="all"
                          label="Select All"
                          bsPrefix='form-check-input-custom'
                          name="sections_and_reports"
                          value={0}
                          onChange={(e) => {
                            Object.values(checkboxRef.current).forEach((item) => {
															if (item) {
																item.checked = e.target.checked;
															}
														});
														setFieldValue('sections_and_reports', e.target.checked ? sectionsAndReportsData : []);
                          }}
                        /> */}
                      </div>
                      <div className="user-check-group">
                        <div className="user-check-group-col">
                          <Form.Check
                            bsPrefix="form-check-input-custom"
                            id="recipesandarticles"
                            label="Recipes and Articles"
                            name="sections_and_reports"
                            disabled
                            value="recipesandarticles"
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes(
                                "recipesandarticles"
                              )
                            }
                          />
                          <Form.Check
                            id="tags"
                            label="Tags"
                            bsPrefix="form-check-input-custom"
                            name="sections_and_reports"
                            value="tags"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes("tags")
                            }
                          />
                          <Form.Check
                            id="contacts"
                            label="MIIA Contacts"
                            bsPrefix="form-check-input-custom"
                            name="sections_and_reports"
                            value="contacts"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes("contacts")
                            }
                          />
                          <Form.Check
                            id="healthportal"
                            label="Health Portal"
                            bsPrefix="form-check-input-custom"
                            name="sections_and_reports"
                            value="healthportal"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes(
                                "healthportal"
                              )
                            }
                          />
                          <Form.Check
                            id="notifications"
                            label="Notifications"
                            bsPrefix="form-check-input-custom"
                            name="sections_and_reports"
                            value="notifications"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes(
                                "notifications"
                              )
                            }
                          />
                        </div>
                        <div className="user-check-group-col">
                          <Form.Check
                            id="dropdowns"
                            label="Dropdowns"
                            bsPrefix="form-check-input-custom"
                            name="sections_and_reports"
                            value="dropdowns"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes("dropdowns")
                            }
                          />
                          <Form.Check
                            id="roles"
                            label="Roles"
                            name="sections_and_reports"
                            value="roles"
                            bsPrefix="form-check-input-custom"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes("roles")
                            }
                          />
                          <Form.Check
                            id="memberslist"
                            label="Members List"
                            name="sections_and_reports"
                            value="memberslist"
                            bsPrefix="form-check-input-custom"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes(
                                "memberslist"
                              )
                            }
                          />
                          <Form.Check
                            id="membersgroup"
                            label="Members Group"
                            name="sections_and_reports"
                            value="membersgroup"
                            bsPrefix="form-check-input-custom"
                            disabled
                            onChange={handleChange}
                            ref={(element) => {
                              checkboxRef.current[element?.value] = element;
                            }}
                            checked={
                              typeof values.sections_and_reports === "object" &&
                              values.sections_and_reports?.includes(
                                "membersgroup"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-access-col">
                      <span>{t("users.useradministration")}</span>
                      <div className="user-check-group-col">
                        <Form.Check
                          id="editandcreateportalusers"
                          label="Web Portal users"
                          name="userAdministration"
                          bsPrefix="form-check-input-custom"
                          disabled
                          value="portalusers"
                          onChange={handleChange}
                          checked={
                            typeof values.user_administration === "object" &&
                            values.user_administration?.includes("portalusers")
                          }
                        />
                        <Form.Check
                          id="editandcreatebousers"
                          label="Admin Portal users"
                          name="userAdministration"
                          bsPrefix="form-check-input-custom"
                          disabled
                          value="adminusers"
                          onChange={handleChange}
                          checked={
                            typeof values.user_administration === "object" &&
                            values.user_administration?.includes("adminusers")
                          }
                        />
                      </div>
                    </div>
                    <div className="user-access-col">
                      <span>{t("users.ssoacces")}</span>
                      <div className="user-check-group-col">
                        <Form.Check
                          id="emiia"
                          label="emiia.org"
                          name="sso_access"
                          disabled
                          bsPrefix="form-check-input-custom"
                          value="emiia"
                          onChange={handleChange}
                          checked={
                            typeof values.sso_access === "object" &&
                            values.sso_access?.includes("emiia")
                          }
                        />
                        <Form.Check
                          id="utmc"
                          label="UTMC"
                          name="sso_access"
                          bsPrefix="form-check-input-custom"
                          disabled
                          value="utmc"
                          onChange={handleChange}
                          checked={
                            typeof values.sso_access === "object" &&
                            values.sso_access?.includes("utmc")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>

                   <Modal
                show={showModelToDelete}
                onHide={handleCloseModalToDeleteUser}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  Delete User?
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
       <Form.Check
                          id="editandcreateusers"
                          bsPrefix="form-check-input-custom"
                          label="I understand that once deleted, this user cannot be recovered. This activity is logged and can't be undone"
                          name="portal_user_administration_hard_delete_portal_user"
                          value="hard_delete_portal_user"
                          checked={showButtonToHardDelete}
                          onChange={handleHardDeleteConfirmationCheckboxChange}
                        />
                  
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="form-action"
                    onClick={handleCloseModalToDeleteUser}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="form-action form-action-primary"
                    disabled={!showButtonToHardDelete}
                    onClick={() => {
                      setShowModelToDelete(false);
                      setShowButtonToHardDelete(false);
                      userAction.hardDeleteUser({
                        dispatch,
                        userEmail: userDetails?.email,
                        setHardDeleteWasSuccessful,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>



            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default UserForm;
