import tagReducer from "./reducers/tagReducer";
import articleReducer from "./reducers/articleReducer";
import recipeReducer from "./reducers/recipeReducer";
import memberReducer from "./reducers/memberReducer";
import memberGroupReducer from "./reducers/memberGroupReducer";
import memberOptionsReducer from './reducers/memberOptionsReducer';
import tagGroupReducer from "./reducers/tagGroupReducer";
import contactReducer from "./reducers/contactReducer";
import productReducer from "./reducers/productReducer";
import notificationReducer from "./reducers/notificationReducer";
import userReducer from "./reducers/userReducer";
import roleReducer from "./reducers/roleReducer";
import linkReducer from "./reducers/linkReducer";
import pageReducer from "./reducers/pageReducer";
import buttonReducer from "./reducers/buttonReducer";
import dropdownReducer from './reducers/dropdownReducer';
import newsletterReducer from './reducers/newsletterReducer';
import newsReducer from './reducers/newsReducer';
import programReducer from "./reducers/programReducer";
import carrouselReducer from './reducers/carrouselReducer';
import { configureStore } from "@reduxjs/toolkit";
import axiosMiddleware from "redux-axios-middleware";
import { applyMiddleware } from "redux";
import axios from "axios";
import thunk from "redux-thunk";
import bulkReducer from './reducers/bulkReducer';

const setup = () => {
  const middleware = applyMiddleware(thunk, axiosMiddleware(axios));
  return configureStore(
    {
      reducer: {
        tag: tagReducer,
        article: articleReducer,
        recipe: recipeReducer,
        tagGroup: tagGroupReducer,
        member: memberReducer,
        memberGroup: memberGroupReducer,
        contact: contactReducer,
        product: productReducer,
        notification: notificationReducer,
        role: roleReducer,
        link: linkReducer,
        button: buttonReducer,
        page: pageReducer,
        dropdown: dropdownReducer,
        bulk: bulkReducer,
        user: userReducer,
        carrousel: carrouselReducer,
        news: newsReducer,
        memberOptions: memberOptionsReducer,
        newsletter: newsletterReducer,
        program: programReducer,
      },
    },
    middleware
  );
};

const StoreService = {
  setup,
};

export default StoreService;
