import { createSlice } from "@reduxjs/toolkit";

const newsReducer = createSlice({
  name: "news",
  initialState: {
    newsDetails: null,
    news: null,
    newsList: null,
    newsMetadata: null,
    newsError: '',
    listLoading: false,
  },
  reducers: {
    setNewsDetail: (state, action) => {
      state.newsDetails = action.payload.data;
      state.newsError = null;
    },
    setNewsList: (state, action) => {
      state.newsList = action.payload.data;
      state.newsError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.newsMetadata = action.payload.meta;
    },
    setNews: (state, action) => {
      state.news = action.payload.data;
      state.newsError = null;
    },
    setNewsError: (state, action) => {
      state.newsError = action.payload.error;
    },
    cleanNewsState: (state) => {
      state = newsReducer.getInitialState();
    },
  },
});

export const {
  setNewsDetail,
  setNewsList,
  setNewsError,
  setNews,
  setMetadata,
  cleanNewsState,
  setListLoading,
} = newsReducer.actions;

export default newsReducer.reducer;