// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview {
  flex: 1 1;
  overflow-y: scroll;
  height: 100%;
  padding-block: 1em;
  justify-content: flex-start;
  box-sizing: border-box;
}
.overview-title {
  margin-left: 2em;
  font-size: 1.5em;
  font-weight: bold;
}
.overview .list {
  margin-bottom: 1em !important;
}`, "",{"version":3,"sources":["webpack://./src/components/views/overview/overview.scss"],"names":[],"mappings":"AAGA;EACC,SAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,2BAAA;EACA,sBAAA;AAFD;AAIC;EACC,gBAAA;EACA,gBAAA;EACA,iBAAA;AAFF;AAKC;EACC,6BAAA;AAHF","sourcesContent":["@import '../../../sass/globals/globals.scss';\n@import '../../../sass/globals/mixins.scss';\n\n.overview {\n\tflex: 1;\n\toverflow-y: scroll;\n\theight: 100%;\n\tpadding-block: 1em;\n\tjustify-content: flex-start;\n\tbox-sizing: border-box;\n\n\t&-title {\n\t\tmargin-left: 2em;\n\t\tfont-size: 1.5em;\n\t\tfont-weight: bold;\n\t}\n\n\t& .list {\n\t\tmargin-bottom: 1em !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
