import './file-input.scss';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef } from 'react';
import pdf from "../../assets/images/filetype/pdf.png";
import doc from "../../assets/images/filetype/docx.png";
import xls from "../../assets/images/filetype/xls.png";
import _default from "../../assets/images/filetype/default.png";
import { fetchSASToken } from "../../services/SASTokenService";

const mapFileTypeToImage = {
		"application/pdf": pdf,
		"application/msword": doc,
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": doc,
		"application/vnd.ms-excel": xls,
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": xls,
		"csv": xls,
	};

const FileInput = ({
	setFieldValue,
	errorMessage,
	allowedTypes,
	value,
	index,
	title,
	allowDownload = false,
	customFieldName = 'file',
	showDescripton = true,
	fileSize,
}) => {
	const [file, setFile] = useState(null);
  const [token, setToken] = useState(null);
	const imageRef = useRef(null);

  useEffect(() => {
    const fetchToken = async () => {
      setToken(await fetchSASToken());
    };
    fetchToken();
  }, []);

	const isFile = (file) => {
		return !['image/jpeg', 'image/png'].includes(file.type || file.mime);
	};

	const getType = (file) => {
		return file.type || file.mime;
	};

	const onFileDrop = e => {
		const newFile = e.target.files[0];
		setFile(newFile);
		setFieldValue(customFieldName, newFile);
		imageRef.current.src = URL.createObjectURL(newFile);

		if(isFile(newFile)) {
			imageRef.current.src = mapFileTypeToImage[getType(newFile)] || _default;
		}
	};

	const onFileClear = () => {
		setFile(null);
		setFieldValue(customFieldName, null);
		imageRef.current.src = "";
	};

	const onFileDownload = async () => {
		const token = await fetchSASToken();

		const link = document.createElement('a');
		link.style.display = 'none';
		// Set the link's attributes

		console.log(file)

		link.href = `${file.url}?${token}`;

		link.download = `file-${Date.now()}.xlsx`;
		// Add the link to the DOM
		document.body.appendChild(link);
		// Click the link to trigger the download
		link.click();
		// Remove the link from the DOM
		document.body.removeChild(link);
	};

	useEffect(() => {
		setFile(value);
	}, [value])

	const getError = () => errorMessage?.documents?.at(index)?.file || errorMessage?.file;

	useEffect(() => {
		if (file) {
			if(file?.url && !isFile(file)) {
				imageRef.current.src = `${file?.url}?${token}`;
				imageRef.current.classList = "image-preview"
			} else if(isFile(file)) {
				imageRef.current.src = mapFileTypeToImage[getType(file)] || _default;
				imageRef.current.classList = "file-preview"
			} else {
				imageRef.current.src = URL.createObjectURL(file);
				imageRef.current.classList = "image-preview"
			}
		}
	}, [file, token]);

	return (
		<>
			<div
				className={`file-input ${getError() ? 'file-input-error' : ''}`}
			>
				{!file && <div className='file-input-label'>
					<span className='file-input-title'>{title}</span>
					<span className='file-input-description'>
						Allowed types: {allowedTypes} {showDescripton && `- Max file size: ${fileSize || '2 MB'}`}
					</span>
				</div>}
				<Form.Control type='file' onChange={onFileDrop} />
				<img className={file ? isFile(file) ? "file-preview" : "image-preview" : "file-hide"} ref={imageRef} src="#" alt=""/>
				<span className="file-input-info">{file?.name}</span>
				{(allowDownload && file?.url)&& <span onClick={onFileDownload} className="file-download material-symbols-outlined">
					download
				</span>}
				<span onClick={onFileClear} className="file-delete material-symbols-outlined">
					delete
				</span>
			</div>

			{getError() && (
				<span className='file-input-info-error'>{getError()}</span>
			)}
		</>
	);
};

export default FileInput;
