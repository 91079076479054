import { createSlice } from "@reduxjs/toolkit";

const newsletterReducer = createSlice({
  name: "newsletter",
  initialState: {
    newsletterDetails: null,
    newsletter: null,
    newsletterMetadata: null,
    newsletterError: '',
  },
  reducers: {
    setNewsletterDetail: (state, action) => {
      state.newsletterDetails = action.payload.data;
      state.newsletterError = null;
    },
    setNewsletter: (state, action) => {
      state.newsletter = action.payload.data;
      state.newsletterError = null;
    },
    setNewsletterError: (state, action) => {
      state.newsletterError = action.payload.error;
    },
    cleanNewsletterState: (state) => {
      state = newsletterReducer.getInitialState();
    },
  },
});

export const {
  setNewsletterDetail,
  setNewsletterError,
  setNewsletter,
  cleanNewsletterState,
} = newsletterReducer.actions;

export default newsletterReducer.reducer;