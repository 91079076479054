import Modal from 'react-bootstrap/Modal';
import RecipePreview from '../recipePreview/recipePreview';

const RecipePreviewModal = ({ show, handleClose, recipe }) => {
	return (
		<Modal size="lg" show={show} onHide={handleClose}>
			<Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Preview
        </Modal.Title>
      </Modal.Header>
			<Modal.Body style={{backgroundColor: '#f7f8f9'}} >
				<RecipePreview recipe={recipe} />
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	);
}

export default RecipePreviewModal;