import Layout from "./components/layout/layout";
import { constants } from "./helpers/constants";
import Overview from "./components/views/overview/overview";
import ArticleList from "./components/views/articles/articleList/articleList";
import ArticleForm from "./components/views/articles/articleForm/articleForm";
import RecipeList from "./components/views/recipe/recipeList/recipeList";
import RecipeForm from "./components/views/recipe/recipeForm/recipeForm";
import MemberList from "./components/views/members/memberList/memberList";
import MemberGroupList from "./components/views/members/memberGroupList/memberGroupList";
import MemberTabs from "./components/views/members/memberTabs/memberTabs";
import MembersCreate from "./components/views/membersCreate/membersCreate";
import ContactList from "./components/views/MIIAContacts/contactList/contactList";
import ContactForm from "./components/views/MIIAContacts/contactForm/contactForm";
import NotificationList from "./components/views/notifications/notificationList/notificationList";
import NotificationForm from "./components/views/notifications/notificationForm/notificationForm";
import ButtonInHomeForm from "./components/views/buttonsInHome/buttonInHomeForm/buttonInHomeForm";
import WebPortalUserForm from "./components/views/webPortalUsers/webPortalUsersForm/webPortalUserForm";
import QuickLinkForm from "./components/views/quickLink/quickLinkForm/quickLinkForm";
import TagTabs from "./components/views/tag/tagTabs/tagTabs";
import TagForm from "./components/views/tag/tagForm/tagForm";
import TagGroupForm from "./components/views/tag/tagGroupForm/tagGroupForm";
import DropdownList from "./components/views/dropdowns/dropdownList/dropdownList";
import DropdownForm from "./components/views/dropdowns/dropdownForm/dropdownForm";
import DropdownOptionValueForm from "./components/views/dropdowns/dropdownOptionValueForm/dropdownOptionValueForm";
import { Routes, Route } from "react-router-dom";
import RoleList from "./components/views/roles/roleList/roleList";
import RoleForm from "./components/views/roles/roleForm/roleForm";
import PrivateRoute from "./helpers/PrivateRoute";
import PageForm from "./components/views/pages/pageForm/pageForm";
import MemberGroupForm from "./components/views/members/memberGroupForm/memberGroupForm";
import UserList from "./components/views/users/userList/userList";
import UserForm from "./components/views/users/userForm/userForm";
import "./i18n";
import RoleRestricted from "./components/roleRestricted/roleRestricted";
import NewsList from "./components/views/news/newsList/newsList";
import NewsForm from "./components/views/news/newsForm/newsForm";
import CarrouselList from "./components/views/carrousel/carrouselList/carrouselList";
import CarrouselForm from "./components/views/carrousel/carrouselForm/carrouselForm";
import Newsletter from "./components/views/newsletter/newsletter";
import LinkList from "./components/views/quickLink/quickLinkList/quickLinkList";
import BulkActionForm from './components/views/bulk/bulkForm/bulkActionForm';
import BulkActionList from './components/views/bulk/bulkList/bulkActionList';
import ProgramList from "./components/views/programs/programList/programList";
import ProgramForm from "./components/views/programs/programForm/programForm";

const App = () => {
  return (
    <PrivateRoute>
      <Layout>
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route
            path="/articles"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <ArticleList />
              </RoleRestricted>
            }
          />
          <Route
            path="/articles/form"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <ArticleForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/articles/form/:entityId"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <ArticleForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/recipes"
            element={
              <RoleRestricted role={constants.section.RECIPE}>
                <RecipeList />
              </RoleRestricted>
            }
          />
      <Route
            path="/healthprograms"
            element={
              <RoleRestricted role={constants.section.PROGRAM}>
                <ProgramList type={'health'} />
              </RoleRestricted>
            }
          />
      <Route
            path="/rmprograms"
            element={
              <RoleRestricted role={constants.section.PROGRAM}>
                <ProgramList type={'rm'} />
              </RoleRestricted>
            }
          />
          <Route
            path="/recipes/form"
            element={
              <RoleRestricted role={constants.section.RECIPE}>
                <RecipeForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/recipes/form/:entityId"
            element={
              <RoleRestricted role={constants.section.RECIPE}>
                <RecipeForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/healthprograms/form"
            element={
              <RoleRestricted role={constants.section.PROGRAM}>
                <ProgramForm type={'health'} />
              </RoleRestricted>
            }
          />
          <Route
            path="/rmprograms/form"
            element={
              <RoleRestricted role={constants.section.PROGRAM}>
                <ProgramForm type={'rm'} />
              </RoleRestricted>
            }
          />
          <Route
            path="/rmprograms/form/:entityId"
            element={
              <RoleRestricted role={constants.section.PROGRAM}>
                <ProgramForm type={'rm'} />
              </RoleRestricted>
            }
          />
          <Route
            path="/healthprograms/form/:entityId"
            element={
              <RoleRestricted role={constants.section.PROGRAM}>
                <ProgramForm type={'health'} />
              </RoleRestricted>
            }
          />
          <Route
            path="/roles"
            element={
              <RoleRestricted role={constants.section.WEB_PORTAL_ROLE}>
                <RoleList />
              </RoleRestricted>
            }
          />
          <Route
            path="/roles/form"
            element={
              <RoleRestricted role={constants.section.WEB_PORTAL_ROLE}>
                <RoleForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/roles/form/:entityId"
            element={
              <RoleRestricted role={constants.section.WEB_PORTAL_ROLE}>
                <RoleForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/tags"
            element={
              <RoleRestricted role={constants.section.TAG}>
                <TagTabs />
              </RoleRestricted>
            }
          />
          <Route
            path="/tags/form"
            element={
              <RoleRestricted role={constants.section.TAG}>
                <TagForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/tags/form/:entityId"
            element={
              <RoleRestricted role={constants.section.TAG}>
                <TagForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/tag-groups/form"
            element={
              <RoleRestricted role={constants.section.TAG}>
                <TagGroupForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/tag-groups/form/:entityId"
            element={
              <RoleRestricted role={constants.section.TAG}>
                <TagGroupForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/quick-access"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <LinkList />
              </RoleRestricted>
            }
          />
          <Route
            path="/quick-access/form"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <QuickLinkForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/quick-access/form/:entityId"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <QuickLinkForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/newsletter"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <Newsletter />
              </RoleRestricted>
            }
          />
          <Route
            path="/quick-access/member/:memberId/form"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <QuickLinkForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/quick-access/member/:memberId/form/:entityId"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <QuickLinkForm type="edit" />
              </RoleRestricted>
            }
          />
          <Route
            path="/members"
            element={
              <RoleRestricted role={constants.section.MEMBER}>
                <MemberList />
              </RoleRestricted>
            }
          />
          <Route
            path="/members/form"
            element={
              <RoleRestricted role={constants.section.MEMBER}>
                <MembersCreate />
              </RoleRestricted>
            }
          />
          <Route
            path="/members/form/:entityId/:currentTab"
            element={
              <RoleRestricted role={constants.section.MEMBER}>
                <MemberTabs />
              </RoleRestricted>
            }
          />
          <Route
            path="/member-groups"
            element={
              <RoleRestricted role={constants.section.MEMBER_GROUP}>
                <MemberGroupList />
              </RoleRestricted>
            }
          />
          <Route
            path="/member-groups/form"
            element={
              <RoleRestricted role={constants.section.MEMBER_GROUP}>
                <MemberGroupForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/member-groups/form/:entityId"
            element={
              <RoleRestricted role={constants.section.MEMBER_GROUP}>
                <MemberGroupForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/pages/form"
            element={
              <RoleRestricted role={constants.section.PAGE}>
                <PageForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/pages/form/:entityId"
            element={
              <RoleRestricted role={constants.section.PAGE}>
                <PageForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/pages/member/:memberId/form"
            element={
              <RoleRestricted role={constants.section.PAGE}>
                <PageForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/pages/member/:memberId/form/:entityId"
            element={
              <RoleRestricted role={constants.section.PAGE}>
                <PageForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/contacts"
            element={
              <RoleRestricted role={constants.section.CONTACT}>
                <ContactList />
              </RoleRestricted>
            }
          />
          <Route
            path="/contacts/form"
            element={
              <RoleRestricted role={constants.section.CONTACT}>
                <ContactForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/contacts/form/:entityId"
            element={
              <RoleRestricted role={constants.section.CONTACT}>
                <ContactForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/news"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <NewsList />
              </RoleRestricted>
            }
          />
          <Route
            path="/news/form/:entityId"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <NewsForm type="edit" />
              </RoleRestricted>
            }
          />
          <Route
            path="/carrousel"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <CarrouselList />
              </RoleRestricted>
            }
          />
          <Route
            path="/carrousel/form/:entityId"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <CarrouselForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/carrousel/form/"
            element={
              <RoleRestricted role={constants.section.ARTICLE}>
                <CarrouselForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/users"
            element={
              <RoleRestricted role={constants.section.ADMIN_PORTAL_USER}>
                <UserList />
              </RoleRestricted>
            }
          />
          <Route
            path="/users/form"
            element={
              <RoleRestricted role={constants.section.ADMIN_PORTAL_USER}>
                <UserForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/users/form/:entityId"
            element={
              <RoleRestricted role={constants.section.ADMIN_PORTAL_USER}>
                <UserForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/dropdowns"
            element={
              <RoleRestricted role={constants.section.DROPDOWN}>
                <DropdownList />
              </RoleRestricted>
            }
          />
          <Route
            path="/dropdowns/form"
            element={
              <RoleRestricted role={constants.section.DROPDOWN}>
                <DropdownForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/dropdowns/form/:entityId"
            element={
              <RoleRestricted role={constants.section.DROPDOWN}>
                <DropdownForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/dropdowns/member/:memberId/form"
            element={
              <RoleRestricted role={constants.section.DROPDOWN}>
                <DropdownForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/dropdowns/member/:memberId/form/:entityId"
            element={
              <RoleRestricted role={constants.section.DROPDOWN}>
                <DropdownForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/dropdown-option/member/:memberId/option/:optionId/form"
            element={
              <RoleRestricted role={constants.section.DROPDOWN}>
                <DropdownOptionValueForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/web-portal-users/member/:memberId/form"
            element={
              <RoleRestricted role={constants.section.WEB_PORTAL_USER}>
                <WebPortalUserForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/web-portal-users/member/:memberId/form/:entityId"
            element={
              <RoleRestricted role={constants.section.WEB_PORTAL_USER}>
                <WebPortalUserForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/notifications"
            element={
              <RoleRestricted role={constants.section.NOTIFICATION}>
                <NotificationList />
              </RoleRestricted>
            }
          />
          <Route
            path="/notifications/form"
            element={
              <RoleRestricted role={constants.section.NOTIFICATION}>
                <NotificationForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/notifications/form/:entityId"
            element={
              <RoleRestricted role={constants.section.NOTIFICATION}>
                <NotificationForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/buttons-in-home/form"
            element={
              <RoleRestricted role={constants.section.BUTTON_IN_HOME}>
                <ButtonInHomeForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/buttons-in-home/form/:entityId"
            element={
              <RoleRestricted role={constants.section.BUTTON_IN_HOME}>
                <ButtonInHomeForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/buttons-in-home/member/:memberId/form"
            element={
              <RoleRestricted role={constants.section.BUTTON_IN_HOME}>
                <ButtonInHomeForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/buttons-in-home/member/:memberId/form/:entityId"
            element={
              <RoleRestricted role={constants.section.BUTTON_IN_HOME}>
                <ButtonInHomeForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/bulk"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <BulkActionList />
              </RoleRestricted>
            }
          />
          <Route
            path="/bulk/form"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <BulkActionForm />
              </RoleRestricted>
            }
          />
          <Route
            path="/bulk/form/:entityId"
            element={
              <RoleRestricted role={constants.section.QUICK_ACCESS_LINK}>
                <BulkActionForm />
              </RoleRestricted>
            }
          />
        </Routes>
      </Layout>
    </PrivateRoute>
  );
};

export default App;
