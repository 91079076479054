import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as roleAction from "../../../../redux/actions/role.action";
import List from "../../../list/list";
import UserService from "../../../../services/UserService";
import { constants } from "../../../../helpers/constants"; 
import { formatDate } from '../../../../helpers/date';

const roleFields = [
  //list will sort by the first element by default
  "name",
  "createdAt",
];

const RoleList = () => {
  const dispatch = useDispatch();

  const { roles, meta, listLoading } = useSelector((state) => ({
    roles: state.role.roleList,
    meta: state.role.roleMetadata,
    listLoading: state.role.listLoading,
  }));

  useEffect(() => {
    roleAction.fetchAll(dispatch);
  }, [dispatch]);

  return (
    <List
      title="Roles"
      tooltipTextDelete="Delete Role"
      redirect="/roles/form"
      columnNames={["NAME"]}
			entities={roles?.data?.map(({ id, attributes: { name, createdAt } }) => ({
        id,
				columns: [
					{ primary: name, secondary: formatDate(createdAt)}
				]
      }))}
      fieldsToSort={roleFields}
      fetchAction={(params) => roleAction.fetchAll(dispatch, params)}
      isOverview={false}
      metadata={meta}
      deleteAction={(id, params) => roleAction.remove(dispatch, id, params)}
      loading={listLoading}
    />
  );
};

export default RoleList;
