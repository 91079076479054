import { createSlice } from "@reduxjs/toolkit";

const buttonReducer = createSlice({
  name: "button",
  initialState: {
    buttonDetails: null,
    button: null,
    buttonList: null,
    buttonMetadata: null,
    buttonError: '',
    listLoading: false,
  },
  reducers: {
    setButtonDetail: (state, action) => {
      state.buttonDetails = action.payload.data;
      state.buttonError = null;
    },
    setButtonList: (state, action) => {
      state.buttonList = action.payload.data;
      state.buttonError = null;
    },
    setMetadata: (state, action) => {
      state.buttonMetadata = action.payload.meta;
    },
    setButton: (state, action) => {
      state.button = action.payload.data;
      state.buttonError = null;
    },
    setButtonError: (state, action) => {
      state.buttonError = action.payload.error;
    },
    cleanButtonState: (state) => {
      state = buttonReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setButtonDetail,
  setButtonList,
  setButtonError,
  setCreatedButton,
  setEditedButton,
  setMetadata,
  setButton,
  cleanButtonState,
  setListLoading,
} = buttonReducer.actions;

export default buttonReducer.reducer;