/* eslint-disable react-hooks/exhaustive-deps */
import "./pageForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../../fileInput/fileInput";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import * as pageAction from "../../../../redux/actions/page.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import { useTranslation } from "react-i18next";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";

const PageForm = () => {
  const dispatch = useDispatch();
  const { memberId, entityId } = useParams();
  const navigate = useNavigate();
  const [selectedTabGenereal, setSelectedTabGeneral] = useState("write");
  const [selectedTabA, setSelectedTabA] = useState("write");
  const [selectedTabB, setSelectedTabB] = useState("write");
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { t } = useTranslation();
  const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
  const VALID_FILE_TYPES = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "csv",
  ];
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showExitModal, setShowExitModal] = useState(false);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const { pageDetails, page, error } = useSelector((state) => ({
    pageDetails: state.page.pageDetails,
    page: state.page.page,
    error: state.page.pageError,
  }));

  const initialValues = {
    title: entityId ? pageDetails?.title || "" : "",
    type: entityId
      ? { value: pageDetails?.type, label: pageDetails?.type } || {}
      : { value: "General Content", label: "General Content" },
    content: entityId ? pageDetails?.content || "" : "",
    documents: entityId ? pageDetails?.documents || [] : [],
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required field"),
    type: Yup.object(),
    content: Yup.string(),
    documents: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        contentA: Yup.string().required("Required field"),
        contentB: Yup.string(),
        source: Yup.string(),
        url: Yup.string().url("Invalid url").nullable(),
        file: Yup.mixed()
          .test(
            "is-correct-size",
            "File too large",
            (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
          )
          .test(
            "is-correct-format",
            "Invalid file extension",
            (value, _) =>
              VALID_FILE_TYPES.includes(value?.type || value?.mime) || !value
          ),
      })
    ),
  });

  const onSubmitForm = async (values, { setFieldValue }) => {
    setDisableSubmit(true); //Disable submit button

    const { title, type } = values;

    let documents = type.value === "Documents" ? values.documents : [];
    const content = type.value === "General Content" ? values.content : "";

    const formData = new FormData();

    documents.forEach((_document, index) => {
      const { source, file } = _document;
      if (source === "upload") {
        formData.append(`${index}`, file);
        setFieldValue(`documents.${index}.url`, "");
      }
    });

    const formBody = {
      title,
      type: type.value,
      documents,
      content,
      location: memberId,
    };

    formData.append("page", JSON.stringify(formBody));

    if (entityId) {
      await pageAction.update(dispatch, entityId, formData);
    } else {
      await pageAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    if (entityId) {
      pageAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && page) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, page, submittedForm, navigate]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
          resetForm,
        } = formik;

        return (
          <div className="page">
            <div className="page-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Page ${entityId ? "edited" : "created"} successfully.`}
                </Alert>
              )}
              <div className="page-header">
                <h1 className="page-title">
                  {entityId ? "Edit Page" : "New Page"}
                </h1>
              </div>
              <Form className="page-form">
                <div className="page-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Type</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        onChange={(option) => setFieldValue("type", option)}
                        closeMenuOnSelect={true}
                        onBlur={handleBlur}
                        options={[
                          {
                            value: "General Content",
                            label: "General Content",
                          },
                          {
                            value: "Documents",
                            label: "Documents",
                          },
                        ]}
                        value={values.type}
                        isInvalid={errors.type && touched.type}
                      />
                    </div>
                  </div>
                  {values.type.value === "Documents" ? (
                    <FieldArray
                      name="documents"
                      render={(arrayHelpers) => (
                        <div className="page-document-array">
                          {values.documents.map((document, index) => (
                            <div className="page-document-container">
                              <div className="page-document" key={index}>
                                <div className="form-row page-document-content">
                                  <div className="page-content-field">
                                    <Form.Label>Content - Column A</Form.Label>
                                    <ReactMde
                                      name={`documents.${index}.contentA`}
                                      value={values.documents[index].contentA}
                                      onChange={(value) =>
                                        setFieldValue(
                                          `documents.${index}.contentA`,
                                          value
                                        )
                                      }
                                      selectedTab={selectedTabA}
                                      onTabChange={setSelectedTabA}
                                      generateMarkdownPreview={(markdown) =>
                                        Promise.resolve(
                                          converter.makeHtml(markdown)
                                        )
                                      }
                                      childProps={{
                                        writeButton: {
                                          tabIndex: -1,
                                        },
                                      }}
                                    />
                                    <p className="input-info-error">
                                      {errors.documents?.at(index)?.contentA}
                                    </p>
                                  </div>
                                  <div className="page-content-field">
                                    <Form.Label>Content - Column B</Form.Label>
                                    <ReactMde
                                      name={`documents.${index}.contentB`}
                                      value={values.documents[index].contentB}
                                      onChange={(value) =>
                                        setFieldValue(
                                          `documents.${index}.contentB`,
                                          value
                                        )
                                      }
                                      selectedTab={selectedTabB}
                                      onTabChange={setSelectedTabB}
                                      generateMarkdownPreview={(markdown) =>
                                        Promise.resolve(
                                          converter.makeHtml(markdown)
                                        )
                                      }
                                      childProps={{
                                        writeButton: {
                                          tabIndex: -1,
                                        },
                                      }}
                                    />
                                    <p className="input-info-error">
                                      {errors.documents?.at(index)?.contentB}
                                    </p>
                                  </div>
                                  <span
                                    onClick={() => arrayHelpers.remove(index)}
                                    className="page-document-delete-button material-symbols-outlined"
                                  >
                                    close
                                  </span>
                                </div>
                                <div className="form-row">
                                  <div className="page-document-group">
                                    <Form.Label>Document</Form.Label>
                                    <div className="form-radio-group">
                                      <Form.Check
                                        type="radio"
                                        id={`document-${index}-1`}
                                        label="URL"
                                        bsPrefix="form-check-input-custom"
                                        name={`documents.${index}.source`}
                                        value="url"
                                        checked={
                                          values.documents[index].source !==
                                          "upload"
                                        }
                                        onChange={handleChange}
                                      />
                                      <Form.Check
                                        type="radio"
                                        bsPrefix="form-check-input-custom"
                                        id={`document-${index}-2`}
                                        label="Upload File"
                                        name={`documents.${index}.source`}
                                        checked={
                                          values.documents[index].source ===
                                          "upload"
                                        }
                                        value="upload"
                                        onChange={handleChange}
                                      />
                                    </div>
                                    {values.documents[index].source ===
                                    "upload" ? (
                                      <div className="form-input">
                                        <FileInput
                                          title="Browse a File"
                                          allowedTypes={"PDF, DOC, XLS"}
                                          value={values.documents[index].file}
                                          setFieldValue={setFieldValue}
                                          errorMessage={errors}
                                          index={index}
                                          customFieldName={`documents.${index}.file`}
                                        />
                                      </div>
                                    ) : (
                                      <div className="form-input">
                                        <Form.Label>File URL</Form.Label>
                                        <Form.Control
                                          name={`documents.${index}.url`}
                                          value={values.documents[index].url}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={
                                            errors.documents?.at(index)?.url &&
                                            touched.documents?.at(index)?.url
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.documents?.at(index)?.url}
                                        </Form.Control.Feedback>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <Button
                            onClick={() =>
                              arrayHelpers.push({
                                contentA: "",
                                contentB: "",
                                url: "",
                                file: "",
                                source: "url",
                              })
                            }
                            className="form-action page-document-action"
                          >
                            Add a new document
                          </Button>
                        </div>
                      )}
                    />
                  ) : (
                    <div className="form-area">
                      <Form.Label>Content</Form.Label>
                      <ReactMde
                        value={values.content}
                        onChange={(value) => setFieldValue("content", value)}
                        selectedTab={selectedTabGenereal}
                        onTabChange={setSelectedTabGeneral}
                        generateMarkdownPreview={(markdown) =>
                          Promise.resolve(converter.makeHtml(markdown))
                        }
                        childProps={{
                          writeButton: {
                            tabIndex: -1,
                          },
                        }}
                      />
                      <p className="input-info-error">{errors.content}</p>
                    </div>
                  )}
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default PageForm;
