import { createSlice } from "@reduxjs/toolkit";

const articleReducer = createSlice({
  name: "article",
  initialState: {
    articleDetails: null,
    article: null,
    articleList: null,
    articleMetadata: null,
    articleError: '',
    listLoading: false,
  },
  reducers: {
    setArticleDetail: (state, action) => {
      state.articleDetails = action.payload.data;
      state.articleError = null;
    },
    setArticleList: (state, action) => {
      state.articleList = action.payload.data;
      state.articleError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.articleMetadata = action.payload.meta;
    },
    setArticle: (state, action) => {
      state.article = action.payload.data;
      state.articleError = null;
    },
    setArticleError: (state, action) => {
      state.articleError = action.payload.error;
    },
    cleanArticleState: (state) => {
      state = articleReducer.getInitialState();
    },
  },
});

export const {
  setArticleDetail,
  setArticleList,
  setArticleError,
  setArticle,
  setMetadata,
  cleanArticleState,
  setListLoading
} = articleReducer.actions;

export default articleReducer.reducer;