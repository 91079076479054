import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const ModalConfirmation = ({
  title,
  question,
  mainAction,
  secondaryAction,
  mainButton,
  secondaryButton,
  handleShow,
  show,
}) => {

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => handleShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{question}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="form-action"
          onClick={() => {
            secondaryAction();
            handleShow(false);
          }}
        >
          {secondaryButton}
        </Button>
        <Button className="form-action form-action-primary" variant="outline-primary" onClick={mainAction}>
          {mainButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmation;
