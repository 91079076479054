// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-visibility .form-input {
  padding-right: 1em;
}
.modal-visibility .form-input .dropdown-select {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/modalVisibility/modalVisibility.scss"],"names":[],"mappings":"AAEC;EACC,kBAAA;AADF;AAGC;EACC,sBAAA;AADF","sourcesContent":[".modal-visibility {\n\n\t.form-input {\n\t\tpadding-right: 1em;\n\t}\n\t.form-input .dropdown-select {\n\t\twidth: 100% !important;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
