import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as userAction from "../../../../redux/actions/user.action";
import List from "../../../list/list";
import { useTranslation } from "react-i18next";
import UserService from "../../../../services/UserService";
import { constants } from "../../../../helpers/constants"; 
import { Alert } from "react-bootstrap";

const userFields = [
  //list will sort by the first element by default
  "full_name",
  "email",
  "publishedAt",
];

const UserList = () => {
  const dispatch = useDispatch();
	const { t } = useTranslation()
  const [successMessage, setSuccessMessage] = useState([]);

  const initialParams = {
    fieldTofilter: "full_name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    backOfficeUser: true,
  };

  const { users, meta, listLoading, userHardDeleted, errorMessage } = useSelector((state) => ({
    users: state.user.userList,
    meta: state.user.userMetadata,
    listLoading: state.user.listLoading,
    errorMessage: state.user.errorUser,
    userHardDeleted: state.user.userHardDeleted,
  }));

  useEffect(() => {
    userAction.fetchAllAdminPortalUsers(dispatch, initialParams);
  }, [dispatch]);


  useEffect(() => {
    userAction.fetchAllPortalUsers(dispatch, initialParams);
    if (userHardDeleted) {
      setSuccessMessage("User deleted successfully");
    }
  }, [dispatch]);
  return ( 
    <div style={{width: '100%'}}>
      {
        successMessage && successMessage.length > 0 && (
          <Alert style={{marginTop: "1rem", marginLeft: "3rem", marginRight: "3rem"}}
           variant="success">{successMessage}</Alert>
        )
      }
      {
        errorMessage && errorMessage.length > 0 && (
          <Alert variant="danger">{errorMessage}</Alert>
        )
      }
    <List
      title={t('users.users')}
      tooltipTextDelete="Delete User"
      redirect="/users/form"
      columnNames={["FULL NAME", "EMAIL", "ROLE"]}
			entities={users?.data?.map(({ id, attributes: {full_name, email, admin_portal_role} }) => ({
        id,
				columns: [
					{ primary: full_name },
          { primary: email },
          { primary: admin_portal_role?.data?.attributes?.name }
				]
      }))}
      showFilterTab={false}
      fieldsToSort={userFields}
      fetchAction={(params) => userAction.fetchAllAdminPortalUsers(dispatch, {...params, backOfficeUser: true})}
      isOverview={false}
      metadata={meta}
      // deleteAction={(id, params) => userAction.removeBackofficeUser(dispatch, id, params)}
      isRemovable={false}
      loading={listLoading}
    />

    </div>
  );
};

export default UserList;
