/* eslint-disable react-hooks/exhaustive-deps */
import "../tabs/index.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { getTheme } from "../../../../helpers/select.theme";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import {
  getOnlyMemberGroupOptions,
} from "../../../../helpers/select";
import { useTranslation } from "react-i18next";
import { customStyles } from '../../../../helpers/select.custom';

const General = ({ tab, setTab, childToParent }) => {
  const dispatch = useDispatch();
  const memberGroupList = useSelector((state) => state.memberGroup.memberGroupSelectList);
  const [memberOptions, setMemberOptions] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
  }, []);
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    title: "",
    short_name: "",
    date_started_working_with_miia: "",
    groups: [],
    dor_code: "",
    zip_code: "",
    date_when_they_become_active: "",
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required field"),
    short_name: Yup.string().required("Required field"),
    date_started_working_with_miia: Yup.date().required("Required field"),
    dor_code: Yup.number().typeError("DOR Code must be of type 'Number'").required("Required field"),
    zip_code: Yup.string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid ZIP Code").required("Required field"),
    title: Yup.string().required("Required field"),
    date_when_they_become_active: Yup.date().required("Required field"),
    groups: Yup.mixed()
  });

  const onSubmitForm = (values) => {
    childToParent("general", values);
  };

  useEffect(() => {
    setMemberOptions(getOnlyMemberGroupOptions(memberGroupList));
  }, [JSON.stringify(memberGroupList)]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, actions) => {
        onSubmitForm(values);
        setTab("type");
      }}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          handleBlur,
        } = formik;

        return (
          <div className="member create-member">
            <div className="title-members-create">
              <h3>New Member</h3>
              <label>
                Enter some general information about the new member:
              </label>
            </div>
            <Form onSubmit={handleSubmit} className="member-form form-create">
              <div className="container-inputs">
                <div className="box-1-create-member">
                  <div className="form-input">
                    <Form.Label>Member Name</Form.Label>
                    <Form.Control
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.name && touched.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label>Short Name</Form.Label>
                    <Form.Control
                      name="short_name"
                      value={values.short_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.short_name && touched.short_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.short_name}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label>DOR Number (Alpha)</Form.Label>
                    <Form.Control
                      name="dor_code"
                      value={values.dor_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.dor_code && touched.dor_code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dor_code}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.title && touched.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </div>
       
                </div>
                <div className="box-2-create-member">
           <div className="form-input">
                    <Form.Label>ZIP Code</Form.Label>
                    <Form.Control
                      name="zip_code"
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.zip_code && touched.zip_code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zip_code}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label>Include it in this groups</Form.Label>
                    <Select
                      theme={(theme) => getTheme(theme)}
                      className="form-select-custom"
                      isMulti
                      styles={customStyles}
                      value={values.groups}
                      onChange={(option) => setFieldValue("groups", option)}
                      closeMenuOnSelect={false}
                      onBlur={handleBlur}
                      options={memberOptions}
                      isInvalid={errors.groups && touched.groups}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.groups}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                      {errors.Include}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label> Date started working with MIIA </Form.Label>
                    <Form.Control
                      name="date_started_working_with_miia"
                      value={values.date_started_working_with_miia}
                      type="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.date_started_working_with_miia && touched.date_started_working_with_miia}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.date_started_working_with_miia}
                    </Form.Control.Feedback>
                </div>
                  <div className="form-input r2c1">
                    <Form.Label>Date when they become active</Form.Label>
                    <Form.Control
                      name="date_when_they_become_active"
                      value={values.date_when_they_become_active}
                      type="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.date_when_they_become_active && touched.date_when_they_become_active}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.date_when_they_become_active}
                    </Form.Control.Feedback>
                  </div>
       
                </div>
              </div>
              <div className="member-form-action-group">
                <Button
                  variant="primary"
                  onClick={() => navigate("/members")}     
                  className="form-action-cancel"  
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    errors === {} ? setTab("type") : setTab("general");
                  }}
                  type="submit"
                  className="form-action form-action-primary"
                  variant="outline-primary"
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default General;
