/* eslint-disable react-hooks/exhaustive-deps */
import "./carrouselForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FileInput from "../../../fileInput/fileInput";
import { Formik } from "formik";
import * as Yup from "yup";
import * as carrouselAction from "../../../../redux/actions/carrousel.action";
import * as memberAction from "../../../../redux/actions/member.action";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import {
  getInitialMemberValues,
  getMemberOptions,
  processMemberOptions,
  formatGroupLabel,
  getRoleOptions,
} from "../../../../helpers/select";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import * as roleAction from "../../../../redux/actions/role.action";
import { customStyles } from '../../../../helpers/select.custom';

const CarrouselForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; //2MB
  const VALID_FILE_TYPES = ["image/png", "image/jpeg"];
  const VALID_FILE_TYPES_PDF = ["application/pdf"];
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showExitModal, setShowExitModal] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [showFileInput, setShowFileInput] = useState(false);

  const {
    carrouselDetails,
    carrousel,
    error,
    memberList,
    memberGroupList,
    roleList,
  } = useSelector((state) => ({
    carrouselDetails: state.carrousel.carrouselDetails,
    carrousel: state.carrousel.carrousel,
    error: state.carrousel.carrouselError,
    memberList: state.member.memberSelectList,
    memberGroupList: state.memberGroup.memberGroupSelectList,
    roleList: state.role.roleList,
  }));

  const initialValues = {
    member: entityId
      ? getInitialMemberValues(carrouselDetails, true)
      : [
          {
            value: "all",
            label: "All Members",
            secondaryLabel: "",
            type: "all",
          },
        ],
    title: entityId ? carrouselDetails?.title || "" : "",
    description: entityId ? carrouselDetails?.description || "" : "",
    url: entityId ? carrouselDetails?.url || "" : "",
    user_scope: entityId
      ? carrouselDetails?.user_scope || "all_users"
      : "all_users",
    user_roles: entityId && carrouselDetails?.portal_user_roles?.length
      ? carrouselDetails?.portal_user_roles?.map(r => ({ value: r?.id, label: r?.name })) || [{ value: "0", label: "All Roles" }]
      : [{ value: "0", label: "All Roles" }],
    image: entityId ? carrouselDetails?.image : "",
    pdf: entityId ? carrouselDetails?.file : "",
		active: entityId ? carrouselDetails?.active : true,
  };

const formSchema = Yup.object().shape({
  member: Yup.mixed(),
  title: Yup.string().required("Required field"),
  description: Yup.string().required("Required field"),
  url: Yup.string().when('pdf', {
    is: (pdf) => !pdf || !pdf.size,
    then: Yup.string().required("Required field"),
    otherwise: Yup.string()
  }),
  user_scope: Yup.string(),
  user_roles: Yup.array(),
  active: Yup.boolean(),
  image: Yup.mixed().required("Required field"),
  pdf: Yup.mixed()
    .when('url', {
      is: (url) => !url || url === '',
      then: Yup.mixed().required("Required field")
        .test(
          "is-correct-size",
          "File too large",
          (value, _) => (value?.size || 0) <= MAX_FILE_SIZE
        )
        .test(
          "is-correct-format",
          "Invalid file extension",
          (value, _) =>
            VALID_FILE_TYPES_PDF.includes(value?.type || value?.mime) || !value
        ),
      otherwise: Yup.mixed()
    })
}, [['url', 'pdf']]);
      
  const onSubmitForm = async (values, { resetForm }) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    console.log('values', values)

    const {
      member = [],
      title,
      description,
      url,
      image,
      user_scope,
      user_roles,
			active,
      pdf,
    } = values;

    const { locations, location_groups, is_all_locations } =
      processMemberOptions(member);

    let portal_user_roles = user_roles.map((role) => role.value);

    const hasAll = user_roles.map((r) => r.value).includes("0");

    if (hasAll) {
      portal_user_roles = [];
    }

    const formBody = {
      locations,
      location_groups,
      is_all_locations,
      title,
      description,
      user_scope,
      portal_user_roles,
			active,
      url,
      image,
      pdf
    };

    if (showFileInput) {
      formBody.url = "";
    } else {
      formBody.pdf = "";
    }

    formData.append("carrousel", JSON.stringify(formBody));
    formData.append("image", image);
    formData.append("pdf", pdf);

    if (entityId) {
      await carrouselAction.update(dispatch, entityId, formData);
    } else {
      await carrouselAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    memberAction.fetchSelectOptions(dispatch, { isDropdown: true });
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
    roleAction.fetchSelectOptionsWebPortal(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      carrouselAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  // If entity details are fetched and the entity url is null, then show the file input
  useEffect(() => {
    console.log('carrouselDetails', carrouselDetails)
    if (carrouselDetails?.url === null || carrouselDetails?.url === "" || carrouselDetails?.url === undefined) {
      setShowFileInput(true);
    } else {
      setShowFileInput(false);
    }
  }, [carrouselDetails]);

  useEffect(() => {
    setMemberOptions(getMemberOptions(memberGroupList, memberList));
  }, [JSON.stringify(memberGroupList), JSON.stringify(memberList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && carrousel) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, carrousel, submittedForm, navigate]);

  useEffect(() => {
    setRoleOptions(getRoleOptions(roleList));
  }, [JSON.stringify(roleList)]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
          resetForm,
        } = formik;

  const handleRadioChange = (event) => {

    if (event.target.value === "url") {
      setShowFileInput(false);
    }
    else {
      setShowFileInput(true);
    }
  }
        return (
          <div className="carrousel">
            <div className="carrousel-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {entityId
                    ? t("carrousel.editSuccess")
                    : t("carrousel.createSuccess")}
                </Alert>
              )}
              <div className="carrousel-header">
                <h1 className="carrousel-title">
                  {entityId
                    ? t("carrousel.editCarrousel")
                    : t("carrousel.newCarrousel")}
                </h1>
								<div className="form-check form-switch">
									<Form.Check
										disabled={disableSubmit}
										bsPrefix="form-custom-switch"
										type="switch"
										name="value"
										onChange={(e) => {
											setFieldValue("active", e.target.checked);
										}}
										value={values.active}
										checked={values.active}
										id="active-switch"
										label={values.active ? "Active" : "Inactive"}
									/>
								</div>
              </div>
              <Form className="carrousel-form">
                <div className="carrousel-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("carrousel.title")}</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("carrousel.publishedIn")}</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        isMulti
                        styles={customStyles}
                        value={values.member}
                        onChange={(option) => setFieldValue("member", option)}
                        closeMenuOnSelect={false}
                        onBlur={handleBlur}
                        options={memberOptions}
                        isInvalid={errors.member && touched.member}
                        formatGroupLabel={formatGroupLabel}
                        getOptionLabel={(option) => (
                          <div className="form-select-custom-label">
                            <span className="form-select-custom-label-primary">
                              {option.label}
                            </span>
                            <span className="form-select-custom-label-secondary">
                              {option.secondaryLabel}
                            </span>
                          </div>
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.member}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("carrousel.description")}</Form.Label>
                      <Form.Control
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </div>
             

                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("carrousel.specificroles")}</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        isMulti
                        styles={customStyles}
                        value={values.user_roles}
                        onChange={(option) => {
                          const hasAll = option
                            .map((r) => r.value)
                            .includes("0");
                          setFieldValue("user_roles", option);
                          if (hasAll) {
                            setFieldValue(
                              "user_roles",
                              option.filter((r) => r.value === "0")
                            );
                          }
                        }}
                        closeMenuOnSelect={false}
                        onBlur={handleBlur}
                        options={roleOptions}
                        isOptionDisabled={() =>
                          values.user_roles.map((r) => r.value).includes("0")
                        }
                        isInvalid={errors.user_roles && touched.user_roles}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.user_roles}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input carrousel-radio-group">
                      <Form.Label>{t("carrousel.visiblefor")}</Form.Label>
                      <div className="form-row">
                        <div className="form-radio-group">
                          <Form.Check
                            type="radio"
                            id="radio1"
                            bsPrefix="form-check-input-custom"
                            label="All Users"
                            name="user_scope"
                            value="all_users"
                            onChange={handleChange}
                            checked={values.user_scope === "all_users"}
                          />
                          <Form.Check
                            type="radio"
                            id="radio2"
                            bsPrefix="form-check-input-custom"
                            label="Only Registered Users"
                            name="user_scope"
                            value="registered_users"
                            onChange={handleChange}
                            checked={values.user_scope === "registered_users"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-input carrousel-radio-group">
                      <div className="form-row">
                        <div className="form-radio-group">
                          <Form.Check
                            type="radio"
                            id="radio1_file"
                            bsPrefix="form-check-input-custom"
                            label="URL"
                            name="urlOrFile"
                            value="url"
                            // onChange={setShowFileInput(false)}
                            onChange={handleRadioChange}
                            checked={!showFileInput}
                          />
                          <Form.Check
                            type="radio"
                            id="radio2_file"
                            bsPrefix="form-check-input-custom"
                            label="File"
                            name="urlOrFile"
                            value="file"
                            // onChange={setShowFileInput(true)}
                            onChange={handleRadioChange}
                            checked={showFileInput}
                          />
                        </div>
                      </div>
                    </div>
                    </div>

                  <div className="form-row"
                  // style={{ display: "flex", flexDirection: "column" }}
                  >
                    {
                      !showFileInput &&
                      (<div className="form-input">
                        <Form.Label>{t("carrousel.url")}</Form.Label>
                        <Form.Control
                          name="url"
                          value={values.url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={errors.url && touched.url}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.url}
                        </Form.Control.Feedback>
                      </div>)}
                    {
                      showFileInput && (

                          <div className="form-input">
                            <FileInput
                              title="Browse a File"
                              allowedTypes={"PDF"}
                              value={values?.pdf}
                              customFieldName={"pdf"}
                              setFieldValue={setFieldValue}
                              errorMessage={errors.pdf}
                            />
  <div
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: ".875em",
                            color: "#dc3545",
                            display: "block",
                          }}
                        > {errors.pdf}</div>
                          </div>
                     
                      )
                    }
                    {/* {
                      showFileInput && (
                        <p
                          style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: ".875em",
                            color: "#dc3545",
                            display: "block",
                          }}
                        > {errors.pdf}</p>
                      )
                    } */}
                  </div>

                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("carrousel.image")}</Form.Label>
                      <FileInput
                        title="Browse an Image"
                        allowedTypes={"PNG, JPG"}
                        customFieldName={"image"}
                        value={values.image}
                        setFieldValue={setFieldValue}
                        errorMessage={errors}
                      />
                      <div
                       style={{
                            width: "100%",
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "#dc3545",
                       }}
                       > {errors.image}</div>
  
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      handleSubmit(e);
                      // clean up alerts

                      setCustomResetForm(() => resetForm);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default CarrouselForm;
