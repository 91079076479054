import { Alert } from 'react-bootstrap';
import "./unauthorizedAlert.scss";

const UnauthorizedAlert = () => {
	return (
		<div className="unauthorized-alert-container">
			<div className="unauthorized-alert">
				<Alert variant="secondary">
					You do not have access to this page.
				</Alert>
			</div>
		</div>
	);
};

export default UnauthorizedAlert;