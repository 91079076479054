
const TEMPLATE_URL = 'https://miiaprod.blob.core.windows.net/miia-prod-public/Bulk template.xlsx'

export const downloadTemplateFile = async () => {
	const link = document.createElement('a');
	link.style.display = 'none';
	// Set the link's attributes
	link.href = TEMPLATE_URL;
	link.download = 'Bulk template.xlsx';
	// Add the link to the DOM
	document.body.appendChild(link);
	// Click the link to trigger the download
	link.click();
	// Remove the link from the DOM
	document.body.removeChild(link);
};
