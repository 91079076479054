import { createSlice } from "@reduxjs/toolkit";

const carrouselReducer = createSlice({
  name: "carrousel",
  initialState: {
    carrouselDetails: null,
    carrousel: null,
    carrouselList: null,
    carrouselMetadata: null,
    carrouselError: "",
    listLoading: false,
    initialDuration: null,
  },
  reducers: {
    setCarrouselDetail: (state, action) => {
      state.carrouselDetails = action.payload.data;
      state.carrouselError = null;
    },
    setCarrouselList: (state, action) => {
      state.carrouselList = action.payload.data;
      state.carrouselError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.carrouselMetadata = action.payload.meta;
    },
    setCarrousel: (state, action) => {
      state.carrousel = action.payload.data;
      state.carrouselError = null;
    },
    setCarrouselError: (state, action) => {
      state.carrouselError = action.payload.error;
    },
    cleanCarrouselState: (state) => {
      state = carrouselReducer.getInitialState();
    },
    setInitialDuration: (state, action) => {
      state.initialDuration = action.payload.data;
      state.carrouselError = null;
    },
  },
});

export const {
  setCarrouselDetail,
  setCarrouselList,
  setCarrouselError,
  setCarrousel,
  setMetadata,
  cleanCarrouselState,
  setListLoading,
  setInitialDuration,
} = carrouselReducer.actions;

export default carrouselReducer.reducer;
