// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchased-products .purchased-products-title {
  margin: 1em;
}
.purchased-products .form-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #edeceb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  height: 50px;
  padding-left: 20px;
  margin-top: 0px !important;
}
.purchased-products .purchased-products-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 1px 0px;
  grid-auto-flow: row;
}`, "",{"version":3,"sources":["webpack://./src/components/views/pages/PurchasedProducts/purchasedProducts.scss"],"names":[],"mappings":"AACC;EACC,WAAA;AAAF;AAGC;EACG,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,+CAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;AADJ;AAIC;EACC,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;AAFF","sourcesContent":[".purchased-products {\n\t.purchased-products-title{\n\t\tmargin: 1em;\n\t}\n\n\t.form-check {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    background: #edeceb 0% 0% no-repeat padding-box;\n    border-radius: 3px;\n    opacity: 1;\n    height: 50px;\n    padding-left: 20px;\n    margin-top: 0px !important;\n  }\n\t\n\t.purchased-products-container{\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 1fr 1fr;\n\t\tgrid-auto-columns: 1fr;\n\t\tgap: 1px 0px;\n\t\tgrid-auto-flow: row;\t\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
