import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as articleAction from "../../../../redux/actions/article.action";
import { fetchSASToken } from "../../../../services/SASTokenService";
import { useTranslation } from "react-i18next";
import List from "../../../list/list";
import placeholder from "../../../../assets/images/placeholder.jpg";

const articleFields = [
  //list will sort by the first element by default
  "title",
  "author",
  "publishedAt",
];
const ArticleList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [updatedArticles, setUpdatedArticles] = useState([]);

  const { articles, meta, listLoading } = useSelector((state) => ({
    articles: state.article.articleList,
    meta: state.article.articleMetadata,
    listLoading: state.article.listLoading,
  }));


  useEffect(() => {
    articleAction.fetchAll(dispatch);
    const getSasToken =  async () => {
      const fetchedToken = await fetchSASToken();
      setToken(fetchedToken);
    } 
    getSasToken();
  }, [dispatch]);


  useEffect(() => {
    if (articles?.data && articles?.data?.length > 0 && token) {
      setUpdatedArticles ( articles?.data?.map(
        ({ id, attributes: { image, title, author, publishedAt, order } }) => ({
          id,
          image: 
image?.data?.attributes?.formats?.thumbnail?.url ||
          image?.data?.attributes?.url
            ? `${image?.data?.attributes?.formats?.thumbnail?.url || image?.data?.attributes?.url}?${token}`
            : placeholder,
          columns: [{ primary: title }, { primary: author }],
          draft: publishedAt === null,
          name: title,
          order,
        })
      ) )

    }
  } , [articles, meta, token, dispatch])

  return (
    <List
      redirect="/articles/form"
      title={t("articles.articles")}
      isOverview={false}
      columnNames={["","IMAGE", "TITLE", "AUTHOR"]}
      hasImage={true}
      entities={updatedArticles}
      tooltipTextDelete="Delete Article"
      fieldsToSort={articleFields}
      fetchAction={(params) =>  { setUpdatedArticles([]); articleAction.fetchAll(dispatch, params)  }}
      reOrderAction={(body, filterParams) =>{articleAction.reOrder(dispatch, body, filterParams) }}
      metadata={meta}
      deleteAction={(id, params) => articleAction.remove(dispatch, id, params)}
      loading={listLoading}
      sortIsDisabled={false}
    />
  );
};

export default ArticleList;
