import "../tabs/index.css";
import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import charterSchool from "../resources/svgs/charter-school.svg";
import city from "../resources/svgs/city.svg";
import housing from "../resources/svgs/housing-authority.svg";
import regional from "../resources/svgs/regional-school-district.svg";
import vocational from "../resources/svgs/vocational-technical-school.svg";
import county from "../resources/svgs/county.svg";
import emergency from "../resources/svgs/emergency-communications-district.svg";
import retirement from "../resources/svgs/retirement-system.svg";
import collaborative from "../resources/svgs/collaborative.svg";
import fire from "../resources/svgs/fire-district.svg";
import planning from "../resources/svgs/planning-commission.svg";
import town from "../resources/svgs/town.svg";
import water from "../resources/svgs/water-sewer-district.svg";
import other from "../resources/svgs/other.svg";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

const Type = ({ setTab, childToParent }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const initialValues = {
    type: "",
  };

  const formSchema = Yup.object().shape({
    type: Yup.string().required("Required field"),
  });

  const onSubmitForm = () => {
    childToParent("type", selected);
  };

  const images = [
    { name: "Charter School", value: "Charter School", image: charterSchool },
    { name: "County", value: "County", image: county },
    { name: "Housing Authority", value: "Housing Authority", image: housing },
    {
      name: "Regional School District",
      value: "Regional School District",
      image: regional,
    },
    {
      name: "Vocational Technical School",
      value: "Vocational Technical School",
      image: vocational,
    },
    { name: "City", value: "City", image: city },
    {
      name: "Emergency",
      value: "Emergency Communications District",
      image: emergency,
    },
    { name: "Retirement", value: "Retirement System", image: retirement },
    { name: "Collaborative", value: "Collaborative", image: collaborative },
    { name: "Fire", value: "Fire District", image: fire },
    { name: "Planning", value: "Planning Commission", image: planning },
    { name: "Town", value: "Town", image: town },
    { name: "Water", value: "Water/Sewer District", image: water },
    { name: "Other", value: "Other", image: other },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(onSubmitForm, handleSubmit)}
    >
      {(formik) => {
        const { values } = formik;

        return (
          <div>
            <div className="title-members-create">
              <h3>Member Type</h3>
              <label>Please select the type of member</label>
            </div>
            <Container className="container-type-creation-member">
              <Row>
                {images.map((image, index) => (
                  <Col key={index} className="col-type-create-member">
                    <button
                      className={
                        selected === image.value
                          ? " box-imgs-type selected"
                          : "box-imgs-type"
                      }
                      id={image.name}
                      onClick={() => {
                        if (selected === image.value) {
                          setSelected(
                            selected.filter((item) => item !== image.value)
                          );
                        } else {
                          setSelected(image.value);
                        }
                      }}
                    >
                      <img
                        className="img-type"
                        src={image.image}
                        alt={image.value}
                      />
                      <p className="p-type">{image.value}</p>
                    </button>
                  </Col>
                ))}
              </Row>
            </Container>
            <div className="member-form-action-group">
              <Button
                variant="primary"
                onClick={() => navigate("/members")}
                className="form-action-cancel"
              >
                Cancel
              </Button>
              <div className="form-subgroup">
                <Button
                  onClick={() => {
                    setTab("general");
                  }}
                  className="form-action"
                  variant="primary"
                >
                  Back
                </Button>
                <Button
                  onClick={() => {
                    if (!selected?.length) {
                      setTab("type");
                    } else {
                      selected?.length > 0 && onSubmitForm(values);
                      setTab("contact");
                    }
                  }}
                  disabled={!selected?.length}
                  type="submit"
                  className="form-action form-action-primary"
                  variant="outline-primary"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Type;
