import * as action from "../reducers/tagGroupReducer";
import axios, { authHeader } from "../../services/HTTPService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  { fieldTofilter, filter, fieldToSort, sortDirection, page, pageSize } = {
    fieldTofilter: "title",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/tag-groups/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setTagGroupList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setTagGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/tag-groups/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setTagGroupDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setTagGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/tag-groups", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setTagGroup({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setTagGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/tag-groups/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setTagGroup({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setTagGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const remove = async (dispatch, id, params) => {
  return axios
    .delete(API_URL + `/api/tag-groups/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
    })
    .catch((error) => {
      dispatch(
        action.setTagGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const fetchSelectOptions = async (dispatch) => {
  return axios
    .get(API_URL + `/api/tag-groups/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setTagGroupList({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setTagGroupError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};
