/* eslint-disable import/no-anonymous-default-export */
export default {
  overview: {
    overviewtitle: "Overview",
    overviewallmembers: "Overview for All Members",
    members: "Members",
    portalusers: "Portal Users",
    notifications: "Notifications",
    createnewmember: "Create a new member",
    createanewnotification: "Create a new notification",
    miiacontact: "MIIA Contacts",
  },
  dropdowns: {
    delete: "Delete Dropdown",
    yesdelete: "Yes, delete it",
    deletequestion:
      "Are you sure you want to delete {{entity}}? You won't be able to undo this action.",
    dropdowns: "Dropdowns",
    successAction: "Dropdown list has been updated successfully",
  },
  buttons: {
    cancel: "Cancel",
    back: "Back",
    save: "Save",
    edit: "Edit",
    confirm: "Confirm",
    delete: "Delete",
    new: "New",
    draft: "Save as Draft",
    exit: "Exit",
    unlock: "Enable",
    unblock: "Enable",
    send: "Send",
    publish: "Publish",
    clearnews: "Clear this News",
    savepublish: "Save & Publish",
  },
  recipes: {
    recipes: "Recipes",
    source: "Source",
    image: "Featured Image",
    ingredients: "Ingredients",
    intructions: "Instructions",
    description: "Description",
    title: "Title",
    editSuccess: "Recipe edited successfully.",
    createSuccess: "Recipe created successfully.",
    errorEditing: "Error editing recipe",
    creatingEditing: "Error creating recipe",
    editRecipe: "Edit Recipe",
    newRecipe: "New Recipe",
    printThisRecipe: "Print this recipe",
  },
  programs: {
    title: "Title",
    description: "Description",
    image: "Featured Image",
    name: "Name",
    programs: "Programs",
    tooltip: "Tooltip",
    link: "Link",
    createSuccess: "Program created successfully.",
    editSuccess: "Program edited successfully.",
  },
  articles: {
    articles: "Articles",
    author: "Author",
    image: "Featured Image",
    tags: "Tags",
    content: "Content",
    description: "Description",
    title: "Title",
    publishedIn: "Published in",
    editSuccess: "Article edited successfully.",
    createSuccess: "Article created successfully.",
    errorEditing: "Error editing article",
    creatingEditing: "Error creating article",
    editArticle: "Edit Article",
    newArticle: "New Article",
    more: "More",
  },
  carrousel: {
    carrousel: "Carousel Slides",
    image: "Background",
    titleCarousel: "Carousel",
    author: "Author",
    title: "Title",
    description: "Short description",
    url: "URL",
    active: "Active",
    publishedIn: "Visible in",
    editSuccess: "Slide edited successfully.",
    createSuccess: "Slide created successfully.",
    errorEditing: "Error editing slide",
    creatingEditing: "Error creating slide",
    editCarrousel: "Edit Slide",
    newCarrousel: "New Slide",
    visiblefor: "Visible for",
    specificroles: "Send to specific roles",
  },
  news: {
    news: "Featured New",
    mma_url: "MMA URL",
    image: "Featured Image",
    category_name: "Category Name",
    category_url: "Category URL",
    title: "Title",
    publishedIn: "Published in",
    editSuccess: "Featured New edited successfully.",
    errorEditing: "Error editing Featured New",
    create: "New Featured News",
    edit: "Edit Featured News",
    more: "More",
  },
  newsletters: {
    editNewsletter: "Edit Newsletter",
    link: "Link",
    image: "Image",
    title: "Title",
    save: "save",
    editSuccess: "Newsletter edited successfully.",
  },
  tags: {
    tagGroups: "Tag Groups",
    tags: "Tags",
    editSuccess: "Tag Group edited successfully.",
    createSuccess: "Tag Group created successfully.",
    errorEditing: "Error editing tag group",
    errorCreating: "Error creating tag group",
    editTagGroup: "Edit Tag Group",
    newTagGroup: "New Tag Group",
    editTagSuccess: "Tag edited successfully.",
    createTaguccess: "Tag created successfully.",
    errorEditingTag: "Error editing tag group",
    errorCreatingTag: "Error creating tag group",
    editTag: "Edit Tag",
    newTag: "New Tag",
    title: "Title",
    includetags: "Includes these Tags",
    includeGroups: "Include it in these groups",
  },
  contacts: {
    contacts: "Contacts",
    email: "Email",
    phone: "Phone",
    position: "Position",
    department: "Department",
    fullname: "Full Name",
    published: "Published in",
    newcontact: "New MIIA Contact",
    editcontact: "Edit MIIA Contact",
    editContactSuccess: "Contact edited successfully.",
    createContactSuccess: "Contact created successfully.",
    errorEditingContact: "Error editing contact",
    errorCreatingContact: "Error creating tag contact",
  },
  modalconfirmation: {
    yesdelete: "Yes, disable it",
    yesUnblock: "Yes, enable it",
    titleUnblock: "Enable",
    unblockquestion:
      "Are you sure you want to enable {{entity}}?. The user will be able to access the portal.",
    titleDelete: "Disable",
    titleExit: "Unsaved changes",
    deletequestion:
      // "Are you sure you want to disable {{entity}}?. The user will no longer be able to access the portal.",
      "Are you sure you want to {{action}} {{entity}}?. {{warning}}",
    exitquestion:
      "Are you sure you want to exit? You will lose the current changes.",
    keepediting: "Keep Editing/Creating",
    exit: "Exit",
    continue: "Continue Creating/Editing",
    yesleave: "Yes, leave",
    cancel: "Cancel",
  },
  healthPortal: {
    healtportaluser: "Health Portal Users",
    newportal: "New Health Portal",
    published: "Published in",
    title: "Title",
    members: "Members",
    allmembers: "All Members",
  },
  notifications: {
    notifications: "Notifications",
    content: "Content",
    specificroles: "Send to specific roles",
    visiblefor: "Visible for",
    endfor: "End for",
    startdate: "Start date",
    url: "URL",
    type: "Type",
    published: "Published in",
    newnotification: "New Notification",
    editnotification: "Edit Notification",
    createSuccess: "Notification created successfully.",
    editSuccess: "Notification edited successfully.",
    errorsending: "Error sending notification",
  },
  users: {
    users: "Users",
    newportalusers: "New Web Portal User",
    newusers: "New Admin Portal User",
    editportalusers: "Edit Web Portal User",
    editusers: "Edit Admin Portal User",
    name: "Name",
    lastname: "Last Name",
    email: "Email",
    role: "Role",
    title: "Title",
    password: "Password",
    settings: "Access settings",
    sectionsandreports: "SECTIONS AND REPORTS",
    useradministration: "USERS ADMINISTRATION",
    ssoacces: "SSO ACCESS",
  },
  bulks: {
    bulks: "Bulk options",
    cancel: "Cancel",
    back: "Back",
    save: "Save",
    edit: "Edit",
    confirm: "Confirm",
    delete: "Delete",
    new: "New",
    draft: "Save as Draft",
    exit: "Exit",
    send: "Send",
    publish: "Publish",
    clearnews: "Clear this News",
    savepublish: "Save & Publish",
  },
  members: {
    members: "Members",
  },
  utils: {
    by: "By",
  },
};
