import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as linkAction from "../../../../redux/actions/link.action";
import List from "../../../list/list";

const linkFields = [
  //list will sort by the first element by default
  "name",
  "link",
  "publishedAt",
];

const LinkList = ({ member = null }) => {
  const dispatch = useDispatch();

  const initialParams = {
    fieldTofilter: "name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    member,
  };

  const { links, meta, listLoading } = useSelector((state) => ({
    links: state.link.linkList,
    meta: state.link.linkMetadata,
    listLoading: state.link.listLoading,
  }));

  useEffect(() => {
    linkAction.fetchAll(dispatch, initialParams);
  }, [dispatch, member]);

  return (
    <List
      title="Quick Access Links"
      tooltipTextDelete="Delete Quick Access Link"
      redirect={member ? `/quick-access/member/${member}/form` : `/quick-access/form`}
      columnNames={["NAME", "LINK"]}
			entities={links?.data?.map(({ id, attributes: { name, link } }) => ({
        id,
				columns: [
					{ primary: name },
          { primary: link },
				],
        name,
      }))}
      fieldsToSort={linkFields}
      fetchAction={(params) => linkAction.fetchAll(dispatch, {...params})}
      isOverview={false}
      metadata={meta}
      showFilterTab={false}
      member={member}
      deleteAction={(id, params) => linkAction.remove(dispatch, id, params)}
      listLoading={listLoading}
    />
  );
};

export default LinkList;
