import "../tabs/index.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import addButton from "../../membersCreate/resources/svgs/add-buttons.svg";
import addPage from ".././resources/svgs/add-page.svg";
import userPlus from ".././resources/svgs/user-plus.svg";
import addQuickAccess from "../resources/svgs/add-quick-access.svg";
import { useNavigate } from "react-router-dom";

const Done = ({ memberId }) => {
  const navigate = useNavigate();
  const images = [
    { name: "Create Web Portal Users", link: `/web-portal-users/member/${memberId}/form`, image: userPlus },
    { name: "Add a quick access to the Web Portal", link: `/quick-access/member/${memberId}/form`, image: addQuickAccess },
    { name: "Add buttons to the Web Portal home page", link: `/buttons-in-home/member/${memberId}/form`, image: addButton },
    { name: "Add a page", link: `/pages/member/${memberId}/form`, image: addPage },
  ];

  const handleSubmitInfo = (e) => {
    e.preventDefault();
    memberId && navigate(`/members/form/${memberId}`);
  };
  return (
    <div className="all-container-member-create done">
      <div className="title-members-create">
        <h3>Done</h3>
        <label>All the required information was completed</label>
      </div>
      <Container className="container-type-creation-member done-4-row">
        <Row>
          {images.map((image, index) => (
            <Col key={index} className="col-type-create-member">
              <div onClick={() => navigate(image.link)} className="box-imgs-type box-imgs-type-done">
                <img src={image.image} alt={image.name} />
                <p>{image.name}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="box-3-create-member btn-type-create-member">
        <Button
          type="submit"
          className="form-action btn-create-member"
          variant="outline-primary"
          onClick={handleSubmitInfo}
        >
          Go to Member Edition 
        </Button>
      </div>
    </div>
  );
};

export default Done;
