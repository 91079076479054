import { createSlice } from "@reduxjs/toolkit";

const tagReducer = createSlice({
  name: "tag",
  initialState: {
    tagDetails: null,
    tag: null,
    tagList: null,
    tagMetadata: null,
    tagError: '',
    listLoading: false,
  },
  reducers: {
    setTagDetail: (state, action) => {
      state.tagDetails = action.payload.data;
      state.tagError = null;
    },
    setTagList: (state, action) => {
      state.tagList = action.payload.data;
      state.tagError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.tagMetadata = action.payload.meta;
    },
    setTag: (state, action) => {
      state.tag = action.payload.data;
      state.tagError = null;
    },
    setTagError: (state, action) => {
      state.tagError = action.payload.error;
    },
    cleanTagState: (state) => {
      state = tagReducer.getInitialState();
    },
  },
});

export const {
  setTagDetail,
  setTagList,
  setTagError,
  setTag,
  setMetadata,
  cleanTagState,
  setListLoading,
} = tagReducer.actions;

export default tagReducer.reducer;