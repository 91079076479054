/* eslint-disable react-hooks/exhaustive-deps */
import "./notificationForm.scss";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import * as notificationAction from "../../../../redux/actions/notification.action";
import * as memberAction from "../../../../redux/actions/member.action";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import * as roleAction from "../../../../redux/actions/role.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import {
  getMemberOptions,
  processMemberOptions,
  getRoleOptions,
  getInitialMemberValues,
  formatGroupLabel,
} from "../../../../helpers/select";
import Badge from "react-bootstrap/esm/Badge";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from "../../../../helpers/select.custom";
import { formatDateInputField } from "../../../../helpers/date";
import * as productAction from "../../../../redux/actions/product.action";
import { FixedSizeList as List } from "react-window";


const badgeLabel = (label, color, badgeClass) => (
  <div className="notification-option">
    <span className="notification-option-label">{label}</span>
    <Badge className={`${badgeClass}-badge`}>{color}</Badge>
  </div>
);

const NotificationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [showExitModal, setShowExitModal] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const { entityId } = useParams();

  const {
    notificationDetails,
    notification,
    error,
    memberList,
    memberGroupList,
    roleList,
    productList,
  } = useSelector((state) => ({
    notificationDetails: state.notification.notificationDetails,
    notification: state.notification.notification,
    productList: state.product.productList,
    error: state.notification.notificationError,
    memberList: state.member.memberSelectList,
    memberGroupList: state.memberGroup.memberGroupSelectList,
    roleList: state.role.roleList,
  }));

  const badgeMappings = {
    info: { label: "Info", color: "Blue", badgeClass: "info" },
    warning: { label: "Warning", color: "Yellow", badgeClass: "warning" },
    error: { label: "Error", color: "Red", badgeClass: "danger" },
  };

  const initialValues = {
    member: entityId
      ? getInitialMemberValues(notificationDetails, true)
      : [
          {
            value: "all",
            label: "All Members",
            secondaryLabel: "",
            type: "all",
          },
        ],
    start_date: entityId
      ? formatDateInputField(notificationDetails?.start_date) || ""
      : "",
    end_date: entityId
      ? formatDateInputField(notificationDetails?.end_date) || ""
      : "",
    only_registered_users:
      entityId &&
      notificationDetails?.user_scope &&
      notificationDetails?.user_scope?.includes("registered_users")
        ? "registered_users"
        : "all_users",
    user_roles: entityId
      ? notificationDetails?.portal_user_roles?.length > 0
        ? notificationDetails?.portal_user_roles?.map((role) => ({
            value: role?.id,
            label: role?.name,
          }))
        : [{ value: "0", label: "All Roles" }]
      : [{ value: "0", label: "All Roles" }],
    url: entityId ? notificationDetails?.url || "" : "",
    content: entityId ? notificationDetails?.body || "" : "",
    product_operator: entityId
      ? notificationDetails?.product_operator || ""
      : "and",
    product: entityId
      ? notificationDetails?.products?.map((product) => ({
          value: product.id,
          label: product.name,
        }))
      : [],
    type: entityId
      ? {
          value: notificationDetails?.type,
          label: badgeLabel(
            badgeMappings[notificationDetails?.type]?.label,
            badgeMappings[notificationDetails?.type]?.color,
            badgeMappings[notificationDetails?.type]?.badgeClass
          ),
        }
      : {
          value: "info",
          label: badgeLabel(
            badgeMappings.info.label,
            badgeMappings.info.color,
            badgeMappings.info.badgeClass
          ),
        },
  };

  const formSchema = Yup.object().shape({
    member: Yup.mixed(),
    type: Yup.mixed().required("Required field"),
    start_date: Yup.string().required("Required field"),
    end_date: Yup.string().required("Required field"),
    user_scope: Yup.string(),
    user_roles: Yup.array(),
    content: Yup.string().required("Required field"),
    product_operator: Yup.string(),
    product: Yup.mixed(),
  });

  const onSubmitForm = async (values, { resetForm }) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const {
      member = [],
      type,
      start_date,
      end_date,
      only_registered_users,
      user_roles,
      content,
      url,
      product,
      product_operator,
    } = values;

    const { locations, location_groups, is_all_locations } =
      processMemberOptions(member);

    const colorMap = {
      info: "blue",
      warning: "yellow",
      danger: "red",
    };

    let portal_user_roles = user_roles.map((role) => role.value);

    const hasAllRoles = user_roles.map((r) => r.value).includes("0");

    if (hasAllRoles) {
      portal_user_roles = [];
    }

    let finalScope;

    if (is_all_locations) {
      if (hasAllRoles) {
        if (only_registered_users === "all_users") {
          finalScope = "all_users";
        } else {
          finalScope = "registered_users";
        }
      } else {
        finalScope = "all_registered_users_with_selected_role";
      }
    } else {
      if (hasAllRoles) {
        if (only_registered_users === "all_users") {
          finalScope = "all_users_from_selected_locations_or_locations_groups";
        } else {
          finalScope =
            "registered_users_from_selected_locations_or_locations_groups";
        }
      } else {
        finalScope =
          "registered_users_from_selected_locations_or_locations_groups_with_selected_role";
      }
    }

    // add https:// if not present in the url
    const finalUrl = url.startsWith("http") ? url : `https://${url}`;

    const formBody = {
      locations,
      location_groups,
      is_all_locations,
      type: type.value,
      color: colorMap[type.value],
      start_date,
      end_date,
      user_scope: finalScope,
      portal_user_roles,
      body: content,
      published: true,
      url: finalUrl,
      products: product?.map((product) => product.value),
      product_operator,
    };

    formData.append("data", JSON.stringify(formBody));

    if (entityId) {
      await notificationAction.update(dispatch, entityId, formData);
    } else {
      await notificationAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    memberAction.fetchSelectOptions(dispatch, { isDropdown: true });
    memberGroupAction.fetchSelectOptions(dispatch, { isDropdown: true });
    roleAction.fetchSelectOptionsWebPortal(dispatch);
    productAction.fetchSelectOptions(dispatch);
    if (entityId) {
      notificationAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setMemberOptions(getMemberOptions(memberGroupList, memberList));
  }, [JSON.stringify(memberGroupList), JSON.stringify(memberList)]);

  useEffect(() => {
    setRoleOptions(getRoleOptions(roleList));
  }, [JSON.stringify(roleList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && notification) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, notification, submittedForm, navigate]);

  const OPTION_HEIGHT = 45;
  const ROWS = 6;

  const MenuList = ({ options, children, getValue }) => {
    const [value] = getValue();
    const initialOffset =
      options.indexOf(value) !== -1
        ? Array.isArray(children) && children.length >= ROWS
          ? options.indexOf(value) >= ROWS
            ? options.indexOf(value) * OPTION_HEIGHT - OPTION_HEIGHT * 5
            : 0
          : 0
        : 0;

    return Array.isArray(children) ? (
      <List
        height={OPTION_HEIGHT * ROWS}
        itemCount={children.length}
        itemSize={OPTION_HEIGHT}
        initialScrollOffset={initialOffset}
      >
        {({ style, index }) => {
          return <div style={customStyles}>{children[index]}</div>;
        }}
      </List>
    ) : (
      <div>{children}</div>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          resetForm,
        } = formik;

        return (
          <div className="notification">
            <div className="notification-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {entityId
                    ? t("notifications.editSuccess")
                    : t("notifications.createSuccess")}
                </Alert>
              )}
              <div className="notification-header">
                <h1 className="notification-title">
                  {entityId
                    ? t("notifications.editnotification")
                    : t("notifications.newnotification")}
                </h1>
              </div>
              <Form className="notification-form">
                <div className="notification-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("notifications.published")}</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        isMulti
                        name="member"
                        // defaultMenuIsOpen={true}
                        styles={customStyles}
                        value={values.member}
                        onChange={(option) => setFieldValue("member", option)}
                        closeMenuOnSelect={false}
                        onBlur={handleBlur}
                        options={memberOptions}
                        isOptionDisabled={() => values?.member?.map((m) => m.value).includes("all")}
                        isInvalid={errors.member && touched.member}
                        components={{
                          MenuList,
                        }}
                        formatGroupLabel={formatGroupLabel}
                        getOptionLabel={(option) => (
                          <div className="form-select-custom-label">
                            <span className="form-select-custom-label-primary">
                              {option.label}
                            </span>
                            <span className="form-select-custom-label-secondary">
                              {option.secondaryLabel}
                            </span>
                          </div>
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.member}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <div className="form-input notification-products">
                        <Form.Label>Required Products</Form.Label>
                        <Select
                          theme={(theme) => getTheme(theme)}
                          className="form-select-custom notification-select"
                          value={values.product}
                          isMulti
                          styles={customStyles}
                          onChange={(option) =>
                            setFieldValue("product", option)
                          }
                          closeMenuOnSelect={true}
                          onBlur={handleBlur}
                          options={productList?.data?.map(
                            ({ id, attributes: { name } }) => ({
                              value: id,
                              label: name,
                            })
                          )}
                          isInvalid={errors.product && touched.product}
                        />
                        <span className="input-info-error">
                          {errors.product}
                        </span>
                      </div>
                      <div className="notification-operator-container">
                        <div className="notification-operator">
                          <span
                            className={
                              values.product_operator === "or"
                                ? "notification-operator-selected"
                                : undefined
                            }
                          >
                            OR
                          </span>
                          <Form.Check
                            bsPrefix="form-custom-switch"
                            type="switch"
                            value={values.product_operator}
                            onChange={() => {
                              setFieldValue(
                                "product_operator",
                                values.product_operator === "or" ? "and" : "or"
                              );
                            }}
                            onBlur={handleBlur}
                            id="product_operator"
                            name="product_operator"
                            checked={values.product_operator !== "or"}
                          ></Form.Check>
                          <span
                            className={
                              values.product_operator !== "or"
                                ? "notification-operator-selected"
                                : undefined
                            }
                          >
                            AND
                          </span>
                          <span className="notification-operator-feedback">{`Link will be displayed if the member purchased ${
                            values.product_operator === "or" ? "any" : "all"
                          } of the selected products`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("notifications.type")}</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        onChange={(option) => setFieldValue("type", option)}
                        closeMenuOnSelect={true}
                        onBlur={handleBlur}
                        options={[
                          {
                            value: "info",
                            label: badgeLabel(
                              badgeMappings.info.label,
                              badgeMappings.info.color,
                              badgeMappings.info.badgeClass
                            ),
                          },
                          {
                            value: "warning",
                            label: badgeLabel(
                              badgeMappings.warning.label,
                              badgeMappings.warning.color,
                              badgeMappings.warning.badgeClass
                            ),
                          },
                          {
                            value: "danger",
                            label: badgeLabel(
                              badgeMappings.error.label,
                              badgeMappings.error.color,
                              badgeMappings.error.badgeClass
                            ),
                          },
                        ]}
                        value={values.type}
                        isInvalid={errors.type && touched.type}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.type}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>{t("notifications.startdate")}</Form.Label>
                      <Form.Control
                        name="start_date"
                        type="date"
                        value={values.start_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.start_date && touched.start_date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.start_date}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>{t("notifications.endfor")}</Form.Label>
                      <Form.Control
                        type="date"
                        name="end_date"
                        value={values.end_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.end_date && touched.end_date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.end_date}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-input notification-radio-group">
                    <Form.Label>{t("notifications.visiblefor")}</Form.Label>
                    <div className="form-row">
                      <div className="form-radio-group">
                        <Form.Check
                          type="radio"
                          id="radio1"
                          bsPrefix="form-check-input-custom"
                          label="Registered and Unregistered Users"
                          name="only_registered_users"
                          value="all_users"
                          onChange={handleChange}
                          // disable if a role was selected
                          disabled={values.user_roles.some(
                            (r) => r.value !== "0"
                          )}
                          checked={values.only_registered_users === "all_users"}
                        />
                        <Form.Check
                          type="radio"
                          id="radio2"
                          bsPrefix="form-check-input-custom"
                          label="Only Registered Users"
                          value="registered_users"
                          name="only_registered_users"
                          onChange={handleChange}
                          checked={
                            values.only_registered_users ===
                              "registered_users" ||
                            values.user_roles.some((r) => r.value !== "0")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-input">
                    <Form.Label>{t("notifications.specificroles")}</Form.Label>
                    <Select
                      theme={(theme) => getTheme(theme)}
                      className="form-select-custom"
                      isMulti
                      styles={customStyles}
                      value={values.user_roles}
                      onChange={(option) => {
                        const hasAll = option.map((r) => r.value).includes("0");
                        setFieldValue("user_roles", option);
                        if (hasAll) {
                          setFieldValue(
                            "user_roles",
                            option.filter((r) => r.value === "0")
                          );
                        }
                      }}
                      closeMenuOnSelect={true}
                      onBlur={handleBlur}
                      options={roleOptions}
                      isOptionDisabled={() =>
                        values.user_roles.map((r) => r.value).includes("0")
                      }
                      isInvalid={errors.user_roles && touched.user_roles}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.user_roles}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-input">
                    <Form.Label>{t("notifications.url")}</Form.Label>
                    <Form.Control
                      name="url"
                      value={values.url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.url && touched.url}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.url}
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-area">
                    <Form.Label>{t("notifications.content")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="content"
                      value={values.content}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.content && touched.content}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.content}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default NotificationForm;
