import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdDragIndicator } from "react-icons/md";

const emptyAction = () => {};

const DraggableRowRepresentation = React.memo(
  ({
    iconOfDeleteAction='delete',
    tooltipTextDelete,
    tooltipTextUnDelete,
    columns,
    deleteAction,
    draft,
    deleted,
    showUnDeleteOption,
    unDeleteAction,
    filterParams,
    hasImage,
    hasListModal,
    id,
    kcUserId,
    image,
    isEditable,
    isRemovable,
    member,
    name,
    parentIndex,
    redirect,
    setBulkContext,
    setEntityName,
    setModalAction,
    setShow,
    setShowListItemsModal,
    showEmptySlot,
    tab,
    type,
    attributes,
    listeners,
    sortIsDisabled,
    order,
    setShowUnDeleteModal,
  }) => {
    return (
      <>

      {!sortIsDisabled && (
    
      <td {...attributes} {...listeners} style={{cursor: "grab"}}>
      <MdDragIndicator />
        </td>  

      )

      }
        {hasImage && (
          <td>
            <img className="list-image" src={image} alt={name} />
          </td>
        )}
        {columns?.length > 0 &&
          columns?.map(({ primary }, index) => (
            <td key={index}>
              {typeof primary === "string" ? (
                <span>{primary?.split("_").join(" ")}</span>
                ) : (
                  primary
                  )}
              {draft && index === 0 && (
                <Badge className="draft-badge">Draft</Badge>
                )}
            </td>
          ))}
        <td>
          <div className="list-element-actions">
            {showEmptySlot && (
              <a
                href="#"
                onClick={() => emptyAction(id, { ...filterParams })}
                className="list-empty"
              >
                Empty
              </a>
            )}
            {isEditable && (
              <Link to={`${redirect}/${id || ""}${tab ? "/" + tab : ""}`}>
                <span className="material-symbols-outlined list-element-action">
                  edit
                </span>
              </Link>
            )}
            {hasListModal && (
              <span
                onClick={() => {
                  setBulkContext({ bulk: id, type });
                  setShowListItemsModal(true);
                }}
                className="material-symbols-outlined list-element-action"
              >
                ballot
              </span>
            )}
            {isRemovable && !deleted && (
<OverlayTrigger
						key={parentIndex}
						placement='top'
						delay={{ show: 0, hide: 0 }}
						overlay={<Tooltip id='button-tooltip-delete-action'>{tooltipTextDelete}</Tooltip>}
					>
              <span
                onClick={() => {
                  setShow(true);
                  setEntityName(name);
                  setModalAction(() => () => {
                    deleteAction(id, {
                      ...filterParams,
                      member,
                    });
                    setShow(false);
                  });
                }}
                className="material-symbols-outlined list-element-action"
              >
                {iconOfDeleteAction}
              </span>
					</OverlayTrigger>
            )}
            {showUnDeleteOption && deleted && (
<OverlayTrigger
						key={parentIndex}
						placement='right'
						delay={{ show: 0, hide: 0 }}
						overlay={<Tooltip id='button-tooltip-delete-action'>{tooltipTextUnDelete}</Tooltip>}
					>
              <span
                onClick={() => {
                  setShowUnDeleteModal(true);
                  setEntityName(name);
                  setModalAction(() => () => {
                    unDeleteAction(
                      id,  {
                        ...filterParams,
                        member,
                      },
                      kcUserId
                    
                    );
                    setShowUnDeleteModal(false);
                  });
                }}
                className="material-symbols-outlined list-element-action"
              >
                lock_open_right
              </span>
</OverlayTrigger>
            )

            }
          </div>
        </td>
      </>
    );
  }
);

const DraggableRow = ({
  columns,
  tooltipTextDelete,
  tooltipTextUnDelete,
  iconOfDeleteAction,
  deleteAction,
  unDeleteAction,
  showUnDeleteOption,
  draft,
  filterParams,
  hasImage,
  hasListModal,
  id,
  image,
  isEditable,
  isRemovable,
  member,
  name,
  parentIndex,
  redirect,
  setBulkContext,
  setEntityName,
  setModalAction,
  setShow,
  setShowListItemsModal,
  showEmptySlot,
  tab,
  type,
  sortIsDisabled = true,
  order,
  deleted,
  metadata,
  setShowUnDeleteModal,
  kcUserId,
}) => {
  const { attributes, listeners, setNodeRef, transform } =
    useSortable({ id, disabled: sortIsDisabled });

  let style = {
    transform: CSS.Transform.toString(transform),
  };

  const orderOfFeatured = metadata?.orderOfFeatured || []
  const entityIsFeatured = orderOfFeatured.includes(order)

  if (entityIsFeatured && !sortIsDisabled) style = { ...style, borderLeft: "0.3rem solid #42A0DB", backgroundColor: "#F0F9FF"};
  if (entityIsFeatured && !sortIsDisabled) columns = [ ...columns, { primary: 'Featured' }]
  if (!entityIsFeatured && !sortIsDisabled) columns = [ ...columns, { primary: '' }]
  
  return (
    <tr
      key={parentIndex}
      ref={setNodeRef}
      style={style}
      // {...attributes}
      // {...listeners}
    >
      <DraggableRowRepresentation
        tooltipTextDelete={tooltipTextDelete}
        tooltipTextUnDelete={tooltipTextUnDelete}
        iconOfDeleteAction={iconOfDeleteAction}
        columns={columns}
        deleted={deleted}
        kcUserId={kcUserId}
        showUnDeleteOption={showUnDeleteOption}
        unDeleteAction={unDeleteAction}
        deleteAction={deleteAction}
        draft={draft}
        filterParams={filterParams}
        hasImage={hasImage}
        hasListModal={hasListModal}
        id={id}
        image={image}
        isEditable={isEditable}
        isRemovable={isRemovable}
        member={member}
        name={name}
        parentIndex={parentIndex}
        redirect={redirect}
        setBulkContext={setBulkContext}
        setEntityName={setEntityName}
        setModalAction={setModalAction}
        setShow={setShow}
        setShowUnDeleteModal={setShowUnDeleteModal}
        setShowListItemsModal={setShowListItemsModal}
        showEmptySlot={showEmptySlot}
        tab={tab}
        type={type}
        attributes={attributes}
        listeners={listeners}
        sortIsDisabled={sortIsDisabled}
        order={order}
      />

    </tr>
  );
};

export default DraggableRow;
