import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as userAction from "../../../../redux/actions/user.action";
import List from "../../../list/list";
import { Alert } from "react-bootstrap";

const userFields = [
  //list will sort by the first element by default
  "full_name",
  "email",
  "publishedAt",
];

const WebPortalUserList = ({ member }) => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState([]);

  const initialParams = {
    fieldTofilter: "full_name",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    backOfficeUser: false,
    member,
  };

  const { users, meta, listLoading, errorMessage, userHardDeleted } = useSelector((state) => ({
    users: state.user.userList,
    errorMessage: state.user.errorUser,
    meta: state.user.userMetadata,
    listLoading: state.user.listLoading,
    userHardDeleted: state.user.userHardDeleted,
  }));

  useEffect(() => {
    userAction.fetchAllPortalUsers(dispatch, initialParams);
    if (userHardDeleted) {
      setSuccessMessage("User deleted successfully");
    }
  }, [dispatch]);

  return (
    <>
      {
        successMessage && successMessage.length > 0 && (
          <Alert style={{marginTop: "0.5rem"}} variant="success">{successMessage}</Alert>
        )
      }
      {
        errorMessage && errorMessage.length > 0 && (
          <Alert style={{marginTop: "0.5rem"}} variant="danger">{errorMessage}</Alert>
        )
      }
      <List
        includeSortByBlocked={true}
        iconOfDeleteAction={"block"}
        title="Web Portal Users"
        redirect={`/web-portal-users/member/${member}/form`}
        columnNames={["FULL NAME", "DISABLED", "EMAIL", "ROLE"]}
        entities={users?.data?.map(
          ({
            id,
            attributes: { full_name, blockedOnKeycloak, email, portal_user_role, kcUserId, deleted },
          }) => ({
            id,
            columns: [
              { primary: full_name },
              { primary: blockedOnKeycloak ? "Yes" : "No" },
              { primary: email },
              { primary: portal_user_role?.data?.attributes?.name },
            ],
            name: full_name,
            kcUserId,
            deleted,
          })
        )}
        fieldsToSort={userFields}
        fetchAction={(params) =>
          userAction.fetchAllPortalUsers(dispatch, {
            ...params,
            backOfficeUser: false,
            member,
          })
        }
        isOverview={false}
        metadata={meta}
        member={member}
        showFilterTab={false}
        showUnDeleteOption={true}
        tooltipTextDelete={"Disable User"}
        tooltipTextUnDelete={"Enable User"}
        unDeleteAction={(id, params, kcUserId) =>
          userAction.unblockUser({ dispatch, strapiId: id, params: { ...params, backOfficeUser: false }, setSuccessMessage, keycloakId: kcUserId, redirectToList: true })
        }
        deleteAction={(id, params) =>
          userAction.blockPortalUser({ dispatch, id, params: { ...params, backOfficeUser: false }, setSuccessMessage })
        }
        loading={listLoading}
      />
    </>
  );
};

export default WebPortalUserList;
