import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as tagAction from "../../../../redux/actions/tag.action";
import List from "../../../list/list";
import { useTranslation } from "react-i18next";

const tagFields = [
  //list will sort by the first element by default
  "name",
  "publishedAt",
];

const TagList = () => {
  const dispatch = useDispatch();
	const { t } = useTranslation()

  const { tags, meta, listLoading } = useSelector((state) => ({
    tags: state.tag.tagList,
    meta: state.tag.tagMetadata,
    listLoading: state.tag.listLoading,
  }));

  useEffect(() => {
    tagAction.fetchAll(dispatch);
  }, [dispatch]);

  return (
    <List
      title={t('tags.tags')}
      redirect="/tags/form"
      tooltipTextDelete="Delete Tag"
      columnNames={["NAME"]}
			entities={tags?.data?.map(({ id, attributes: {name} }) => ({
        id,
				columns: [
					{ primary: name }
				],
        name
      }))}
      showFilterTab={false}
      fieldsToSort={tagFields}
      fetchAction={(params) => tagAction.fetchAll(dispatch, params)}
      isOverview={false}
      metadata={meta}
      deleteAction={(id, params) => tagAction.remove(dispatch, id, params)}
      loading={listLoading}
    />
  );
};

export default TagList;
