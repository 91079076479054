import "./memberGroupForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import * as memberAction from "../../../../redux/actions/member.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import {
  getOnlyMemberOptions,
  processMemberOptions,
  getInitialMemberValues,
} from "../../../../helpers/select";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { customStyles } from '../../../../helpers/select.custom';

const MemberGroupForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const { memberGroupDetails, memberList, memberGroup, error } = useSelector(
    (state) => ({
      memberGroupDetails: state.memberGroup.memberGroupDetails,
      memberList: state.member.memberSelectList,
      memberGroup: state.memberGroup.memberGroup,
      error: state.memberGroup.memberGroupError,
    })
  );
  const [showExitModal, setShowExitModal] = useState(false);
  const { t } = useTranslation();

  const onSubmitForm = (values) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const { title, members } = values;

    const { locations } = processMemberOptions(members);

    const formBody = {
      title,
      locations,
    };

    formData.append("locationGroup", JSON.stringify(formBody));

    if (entityId) {
      memberGroupAction.update(dispatch, entityId, formData);
    } else {
      memberGroupAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    memberAction.fetchSelectOptions(dispatch, { isDropdown: true });

    if (entityId) {
      //If we have an id then load data
      memberGroupAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setMemberOptions(getOnlyMemberOptions(memberList));
  }, [JSON.stringify(memberList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && memberGroup) {
      setRenderSuccessAlert(true);
      if(!entityId) {
        customResetForm();
      }
    }
  }, [entityId, memberGroup, submittedForm, navigate]);

  const initialValues = {
    title: entityId ? memberGroupDetails?.title || "" : "",
    members: entityId ? getInitialMemberValues(memberGroupDetails) : [],
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required field"),
    members: Yup.mixed(),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
          resetForm
        } = formik;

        return (
          <div className="member-group">
            <div className="member-group-container">
              {error && (
                <Alert variant="danger">
                  {error}
                </Alert>
              )}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {`Member Group ${
                    entityId ? "edited" : "created"
                  } successfully.`}
                </Alert>
              )}
              <div className="member-group-header">
                <h1 className="member-group-title">
                  {entityId ? "Edit Member Group" : "New Member Group"}
                </h1>
              </div>
              <Form className="member-group-form">
                <div className="member-group-form-layout">
                  <div className="form-column">
                    <div className="form-row">
                      <div className="form-input">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={errors.title && touched.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </div>
                      <div className="form-input">
                        <Form.Label>Includes these Members</Form.Label>
                        <Select
                          theme={(theme) => getTheme(theme)}
                          className="form-select-custom"
                          isMulti
                          styles={customStyles}
                          value={values.members}
                          onChange={(option) =>
                            setFieldValue("members", option)
                          }
                          closeMenuOnSelect={false}
                          onBlur={handleBlur}
                          options={memberOptions}
                          isInvalid={errors.members && touched.members}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.members}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if(formik.dirty && !renderSuccessAlert){
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default MemberGroupForm;
