import { Col, Row, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as productAction from "../../../../redux/actions/product.action";
import { createMember } from "../../../../redux/actions/member.action";
import { useNavigate } from "react-router-dom";
import "./purchasedProducts.scss";

const PurchasedProducts = ({ member }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { productList, purchasedProducts, error } = useSelector((state) => ({
    productList: state.product.productList,
    purchasedProducts: state.product.purchasedProducts,
    error: state.member.memberError,
  }));
  const formRef = useRef(null);

  useEffect(() => {
    if (member) {
      productAction.fetchPurchasedProducts(dispatch, member);
    }
    productAction.fetchSelectOptions(dispatch);
  }, []);

  const initialValues = {
    purchasedProducts: purchasedProducts?.data?.map(({ product }) =>
      product?.id.toString()
    ),
  };

  const formSchema = Yup.object().shape({
    purchasedProducts: Yup.array(),
  });

  const onSubmitForm = async () => {
    setDisableSubmit(true);

    const { values } = formRef?.current;

    const currentPurchasedProducts = purchasedProducts?.data?.map(
      ({ product }) => product?.id.toString()
    );

    const purchasedProductsToCreate = values?.purchasedProducts?.filter(
      (product) => !currentPurchasedProducts.find((p) => p === product)
    );

    const purchasedProductsToDelete = currentPurchasedProducts.filter(
      (p) => !values?.purchasedProducts?.find((product) => p === product)
    );

    if (purchasedProductsToCreate?.length > 0) {
      for (let i = 0; i < purchasedProductsToCreate.length; i++) {
        const product = purchasedProductsToCreate[i];
        await productAction.createPurchasedProduct(dispatch, {
          location: member,
          product,
        });
      }
    }

    //get ids of purchased products to delete

    const purchasedProductsToDeleteIds = purchasedProducts?.data
      ?.filter((purchasedProduct) =>
        purchasedProductsToDelete.find(
          (product) => product === purchasedProduct?.product?.id.toString()
        )
      )
      ?.map((purchasedProduct) => purchasedProduct?.id);

    if (purchasedProductsToDeleteIds?.length > 0) {
      for (let i = 0; i < purchasedProductsToDeleteIds.length; i++) {
        const id = purchasedProductsToDeleteIds[i];
        await productAction.deletePurchasedProduct(dispatch, id);
      }
    }

    setDisableSubmit(false);
    setSubmittedForm(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
      innerRef={formRef}
    >
      {(formik) => {
        const { values, handleChange, handleBlur, handleSubmit } = formik;

        return (
          <div className="purchased-products">
            <div className="purchased-products-title">
              <h3>Products</h3>
              <label>Select the products this member will have access to</label>
            </div>
            <div className="purchased-products-container">
              {productList?.data?.length > 0 &&
                productList?.data?.map(
                  ({ id, attributes: { name } }, index) => (
                    <div key={index} className="form-check form-switch">
                      <Form.Check
                        onBlur={handleBlur}
                        disabled={disableSubmit}
                        bsPrefix="form-custom-switch"
                        name="purchasedProducts"
                        type="switch"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={id}
                        checked={values.purchasedProducts?.includes(
                          id.toString()
                        )}
                        id={id}
                        label={name}
                      />
                    </div>
                  )
                )}
            </div>
            <div className="form-action-group">
              <Button
                type="submit"
                className="form-action form-action-primary"
                variant="outline-primary"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                disabled={disableSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default PurchasedProducts;
