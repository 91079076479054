import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { fetchSASToken } from "../../../../services/SASTokenService";
import "./recipePreview.scss";

const RecipePreview = ({ recipe }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      setToken(await fetchSASToken());
    };
    fetchToken();
  }, []);

  return (<Container fluid className="recipe-preview-container">
    <Container fluid className="container-img-recipe">
      <div
        style={{
          height: "350px",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${
            recipe.file?.url
              ? recipe.file?.url + "?" + token
              : URL.createObjectURL(recipe?.file || new Blob())
          })`,
        }}
      />
    </Container>
    <Container fluid>
      <Row className="recipe-content-detail">
        <Col sm={7} className="recipe-left">
          <h2>{recipe?.title}</h2>
          <p>
            <b>Instructions</b>
          </p>
          <ReactMarkdown children={recipe?.instructions} />
          {recipe?.source && <p>Source: {recipe?.source}</p>}
        </Col>
        <Col className="recipe-right">
          {/* <Container fluid className="box-print">
            <b className="label">{t("recipes.printThisRecipe")}</b>
          </Container> */}
          <Container fluid className="box-data">
            <h5>Ingredients</h5>
            <ReactMarkdown children={recipe?.ingredients} />
          </Container>
        </Col>
      </Row>
    </Container>
  </Container>);
};

export default RecipePreview;
