import { createSlice } from "@reduxjs/toolkit";

const tagGroupReducer = createSlice({
  name: "tagGroup",
  initialState: {
    tagGroupDetails: null,
    tagGroup: null,
    tagGroupList: null,
    tagGroupMetadata: null,
    tagGroupError: '',
    listLoading: false,
  },
  reducers: {
    setTagGroupDetail: (state, action) => {
      state.tagGroupDetails = action.payload.data;
      state.tagGroupError = null;
    },
    setTagGroupList: (state, action) => {
      state.tagGroupList = action.payload.data;
      state.tagGroupError = null;
    },
    setMetadata: (state, action) => {
      state.tagGroupMetadata = action.payload.meta;
    },
    setTagGroup: (state, action) => {
      state.tagGroup = action.payload.data;
      state.tagGroupError = null;
    },
    setTagGroupError: (state, action) => {
      state.tagGroupError = action.payload.error;
    },
    cleanTagGroupState: (state) => {
      state = tagGroupReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setTagGroupDetail,
  setTagGroupList,
  setTagGroupError,
  setTagGroup,
  setMetadata,
  cleanTagGroupState,
  setListLoading,
} = tagGroupReducer.actions;

export default tagGroupReducer.reducer;