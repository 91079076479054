// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f3f6f9;
  height: 100vh;
}

.content {
  height: calc(100vh - 8em);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/layout.scss","webpack://./src/sass/globals/mixins.scss"],"names":[],"mappings":"AAGA;ECIC,aAAA;EACA,sBAAA;EACA,2BDLoB;ECMpB,uBDNgC;EAChC,yBAAA;EACA,aAAA;AACD;;AAEA;EACC,yBAAA;EACA,WAAA;ECVA,aAAA;EACA,2BDUc;ECTd,uBDS0B;AAG3B","sourcesContent":["@import '../../sass/globals/mixins.scss';\n@import '../../sass/globals/globals.scss';\n\n.layout {\n\t@include flexColumn(flex-start, flex-start);\n\tbackground-color: $grey;\n\theight: 100vh;\n}\n\n.content {\n\theight: calc(100vh - 8em);\n\twidth: 100%;\n\t@include flex(flex-start, flex-start);\n}\n","@mixin flex($justify-content, $align-items) {\n\tdisplay: flex;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n\n@mixin flexColumn($justify-content, $align-items) {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: $justify-content;\n\talign-items: $align-items;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
