import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchSASToken = async () => {
  return axios
    .get(API_URL + "/api/media-access/get-sas-token")
    .then(({ data }) => {
      return data.meta;
    })
    .catch((error) => {
      return error;
    });
};
