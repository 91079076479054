import { createSlice } from "@reduxjs/toolkit";

const userReducer = createSlice({
  name: "user",
  initialState: {
    user: null,
    loginStatus: false,
    errorUser: null,
    infoUser: null,
    userList: null,
    userMetadata: null,
    userDetails: null,
    listLoading: false,
    userHardDeleted: false,
  },
  reducers: {
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload.loginStatus;
      state.errorUser = null;
      state.userHardDeleted = false;
    },
    setUserHardDeleted: (state, action) => {
      state.userHardDeleted = action.payload.userHardDeleted;
      state.errorUser = null;
    },
    setInfoUser: (state, action) => {
      state.infoUser = action.payload.infoUser;
      state.errorUser = null;
      state.userHardDeleted = false;
    },
    setUserError: (state, action) => {
      state.errorUser = action.payload.error;
      state.userHardDeleted = false;
    },
    setUser: (state, action) => {
      state.user = action.payload.data;
      state.userError = null;
      state.userHardDeleted = false;
    },
    setUserDetail: (state, action) => {
      state.userDetails = action.payload.data;
      state.userError = null;
      state.userHardDeleted = false;
    },
    setUserList: (state, action) => {
      state.userList = action.payload.data;
      state.userError = null;
      state.userHardDeleted = false;
    },
    setMetadata: (state, action) => {
      state.userMetadata = action.payload.meta;
      state.userHardDeleted = false;
    },
    cleanState: () => {
      return userReducer.initialState;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setLoginStatus,
  cleanState,
  setUserError,
  setInfoUser,
  setUserList,
  setMetadata,
  setUser,
  setUserDetail,
  setListLoading,
  setUserHardDeleted,
} = userReducer.actions;

export default userReducer.reducer;
