import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as memberGroupAction from "../../../../redux/actions/memberGroup.action";
import List from "../../../list/list";

const memberGroupFields = [
  //list will sort by the first element by default
  "title",
  "publishedAt",
];

const MemberGroupList = () => {
  const dispatch = useDispatch();

  const { memberGroups, meta, listLoading } = useSelector((state) => ({
    memberGroups: state.memberGroup.memberGroupList,
    meta: state.memberGroup.memberGroupMetadata,
    listLoading: state.memberGroup.listLoading,
  }));

  useEffect(() => {
    memberGroupAction.fetchAll(dispatch);
  }, [dispatch]);

  return (
    <List
      title="Member Groups"
      tooltipTextDelete="Delete Member Group"
      redirect="/member-groups/form"
      columnNames={["TITLE"]}
			entities={memberGroups?.data?.map(({ id, attributes: {title} }) => ({
        id,
				columns: [
					{ primary: title }
				],
        name: title,
      }))}
      showFilterTab={false}
      fieldsToSort={memberGroupFields}
      fetchAction={(params) => memberGroupAction.fetchAll(dispatch, params)}
      isOverview={false}
      metadata={meta}
      tooltipTextDelete="Delete Member Group"
      deleteAction={(id, params) => memberGroupAction.remove(dispatch, id, params)}
      loading={listLoading}
    />
  );
};

export default MemberGroupList;
