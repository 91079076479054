import { createSlice } from "@reduxjs/toolkit";

const bulkReducer = createSlice({
  name: "bulk",
  initialState: {
    bulkDetails: null,
    bulk: null,
    bulkList: null,
    bulkMetadata: null,
    bulkError: '',
    listLoading: false,
  },
  reducers: {
    setBulkDetail: (state, action) => {
      state.bulkDetails = action.payload.data;
      state.bulkError = null;
    },
    setBulkList: (state, action) => {
      state.bulkList = action.payload.data;
      state.bulkError = null;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
    setMetadata: (state, action) => {
      state.bulkMetadata = action.payload.meta;
    },
    setBulk: (state, action) => {
      state.bulk = action.payload.data;
      state.bulkError = null;
    },
    setBulkError: (state, action) => {
      state.bulkError = action.payload.error;
    },
    cleanBulkState: (state) => {
      state = bulkReducer.getInitialState();
    },
  },
});

export const {
  setBulkDetail,
  setBulkList,
  setBulkError,
  setBulk,
  setMetadata,
  cleanBulkState,
  setListLoading
} = bulkReducer.actions;

export default bulkReducer.reducer;