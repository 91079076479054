import "./tagForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import * as tagAction from "../../../../redux/actions/tag.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import * as tagGroupAction from "../../../../redux/actions/tagGroup.action";
import {
  getOnlyTagGroupOptions,
  processTagOptions,
  getInitialTagValues,
} from "../../../../helpers/select";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { customStyles } from '../../../../helpers/select.custom';

const TagForm = () => {
  const dispatch = useDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const { tagDetails, tagGroupList, tag, error } = useSelector((state) => ({
    tagDetails: state.tag.tagDetails,
    tagGroupList: state.tagGroup.tagGroupList,
    tag: state.tag.tag,
    error: state.tag.tagError,
  }));
  const [showExitModal, setShowExitModal] = useState(false);

  const onSubmitForm = (values) => {
    setDisableSubmit(true); //Disable submit button

    const formData = new FormData();

    const { groups = [], name } = values;

    const { tag_groups } = processTagOptions(groups);

    const formBody = {
      tag_groups,
      name,
    };

    formData.append("tag", JSON.stringify(formBody));

    if (entityId) {
      tagAction.update(dispatch, entityId, formData);
    } else {
      tagAction.create(dispatch, formData);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    tagGroupAction.fetchSelectOptions(dispatch);

    if (entityId) {
      //If we have an id then load data
      tagAction.fetchDetails(dispatch, entityId);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    setTagOptions(getOnlyTagGroupOptions(tagGroupList));
  }, [JSON.stringify(tagGroupList)]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && tag) {
      setRenderSuccessAlert(true);
      if (!entityId) {
        customResetForm();
      }
    }
  }, [entityId, tag, submittedForm, navigate]);

  const initialValues = {
    name: entityId ? tagDetails?.name || "" : "",
    groups: entityId ? getInitialTagValues(tagDetails) : [],
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required field"),
    groups: Yup.mixed(),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={formSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
          resetForm,
        } = formik;

        return (
          <div className="tag">
            <div className="tag-container">
              {error && <Alert variant="danger">{error}</Alert>}
              {renderSuccessAlert && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {entityId
                    ? t("tags.editTagSuccess")
                    : t("tags.createTaguccess")}
                </Alert>
              )}
              <div className="tag-header">
                <h1 className="tag-title">
                  {entityId ? t("tags.editTag") : t("tags.newTag")}
                </h1>
              </div>
              <Form className="tag-form">
                <div className="tag-form-layout">
                  <div className="form-column">
                    <div className="form-row">
                      <div className="form-input">
                        <Form.Label>{t("tags.title")}</Form.Label>
                        <Form.Control
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={errors.name && touched.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </div>
                      <div className="form-input">
                        <Form.Label>{t("tags.includeGroups")}</Form.Label>
                        <Select
                          theme={(theme) => getTheme(theme)}
                          className="form-select-custom"
                          isMulti
                          styles={customStyles}
                          value={values.groups}
                          onChange={(option) => setFieldValue("groups", option)}
                          closeMenuOnSelect={false}
                          onBlur={handleBlur}
                          options={tagOptions}
                          isInvalid={errors.groups && touched.groups}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.groups}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default TagForm;
