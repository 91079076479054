import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as newsAction from "../../../../redux/actions/news.action";
import { fetchSASToken } from "../../../../services/SASTokenService";
import { useTranslation } from "react-i18next";
import List from "../../../list/list";
import placeholder from "../../../../assets/images/placeholder.jpg";

const newsFields = [
  //list will sort by the first element by default
  "title",
];

const NewsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);

  const { news, meta, listLoading } = useSelector((state) => ({
    news: state.news.newsList,
    meta: state.news.newsMetadata,
    listLoading: state.news.listLoading,
  }));

  useEffect(() => {
    newsAction.fetchAll(dispatch);
    const fetchToken = async () => {
      setToken(await fetchSASToken());
    };
    fetchToken();
  }, [dispatch]);

  return (
    <List
      redirect="/news/form"
      title={"Featured News"}
      isOverview={false}
      tooltipTextDelete="Delete News"
      columnNames={["IMAGE", "TITLE"]}
      hasImage={true}
      showFilterTab={false}
      isRemovable={false}
      canCreateNew={false}
      entities={news?.data?.map(
        ({ id, attributes: { image, title, publishedAt } }) => ({
          id,
          image: image?.data?.attributes?.url
            ? `${image?.data?.attributes?.url}?${token}`
            : placeholder,
          columns: [{ primary: title }],
          draft: publishedAt === null,
          name: title,
        })
      )}
      fieldsToSort={newsFields}
      fetchAction={(params) => newsAction.fetchAll(dispatch, params)}
      emptyAction={(id, params) => newsAction.emptyAndReload(dispatch, id, params)}
      metadata={meta}
      loading={listLoading}
      showPagination={false}
      showEmptySlot={true}
    />
  );
};

export default NewsList;
