import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import UserService from './services/UserService';
import StoreService from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = StoreService.setup()

root.render(
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
);

UserService.initKeycloak(() => root, store);
