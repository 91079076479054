import axios, { authHeader } from "../../services/HTTPService";
import * as action from "../reducers/notificationReducer";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAll = async (
  dispatch,
  {
    fieldTofilter,
    filter,
    fieldToSort,
    sortDirection,
    page,
    pageSize,
    memberFilters,
    typeOfFilter,
    includePublicNotifications,
  } = {
    fieldTofilter: "type",
    filter: "",
    fieldToSort: "id",
    sortDirection: "asc",
    page: 1,
    pageSize: 8,
    memberFilters: { all: true, members: [], groups: [] },
    typeOfFilter: "$containsi",
    includePublicNotifications: false,
  }
) => {
  dispatch(action.setListLoading(true));

  return axios
    .get(API_URL + `/api/notifications/backoffice/search`, {
      headers: authHeader(),
      params: {
        fieldTofilter,
        filter,
        fieldToSort,
        sortDirection,
        page,
        pageSize,
        memberFilters,
        typeOfFilter,
        includePublicNotifications,
      },
    })
    .then(({ data }) => {
      const meta = data?.meta;
      dispatch(action.setNotificationList({ data }));
      dispatch(action.setMetadata({ meta }));
      dispatch(action.setListLoading(false));
    })
    .catch((error) => {
      dispatch(
        action.setNotificationError({
          error: error?.response?.data?.error?.message,
        })
      );
      dispatch(action.setListLoading(false));
    });
};

export const fetchDetails = async (dispatch, id) => {
  return axios
    .get(API_URL + `/api/notifications/${id}`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setNotificationDetail({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setNotificationError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const create = async (dispatch, body) => {
  return axios
    .post(API_URL + "/api/notifications", body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setNotification({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setNotificationError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const update = async (dispatch, id, body) => {
  return axios
    .put(API_URL + `/api/notifications/${id}`, body, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(action.setNotification({ data }));
    })
    .catch((error) => {
      dispatch(
        action.setNotificationError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};

export const remove = async (dispatch, id, params, setNotificationDisabledSuccessMessage = () => {}) => {
  return axios
    .delete(API_URL + `/api/notifications/${id}`, { headers: authHeader() })
    .then(async () => {
      await fetchAll(dispatch, params);
      setNotificationDisabledSuccessMessage("Notification deleted successfully");
    })
    .catch((error) => {
      dispatch(
        action.setNotificationError({
          error: error?.response?.data?.error?.message,
        })
      );
    });
};
