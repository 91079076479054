import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as memberAction from "../../../../redux/actions/member.action";
import List from "../../../list/list";
import { formatDate } from "../../../../helpers/date";

const memberFields = [
  //list will sort by the first element by default
  "name",
  "type",
  "short_name",
  "dor_code",
  "zip_code",
  "phone",
  "publishedAt",
];

const MemberList = () => {
  const dispatch = useDispatch();

  const { members, meta, listLoading } = useSelector((state) => ({
    members: state.member.memberList,
    meta: state.member.memberMetadata,
    listLoading: state.member.listLoading,
  }));

  useEffect(() => {
    memberAction.fetchAll(dispatch);
  }, [dispatch]);

  return (
    <List
      redirect="/members/form"
      tooltipTextDelete="Delete Member"
      title="Members"
      columnNames={["NAME", "TYPE", "SHORT NAME", "DOR CODE", "ZIP CODE", "PHONE", "DATE"]}
      entities={members?.data?.map(
        ({
          id,
          attributes: {
            name,
            type,
            short_name,
            dor_code,
            zip_code,
            phone,
            publishedAt,
          },
        }) => ({
          id,
          columns: [
            { primary: name },
            { primary: type },
            { primary: short_name },
            { primary: dor_code },
            { primary: zip_code },
            { primary: phone },
            { primary: formatDate(publishedAt) },
          ],
          name,
        })
      )}
      showFilterTab={false}
      fieldsToSort={memberFields}
      fetchAction={(params) => memberAction.fetchAll(dispatch, params)}
      metadata={meta}
      deleteAction={(id, params) => memberAction.remove(dispatch, id, params)}
      isOverview={false}
      loading={listLoading}
      tab="general"
    />
  );
};

export default MemberList;
