import { createSlice } from "@reduxjs/toolkit";

const contactReducer = createSlice({
  name: "contact",
  initialState: {
    contactDetails: null,
    contact: null,
    contactList: null,
    contactMetadata: null,
    contactRoleSelectList: null,
    contactError: '',
    listLoading: false,
  },
  reducers: {
    setContactDetail: (state, action) => {
      state.contactDetails = action.payload.data;
      state.contactError = null;
    },
    setContactList: (state, action) => {
      state.contactList = action.payload.data;
      state.contactError = null;
    },
    setContactRoles: (state, action) => {
      state.contactRoleSelectList = action.payload.data;
      state.contactError = null;
    },
    setMetadata: (state, action) => {
      state.contactMetadata = action.payload.meta;
    },
    setContact: (state, action) => {
      state.contact = action.payload.data;
      state.contactError = null;
    },
    setContactError: (state, action) => {
      state.contactError = action.payload.error;
    },
    cleanContactState: (state) => {
      state = contactReducer.getInitialState();
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    }
  },
});

export const {
  setContactDetail,
  setContactList,
  setContactError,
  setContact,
  setMetadata,
  cleanContactState,
  setListLoading,
  setContactRoles
} = contactReducer.actions;

export default contactReducer.reducer;