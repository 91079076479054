import "./webPortalUserForm.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import * as userAction from "../../../../redux/actions/user.action";
import * as roleAction from "../../../../redux/actions/role.action";
import * as dropdownAction from "../../../../redux/actions/dropdown.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import { getTheme } from "../../../../helpers/select.theme";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "../../../modalconfirmation/ModalConfirmation";
import { Modal } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const WebPortalUserForm = () => {
  const { memberId, entityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [renderSuccessAlert, setRenderSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableRequestPassword, setDisableRequestPassword] = useState(false);
  const checkboxRef = useRef([]);
  const { t } = useTranslation();
  const formRef = useRef({ values: null });
  const { touched } = formRef.current;
  const [showExitModal, setShowExitModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [sectionsAndReports, setSectionsAndReports] = useState([]);
  const [roleManuallySelected, setRoleManuallySelected] = useState(false);
  const [customResetForm, setCustomResetForm] = useState(() => {});
  const [showModelToUnblock, setShowModelToUnblock] = useState(false);
  const [showModelToDelete, setShowModelToDelete] = useState(false);
  const [showButtonToHardDelete, setShowButtonToHardDelete] = useState(false);
  const [hardDeleteWasSuccessful, setHardDeleteWasSuccessful] = useState(false);

  const handleCloseModalToUnlockUser = () => setShowModelToUnblock(false);
  const handleCloseModalToDeleteUser = () => setShowModelToDelete(false);
  const handleHardDeleteConfirmationCheckboxChange = () => setShowButtonToHardDelete(!showButtonToHardDelete);

   const renderTooltip = (props) => (
    <Tooltip id="form-check-tooltip" {...props}>
       If this box is selected the user's activity will be displayed in mixpanel.
    </Tooltip>
  );

  // on start clear errors
  useEffect(() => {
    userAction.clearErrors(dispatch);
  }, [dispatch]);
  
  // if hard delete was successful, redirect to members list
  useEffect(() => {
    if (hardDeleteWasSuccessful) {
      navigate(`/members/form/${memberId}/webportalusers`);
    }
  }, [hardDeleteWasSuccessful, navigate, memberId]);

  const { userDetails, user, error, roleList, roleDetails, dropdownList, } =
    useSelector((state) => ({
      userDetails: state.user.userDetails,
      roleList: state.role.roleList,
      roleDetails: state.role.roleDetails,
      dropdownList: state.dropdown.dropdownList,
      user: state.user.user,
      error: state.user.errorUser,
    }));

  const processSectionsAndReports = (userDetails) => {
    if (!userDetails) return [];
    if (!userDetails.portal_user_role) return [];

    const allowed =
      userDetails.portal_user_custom_entitlements_allowed_sections_and_reports ||
      [];
    const denied =
      userDetails.portal_user_custom_entitlements_denied_sections_and_reports ||
      [];

    const roleSectionsAndReports =
      userDetails.portal_user_role?.sections_and_reports.map(
        ({ id }) => `${id}`
      );

    const newSectionsAndReports = [
      ...roleSectionsAndReports,
      ...allowed,
    ].filter((sr) => !denied.includes(sr));

    return newSectionsAndReports || [];
  };

  const processRoleDetails = (roleDetails) => {
    const sectionsAndReports = roleDetails?.sections_and_reports?.map(
      ({ id }) => `${id}`
    );
    return sectionsAndReports || [];
  };

  const initialValuesCreate = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone_extension: "",
    title: "",
    role: { value: "", label: "" },
    sections_and_reports: [],
    portal_user_administration: false,
    userShouldBeTracked: true,
    sso_access_portal_user: [],
  };

  const initialValuesUpdate = {
    firstName: userDetails?.first_name,
    lastName: userDetails?.last_name,
    email: userDetails?.email,
    phone: userDetails?.phone_number,
    phone_extension: userDetails?.phone_extension || "",
    role: selectedRole,
    title: userDetails?.title,
    sections_and_reports: sectionsAndReports,
    userShouldBeTracked: userDetails?.userShouldNotBeTracked === true ? false : true,
    portal_user_administration:
      userDetails?.portal_user_custom_entitlements_user_administration ===
        "ENABLED" || false,
    sso_access_portal_user:
      roleDetails && touched?.role ? roleDetails.sso_access_portal_user : [],
  };

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required("Required field"),
    lastName: Yup.string().required("Required field"),
    email: Yup.string().email().required("Required field"),
    role: Yup.object().required("Required field"),
    title: Yup.string().required("Required field"),
    phone: Yup.string().matches(
      /^[0-9\-]+$/,
      "Only numbers and dashes are allowed"
    ),
    phone_extension: Yup.string(),
    sections_and_reports: Yup.array(),
    portal_user_administration: Yup.boolean(),
    userShouldBeTracked: Yup.boolean(),
    sso_access_portal_user: Yup.array(),
  });

  const onSubmitForm = async (values) => {
    setDisableSubmit(true); //Disable submit button
    const {
      firstName,
      lastName,
      email,
      role,
      title,
      portal_user_administration,
      phone,
      phone_extension,
      userShouldBeTracked,
      // sso_access_portal_user
    } = values;

    const roleSectionsAndReports = roleDetails?.sections_and_reports?.map(
      ({ id }) => `${id}`
    );
    // const rolePortalUserAdministration = roleDetails?.portal_user_administration;
    // const roleSSOAccessPortalUser = roleDetails?.sso_access_portal_user;

    const portal_user_custom_entitlements_allowed_sections_and_reports =
      sectionsAndReports.filter((sr) => !roleSectionsAndReports?.includes(sr));

    const portal_user_custom_entitlements_denied_sections_and_reports =
      roleSectionsAndReports.filter((sr) => !sectionsAndReports?.includes(sr));

    const portal_user_custom_entitlements_user_administration =
      portal_user_administration ? "ENABLED" : "DISABLED";

    const emailChanged = userDetails?.email !== email;

    const formBody = {
      first_name: firstName,
      last_name: lastName,
      email: entityId && !emailChanged ? undefined : email,
      title,
      location: memberId,
      phone_number: phone,
      portalUserRoleId: role.value,
      userShouldNotBeTracked: !userShouldBeTracked,
      portal_user_custom_entitlements_allowed_sections_and_reports,
      portal_user_custom_entitlements_denied_sections_and_reports,
      portal_user_custom_entitlements_user_administration,
      sso_access_portal_user: roleDetails?.sso_access_portal_user,
      phone_extension,
    };

    if (entityId) {
      await userAction.updatePortalUser(dispatch, entityId, formBody);
    } else {
      await userAction.createPortalUser(dispatch, formBody);
    }

    setSubmittedForm(true); // Flag to make sure that the form was submitted
    setDisableSubmit(false); //Reenable submit button
  };

  useEffect(() => {
    // portal user have portal_user_role and admin don't have portal_user_role
    if (!userDetails?.portal_user_role || !entityId) return;

    const { id, name } = userDetails?.portal_user_role;

    if (!id) return;

    setSelectedRole({
      value: id,
      label: name,
    });

    roleAction.fetchDetails(dispatch, id);
  }, [userDetails, dispatch, entityId]);

  useEffect(() => {
    roleAction.fetchSelectOptionsWebPortal(dispatch);
    dropdownAction.fetchSelectOptions(dispatch);

    if (entityId) {
      //If id is passed as a parameter then load initial values
      userAction.fetchPortalUserDetails(dispatch, entityId, true);
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    //Render success alert and redirect
    if (submittedForm && user) {
      if (entityId) {
        userAction.fetchPortalUserDetails(dispatch, entityId, true);
        setRoleManuallySelected(false);
      } else {
        customResetForm();
        setSectionsAndReports([]);
        setSelectedRole({ value: "", label: "" });
      }
      userAction.clearErrors(dispatch);
      setRenderSuccessAlert(true);
      setSuccessMessage(
        entityId
          ? "Web Portal User edited successfully."
          : "Web Portal User created successfully."
      );
    } else {
      setRenderSuccessAlert(false);
    }
  }, [entityId, user, submittedForm, navigate, dispatch]);

  useEffect(() => {
    if (!selectedRole && !roleManuallySelected) return;
    roleAction.fetchDetails(dispatch, selectedRole?.value);
  }, [selectedRole, roleManuallySelected, dispatch]);

  useEffect(() => {
    if (userDetails && entityId) {
      setSectionsAndReports(processSectionsAndReports(userDetails));
    }
  }, [userDetails, dispatch, entityId]);

  useEffect(() => {
    if (!roleDetails || !roleManuallySelected) return;
    setSectionsAndReports(processRoleDetails(roleDetails));
  }, [roleDetails, roleManuallySelected]);


  return (
    <Formik
      initialValues={entityId ? initialValuesUpdate : initialValuesCreate}
      validationSchema={formSchema}
      enableReinitialize={!!entityId}
      onSubmit={onSubmitForm}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          resetForm,
        } = formik;

        return (
          <div className="web-portal">
            <div className="web-portal-container">
              {error && !renderSuccessAlert && (
                <Alert variant="danger">{error}</Alert>
              )}
              {renderSuccessAlert && !error && (
                <Alert
                  variant="success"
                  onClose={() => {
                    setRenderSuccessAlert(false);
                  }}
                >
                  {successMessage}
                </Alert>
              )}
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1 className="web-portal-title">
                    {entityId ? "Edit Web Portal User" : "New Web Portal User"}
                  </h1>

                  {userDetails?.enabled === false && (
                    <>
                      <p className="blocked-label">Disabled</p>
                    </>
                  )}
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="link"
                    className="hard-delete-user-button"
                    onClick={() => {
                      setShowModelToDelete(true);
                    }}
                  >
                    <div style={{display: "flex", alignItems: "flex-start"}}>

                      <span className="material-symbols-outlined" > delete </span>
                      <p>Delete user </p>
                    </div>
                  </Button>
                  </div>

              </div>

              <Form className="web-portal-form">
                <div className="web-portal-form-layout">
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.firstName && touched.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.lastName && touched.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.phone && touched.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Phone Extension</Form.Label>
                      <Form.Control
                        name="phone_extension"
                        value={values.phone_extension}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.phone_extension && touched.phone_extension
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_extension}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-input">
                      <Form.Label>Role</Form.Label>
                      <Select
                        theme={(theme) => getTheme(theme)}
                        className="form-select-custom"
                        onChange={(option) => {
                          const { value, label } = option;
                          setRoleManuallySelected(true);
                          setSelectedRole({ value, label });
                          setFieldValue("role", { value, label });
                        }}
                        closeMenuOnSelect={true}
                        onBlur={handleBlur}
                        options={roleList?.data?.map(
                          ({ id, attributes: { name } }) => ({
                            value: id,
                            label: name,
                          })
                        )}
                        value={values.role}
                        isInvalid={!selectedRole && touched.role}
                      />
                      {!selectedRole && touched.role && (
                        <p className="input-info-error">Required field</p>
                      )}
                    </div>
                    <div className="form-input">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.title && touched.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </div>
                    <div className="form-input">
                      <Form.Label>Password</Form.Label>
                      {entityId ? (
                        <Button
                          className="form-action"
                          disabled={disableRequestPassword}
                          onClick={() => {
                            setDisableRequestPassword(true);
                            userAction.requestPasswordReset({
                              dispatch,
                              email: userDetails?.email,
                              setSuccessMessage,
                              setRenderSuccessAlert,
                              setDisableRequestPassword,
                            });
                          }}
                        >
                          Request Password Update
                        </Button>
                      ) : (
                        <Form.Control
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!entityId}
                          placeholder={
                            !entityId &&
                            "Password will be generated automatically"
                          }
                        />
                      )}
                    </div>
                  </div>
                  <span className="web-portal-access-label">
                    Access settings
                  </span>
                  <div className="form-row">
                    <div className="web-portal-access-col">
                      <div className="web-portal-sr-check-group-col-header">
                        <span>SECTIONS AND REPORTS</span>
                        <div className="check-group-line"></div>
                        <Form.Check
                          id="all"
                          bsPrefix="form-check-input-custom"
                          label="Select All"
                          name="sections_and_reports"
                          value={0}
                          ref={(el) => (checkboxRef.current[0] = el)}
                          onChange={(e) => {
                            Object.values(checkboxRef.current).forEach(
                              (item) => {
                                if (item) {
                                  item.checked = e.target.checked;
                                }
                              }
                            );
                            setSectionsAndReports(
                              e.target.checked
                                ? dropdownList?.data.map(({ id }) =>
                                    id.toString()
                                  )
                                : []
                            );
                          }}
                        />
                      </div>
                      <div className="web-portal-check-group">
                        <div className="web-portal-check-group-col">
                          <div className="web-portal-sr-check-group-col">
                            {dropdownList?.data?.map(
                              ({ id, attributes: { name } }, index) => (
                                <Form.Check
                                  id={id}
                                  bsPrefix="form-check-input-custom"
                                  key={index}
                                  checked={sectionsAndReports?.includes(
                                    id.toString()
                                  )}
                                  ref={(element) => {
                                    checkboxRef.current[index + 1] = element;
                                  }}
                                  label={name}
                                  name="sections_and_reports"
                                  value={id}
                                  onChange={(e) => {
                                    setSectionsAndReports(
                                      e.target.checked
                                        ? [
                                            ...sectionsAndReports,
                                            e.target.value,
                                          ]
                                        : sectionsAndReports.filter(
                                            (item) => item !== e.target.value
                                          )
                                    );
                                    checkboxRef.current[0].checked = false;
                                  }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="web-portal-access-col">
                      <span>USERS ADMINISTRATION</span>
                      <div className="web-portal-check-group-col">
                        <Form.Check
                          id="editandcreateusers"
                          bsPrefix="form-check-input-custom"
                          label="Edit and create users"
                          name="portal_user_administration"
                          value="editandcreateusers"
                          checked={values.portal_user_administration}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="web-portal-access-col">
                      <span>SSO ACCESS</span>
                      <div className="web-portal-check-group-col">
                        <Form.Check
                          id="emiia"
                          bsPrefix="form-check-input-custom"
                          label="emiia.org"
                          name="sso_access_portal_user"
                          value="A"
                          checked={values.sso_access_portal_user?.includes("A")}
                          onChange={handleChange}
                        />
                        <Form.Check
                          id="utmc"
                          label="UTMC"
                          bsPrefix="form-check-input-custom"
                          name="sso_access_portal_user"
                          value="B"
                          checked={values.sso_access_portal_user?.includes("B")}
                          onChange={handleChange}
                        />
                          <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div>
                        <Form.Check
                          id="userShouldBeTracked"
                          bsPrefix="form-check-input-custom"
                          label="Mixpanel"
                          name="userShouldBeTracked"
                          value="userShouldBeTracked"
                          checked={values.userShouldBeTracked}
                          onChange={handleChange}
                          isInvalid={
                            errors.userShouldBeTracked &&
                            touched.userShouldBeTracked
                          }
                        />
      </div>
                          </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-action-group">
                  <Button
                    onClick={() => {
                      if (formik.dirty && !renderSuccessAlert) {
                        setShowExitModal(true);
                      } else {
                        window.history.back();
                      }
                    }}
                    className="form-action"
                    variant="outline-primary"
                  >
                    {t("buttons.back")}
                  </Button>
                  {userDetails?.enabled === false && (
                    <>
                      <Button
                        type="submit"
                        className="form-action form-action-secondary"
                        variant="outline-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModelToUnblock(true);
                        }}
                        disabled={disableSubmit}
                      >
                        {t("buttons.unblock")}
                      </Button>
                    </>
                  )}
                  <Button
                    type="submit"
                    className="form-action form-action-primary"
                    variant="outline-primary"
                    onClick={(e) => {
                      setCustomResetForm(() => resetForm);
                      handleSubmit(e);
                    }}
                    disabled={disableSubmit}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </Form>


              <Modal
                show={showModelToDelete}
                onHide={handleCloseModalToDeleteUser}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  Delete User?
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
       <Form.Check
                          id="editandcreateusers"
                          bsPrefix="form-check-input-custom"
                          label="I understand that once deleted, this user cannot be recovered. This activity is logged and can't be undone"
                          name="portal_user_administration_hard_delete_portal_user"
                          value="hard_delete_portal_user"
                          checked={showButtonToHardDelete}
                          onChange={handleHardDeleteConfirmationCheckboxChange}
                        />
                  
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="form-action"
                    onClick={handleCloseModalToDeleteUser}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="form-action form-action-primary"
                    disabled={!showButtonToHardDelete}
                    onClick={() => {
                      setShowModelToDelete(false);
                      setShowButtonToHardDelete(false);
                      userAction.hardDeleteUser({
                        dispatch,
                        userEmail: userDetails?.email,
                        setHardDeleteWasSuccessful,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showModelToUnblock}
                onHide={handleCloseModalToUnlockUser}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Enable User
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  Are you sure you want to enable this user?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="form-action"
                    onClick={handleCloseModalToUnlockUser}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="form-action form-action-primary"
                    onClick={() => {
                      setShowModelToUnblock(false);
                      userAction.unblockUser({
                        dispatch,
                        keycloakId: userDetails?.shared_uid_with_kc,
                        strapiId: entityId,
                        setSuccessMessage,
                        setRenderSuccessAlert,
                      });
                    }}
                  >
                    Yes, enable it
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <ModalConfirmation
              show={showExitModal}
              handleShow={setShowExitModal}
              title={t("modalconfirmation.titleExit")}
              mainAction={() => setShowExitModal(false)}
              secondaryAction={() => window.history.back()}
              question={t("modalconfirmation.exitquestion")}
              mainButton={t("modalconfirmation.continue")}
              secondaryButton={t("modalconfirmation.yesleave")}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default WebPortalUserForm;
