import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import * as linkAction from "../../redux/actions/link.action";
import * as buttonInHomeAction from "../../redux/actions/button.action";
import List from "../list/list";
import "./modalListItems.scss";

const fields = [
  //list will sort by the first element by default
  "name",
  "link",
  "publishedAt",
];

const ModalListItems = ({ show, handleShow, bulkContext: { bulk, type } }) => {
  const dispatch = useDispatch();

  const initialParams = useMemo(
    () => ({
      fieldTofilter: "name",
      filter: "",
      fieldToSort: "id",
      sortDirection: "asc",
      page: 1,
      pageSize: 8,
      bulk,
    }),
    [bulk]
  );

  const {
    links,
    buttons,
    linkMeta,
    linkListLoading,
    buttonMeta,
    buttonListLoading,
  } = useSelector((state) => ({
    links: state.link.linkList,
    linkMeta: state.link.linkMetadata,
    linkListLoading: state.link.listLoading,
    buttons: state.button.buttonList,
    buttonMeta: state.button.buttonMetadata,
    buttonListLoading: state.button.listLoading,
  }));

  const typeMap = useMemo(
    () => ({
      button_in_home: {
        fetch: () => buttonInHomeAction.fetchAll(dispatch, initialParams),
        list: buttons,
        title: "Buttons In Home",
        fetchWithParams: (params) =>
          buttonInHomeAction.fetchAll(dispatch, { ...params }),
        remove: (id, params) => buttonInHomeAction.remove(dispatch, id, params),
        meta: buttonMeta,
        loading: buttonListLoading,
      },
      quick_access_link: {
        fetch: () => linkAction.fetchAll(dispatch, initialParams),
        list: links,
        title: "Quick Access Link",
        fetchWithParams: (params) =>
          linkAction.fetchAll(dispatch, { ...params }),
        remove: (id, params) => linkAction.remove(dispatch, id, params),
        meta: linkMeta,
        loading: linkListLoading,
      },
    }),
    [
      buttons,
      links,
      dispatch,
      initialParams,
      buttonListLoading,
      buttonMeta,
      linkMeta,
      linkListLoading,
    ]
  );

  useEffect(() => {
    typeMap[type]?.fetch();
  }, [dispatch, type]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="bulk-modal"
      onHide={() => {
        handleShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Created Links</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <List
          title={typeMap[type]?.title}
          redirect=""
          columnNames={["MEMBER", "NAME", "LINK"]}
          entities={typeMap[type]?.list?.data?.map(
            ({ id, attributes: { name, link, location } }) => ({
              id,
              columns: [
                { primary: location?.data?.attributes?.name },
                { primary: name },
                { primary: link },
              ],
              name,
            })
          )}
          fieldsToSort={fields}
          fetchAction={(params) => typeMap[type]?.fetchWithParams(params)}
          isOverview={false}
          metadata={typeMap[type]?.meta}
          showFilterTab={false}
          showFilterButton={false}
          bulk={bulk}
          tooltipTextDelete="Delete Link"
          isEditable={false}
          deleteAction={(id, params) => typeMap[type]?.remove(id, params)}
          listLoading={typeMap[type]?.loading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalListItems;
